import React, {Component} from 'react';
import axios from '../../axios-sw';
import {connect} from 'react-redux';

import {ToastContainer} from 'react-toastify';

import MaterialTable, {MTableBodyRow, MTableEditRow, MTableToolbar} from 'material-table';
import {tableIcons, titleTemplate} from '../../shared/tableVariables';

import Columns from '../../shared/tables';
import {HotKeys} from "../../components/System/HotKeys/HotKeys";
import {authRedirect, catchResponse, formatDate, thenResponse} from "../../shared/utility";

import Spinner from '../../components/UI/Spinner/Spinner';
import ReactSelect from "react-select";

class Contacts extends Component {

    state = {
        data: [],
        loaded: false,
        seasons: [],
        season: null
    };

    componentDidMount() {
        this.indexContacts();
        this.indexSeasons();
    }

    indexSeasons = () => {
        axios.get('/seasons.json', {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                const result = res.data.map(function (obj) {
                    return {value: obj.id, label: obj.code}
                });
                this.setState({seasons: result});
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    indexContacts = () => {
        const season =
            JSON.parse(window.localStorage.getItem(`user_contacts_season_${this.props.user_id}`))
                ? JSON.parse(window.localStorage.getItem(`user_contacts_season_${this.props.user_id}`))
                : {value: 7, label: "W21"};

        axios.get(`/contacts-seasons/${season.value}.json`, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.setState({data: res.data, loaded: true, season: season})
                window.localStorage.setItem(`user_contacts_season_${this.props.user_id}`, JSON.stringify(season));
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    createContact = (newData) => {
        const data = {
            contact: {
                country: newData.country == null ? null : newData.country.toUpperCase(),
                company: newData.company == null ? null : newData.company.toUpperCase(),
                name: newData.name == null ? null : newData.name.toUpperCase(),
                code: newData.code == null ? null : newData.code.toUpperCase(),
                email: newData.email == null ? null : newData.email.toUpperCase(),
                supervisor_contact: newData.supervisor_contact == null ? null : newData.supervisor_contact.toUpperCase(),
                carry_terms_sent: newData.carry_terms_sent ? newData.carry_terms_sent : null, // boolean
                carry_terms_received: newData.carry_terms_received ? newData.carry_terms_received : null, // boolean
                ssd_sent: newData.ssd_sent ? newData.ssd_sent : null, // boolean
                ssd_received: newData.ssd_received ? newData.ssd_received : null, // boolean
            },
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
            season_id: this.state.season.value
        };
        axios.post('/contacts.json', data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.indexContacts();
                thenResponse(res)
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    destroyContact = (id) => {
        const data = {
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address
        };
        axios.delete('/contacts/' + id, {data: data, headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.indexContacts();
                thenResponse(res)
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    updateContact = (id, newData) => {
        const data = {
            contact: {
                country: newData.country == null ? null : newData.country.toUpperCase(),
                company: newData.company == null ? null : newData.company.toUpperCase(),
                name: newData.name == null ? null : newData.name.toUpperCase(),
                code: newData.code == null ? null : newData.code.toUpperCase(),
                email: newData.email == null ? null : newData.email.toUpperCase(),
                supervisor_contact: newData.supervisor_contact == null ? null : newData.supervisor_contact.toUpperCase(),
                carry_terms_sent: newData.carry_terms_sent ? newData.carry_terms_sent : null, // boolean
                carry_terms_received: newData.carry_terms_received ? newData.carry_terms_received : null, // boolean
                ssd_sent: newData.ssd_sent ? newData.ssd_sent : null, // boolean
                ssd_received: newData.ssd_received ? newData.ssd_received : null, // boolean
            },
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
            season_id: this.state.season.value
        };
        const url = `/contacts/${id}.json`;
        axios.put(url, data)
            .then(res => {
                axios.get(`/contacts-seasons/${id}/${this.state.season.value}.json`, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(res => {
                        const fetchedContact = res.data;
                        const array = [...this.state.data];
                        const index = array.findIndex(x => x.id === fetchedContact.id);
                        if (index !== -1) {
                            array[index].country = fetchedContact.country;
                            array[index].company = fetchedContact.company;
                            array[index].name = fetchedContact.name;
                            array[index].code = fetchedContact.code;
                            array[index].email = fetchedContact.email;
                            array[index].supervisor_contact = fetchedContact.supervisor_contact;
                            array[index].payment_term_id = fetchedContact.payment_term_id;
                            array[index].carry_terms_sent = fetchedContact.carry_terms_sent;
                            array[index].carry_terms_received = fetchedContact.carry_terms_received;
                            array[index].ssd_sent = fetchedContact.ssd_sent;
                            array[index].ssd_received = fetchedContact.ssd_received;
                            this.setState({data: array});
                        }
                    })
                    .catch(err => {
                        catchResponse(err)
                    });
                thenResponse(res)
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    render() {

        let emptyDataMessage;
        if (!this.state.loaded) {
            emptyDataMessage = <Spinner/>
        } else {
            emptyDataMessage = 'No data to display'
        }

        let content;
        if (this.props.role === null) {
            content = <Spinner/>
        } else {
            let editable;
            if (this.props.role === 'designer') {
                editable = null;
            } else {
                editable = {
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            resolve();
                            this.createContact(newData);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            resolve();
                            this.updateContact(oldData.id, newData)
                        }),
                    onRowDelete: oldData =>
                        new Promise(resolve => {
                            resolve();
                            this.destroyContact(oldData.id);
                        }),
                }
            }
            content = (
                <>
                    {!this.state.loaded && <div className={"overlay"}></div>}
                    {authRedirect(this.props.token)}
                    <ToastContainer/>
                    <HotKeys/>

                    <MaterialTable
                        title={titleTemplate('Contacts')}
                        localization={{
                            body: {
                                emptyDataSourceMessage: emptyDataMessage,
                                editRow: {
                                    cancelTooltip: 'Back'
                                }
                            }
                        }}
                        icons={tableIcons}
                        style={{width: '100%'}}
                        columns={this.props.role === 'designer' ? Columns.contactsColumnsForDesigner : Columns.contactsColumns}
                        data={this.state.data}
                        options={{
                            pageSize: 15,
                            exportButton: true,
                            exportFileName: 'contacts_' + formatDate(Date.now()),
                            filtering: true
                        }}
                        components={{
                            EditRow: props => (
                                <MTableEditRow className='editingRow uppercase' {...props} />
                            ),
                            Row: props => (
                                <MTableBodyRow {...props} />
                            ),
                            Toolbar: props => {
                                return (
                                    <div className='mainToolbar'>
                                        <MTableToolbar {...props} />
                                        <div style={{
                                            width: '200px',
                                            position: 'absolute',
                                            right: '400px',
                                            top: '10px'
                                        }}>
                                            <ReactSelect
                                                placeholder=""
                                                onChange={(e) => {
                                                    window.localStorage.setItem(`user_contacts_season_${this.props.user_id}`, JSON.stringify(e));
                                                    this.setState({data: [], loaded: false})
                                                    this.indexContacts()
                                                }
                                                }
                                                value={this.state.season}
                                                menuPortalTarget={document.body}
                                                styles={{menuPortal: base => ({...base, zIndex: 8000})}}
                                                options={this.state.seasons}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        }}
                        editable={editable}
                    />

                </>
            )
        }

        return content
    }

}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        token: state.auth.token,
        role: state.auth.role
    }
};

export default connect(mapStateToProps)(Contacts);