import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Grid, LinearProgress, Paper } from "@material-ui/core";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";

// Custom components
import axios from "../../../axios-sw";
import { authRedirect, catchResponse } from "../../../shared/utility";
import classes from "./VMFormCSV.module.scss";
import { ArrowBackIos } from "@material-ui/icons";

class VMFormCSV extends Component {
  state = {
    loading: false,
  };

  syncVMData = (data) => {
    this.setState({ loading: true });

    axios
      .post("/vm/mass-update.json", { data: data }, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        toast.success("Successfully synced data!");
        this.setState({ loading: false });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    return (
      <>
        <ToastContainer />
        {authRedirect(this.props.token)}

        <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "30vh" }}>
          <Grid item>
            <Paper style={{ width: 900 }}>
              {this.state.loading && (
                <LinearProgress
                  style={{
                    marginBottom: "-4px",
                    zIndex: 1,
                    borderRadius: "4px 4px 0 0",
                  }}
                />
              )}

              <div className={classes.FormNavigation}>
                <ArrowBackIos onClick={() => this.props.history.push(this.props.location.state.source)} />
              </div>

              <div style={{ padding: 20 }}>
                <Importer
                  assumeNoHeaders={false}
                  restartable
                  processChunk={async (rows) => {
                    this.syncVMData(rows);
                  }}
                  delimiter=";"
                >
                  <ImporterField name="index" label="Index" />
                  <ImporterField name="shipping_date_to_stores" label="Shipping date to stores" />
                </Importer>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    full_name: state.auth.full_name,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(withRouter(VMFormCSV));
