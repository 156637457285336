import React, { Component } from "react";
import axios from "../../../axios-sw";

import { toast } from "react-toastify";
import { StyleNameMask, catchResponse, StyleNameMaskNumber, AssortmentGroupMask } from "../../../shared/utility";
import { Button, FormControl, Input, Paper, TextField } from "@material-ui/core";

import { Form, Formik } from "formik";
import classes from "./ProductForm.module.scss";
import * as Yup from "yup";

import SplitPane from "react-split-pane";
import Pane from "react-split-pane/lib/Pane";

import ReactSelect, { createFilter } from "react-select";

import CombinationsForm from "./CombinationForm/CombinationForm";

import Dropzone from "../../../components/System/OrderDropzone/OrderDropzone";

import PropTypes from "prop-types";
import Spinner from "../../../components/UI/Spinner/Spinner";
import _ from "lodash";
import { Delete } from "@material-ui/icons";
import WarningInfo from "../../../components/UI/WarningInfo/WarningInfo";

const ProductFormSchema = Yup.object().shape({
  style_name: Yup.string().required("Style name is required!"),
  sex: Yup.object().typeError("Sex is required!").required("Sex is required!"),
  product_details: Yup.string().max(255, "Product Details must be at most 255 characters"),
  product_length: Yup.object().typeError("Length is required!").required("Length is required!"),
  sleeves: Yup.object().typeError("Sleeves is required!").required("Sleeves is required!"),
  waist: Yup.object().typeError("Waist is required!").required("Waist is required!"),
  shape: Yup.object().typeError("Shape is required!").required("Shape is required!"),
  assortment_group: Yup.string()
    .min(2, "Assortment Group must be at least 2 characters")
    .max(5, "Assortment Group must be at most 5 characters")
    .required("Assortment Group is required!"),
});

StyleNameMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const dropzone = React.createRef();

class ProductForm extends Component {
  state = {
    combinationsData: [],
    sizesPricesData: [],
    sizesPricesCombinationsData: [],

    // Indeksowanie pod produkt
    descriptions: [],
    sexes: [],
    fabrics: [],

    // Indeksowanie pod kombinacje
    colors: [],
    sub_colors: [],

    // Fetchowanie produktu
    productData: [],
    disableEditingAssortmentGroup: false,
    productIndex: null,

    previewFiles: [],
    patchCombinationsFiles: [],
    patchFiles: [],

    description: null,
    sex: null,
    sizes: [],
    combinationsQuantity: 0,
    loaded: false,
    loadedCombinations: true,

    redirection: null,
    sizeArray: [],
    sizeToAdd: [],
    productsColors: [],

    orderProductsOptions: [],
    currentOrder: null,

    formRef: React.createRef(),
  };

  componentDidMount() {
    this.createSelectOrderArrayForProduct();
    // Indeksowanie pod produkt
    this.indexDescriptions();
    this.indexSexes();
    this.indexFabrics();

    // Indeksowanie pod kombinacje
    this.indexColors();
    this.indexSubColors();

    // Fetchowanie produktu
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.productsQuantity !== this.props.productsQuantity) this.createSelectOrderArrayForProduct();
  }

  createSelectOrderArrayForProduct = () => {
    let arr = [];
    for (let i = 1; i <= this.props.productsQuantity; i++) {
      arr = [...arr, { value: i, label: i }];
    }

    this.setState({
      orderProductsOptions: arr,
      currentOrder: { value: this.props.defaultProductOrder, label: this.props.defaultProductOrder },
    });
  };

  fetchData = () => {
    this.setState({
      editForm: this.props.editForm,
    });
    if (this.props.editForm) {
      const index = this.props.productData.order_product.index;
      this.setState({
        productData: this.props.productData.order_product,
        disableEditingAssortmentGroup: index !== null ? index.length > 2 : false,
        productIndex: this.props.productData.order_product.index,
        description: {
          value: this.props.productData.order_product.order_description.id,
          label: this.props.productData.order_product.order_description.code,
        },
        sex: {
          value: this.props.productData.order_product.sex.id,
          label: this.props.productData.order_product.sex.code,
        },
      });
      this.fetchSizes(
        this.props.productData.order_product.order_description.id,
        this.props.productData.order_product.sex.id
      );
      if (this.props.productData.order_product.combinations.length > 0) {
        this.getCombinations(this.props.productData.order_product.id);
      } else {
        this.setState({ loaded: true });
      }
    } else {
      this.setState({ loaded: true });
    }
  };
  getCombinations = (id) => {
    axios
      .get("/combinations/order-form/" + id + ".json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const sorted = res.data.sort(function (a, b) {
          let o1 = a.order;
          let o2 = b.order;
          let p1 = a.size_id;
          let p2 = b.size_id;

          if (o1 < o2) return -1;
          if (o1 > o2) return 1;
          if (p1 < p2) return -1;
          if (p1 > p2) return 1;
          return 0;
        });

        // Funkcja filtrująca
        const uniq = (arrArg) => {
          return arrArg.filter((elem, pos, arr) => {
            return arr.indexOf(elem) === pos;
          });
        };

        const productsColors = [];
        sorted.forEach((combination) => {
          productsColors.push(combination.color_id);
        });

        const products = res.data;
        const combinationsData = [];
        uniq(productsColors).forEach((color_id) => {
          for (let i = 0; i < products.length; i++) {
            if (products[i].color_id === color_id) {
              combinationsData.push({
                id: products[i].id,
                product_id: products[i].product_id,

                main_color: products[i].main_color,
                composition: products[i].composition,
                composition_2: products[i].composition_2,
                collection_ref: products[i].collection_ref,
                fabric_name: products[i].fabric_name,

                price: parseFloat(products[i].price.replace(",", ".")),

                swatch_files: products[i].swatch_files,
                swatch_color: products[i].swatch_color,
                more_information: products[i].more_information,

                colour_for_hz: products[i].colour_for_hz,
                colour_for_website: products[i].colour_for_website,
                product_type: products[i].product_type,

                new: false,
              });
              break; // Po pierwszym produkcie z danego koloru zatrzymuje pętle
            }
          }
        });

        const sizeArray = products.map((product) => product.size_id);

        const sizesPricesCombinationsData = [];
        res.data.forEach((combination) => {
          sizesPricesCombinationsData.push({
            id: (Math.random() * 1e6) | 1,
            size_id: combination.size.id,
            size_code: combination.size.code,
            quantity: combination.quantity,
            main_color: combination.color_id,
            product_id: combination.id,
          });
        });

        this.setState({
          combinationsData: combinationsData,
          sizesPricesCombinationsData: sizesPricesCombinationsData,
          combinationsQuantity: combinationsData.length,
          loaded: true,
          loadedCombinations: true,
          sizeArray: sizeArray,
          productsColors: productsColors,
        });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  fetchSizes = (descriptionId, sexId) => {
    axios
      .get("/size-descriptions/" + descriptionId + "/sex/" + sexId + ".json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then(async (res) => {
        let sizes = res.data;
        let sizesToAdd = [];
        res.data.forEach((data) => {
          if (!this.state.sizeArray.includes(data.size_id)) sizesToAdd = [...sizesToAdd, data.size_id];
        });

        if (this.state.loaded) {
          if (sizesToAdd.length !== 0 && this.state.loaded) {
            const uniq = (arrArg) => {
              return arrArg.filter((elem, pos, arr) => {
                return arr.indexOf(elem) === pos;
              });
            };

            axios
              .post(
                `/combinations/size-combinations.json`,
                {
                  products_colors: this.state.productsColors,
                  sizes_to_add: sizesToAdd,
                  product_id: this.state.productData.id,
                },
                { headers: { Authorization: `Bearer ${this.props.token}` } }
              )
              .then((res) => {
                this.setState({ sizes: sizes, sizeToAdd: sizesToAdd });
              })
              .catch((err) => {
                catchResponse(err);
              });
          }
        }
        this.setState({ sizes: sizes, sizeToAdd: sizesToAdd });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  // Indeksowanie pod produkt
  indexDescriptions = () => {
    axios
      .get("/descriptions.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code + " (" + obj.title + ")" };
        });
        this.setState({ descriptions: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  indexSexes = () => {
    axios
      .get("/sexes.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code + " (" + obj.description + ")" };
        });
        this.setState({ sexes: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  indexFabrics = () => {
    axios
      .get("/fabrics.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.title };
        });
        this.setState({ fabrics: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  // Indeksowanie pod kombinacje
  indexColors = () => {
    axios
      .get("/colors.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.title };
        });
        this.setState({ colors: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  indexSubColors = () => {
    axios
      .get("/sub_colors.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.title };
        });
        this.setState({ sub_colors: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  // Funkcje dotyczące formularzy kombinacji
  removeCombinationsGroup = (added_product_id, createdProduct) => {
    const array = [...this.state.combinationsData];
    const index = array.findIndex((x) => x.id === added_product_id);
    if (index !== -1) {
      array.splice(index, 1);
      if (createdProduct) {
        const sizesArr = [...this.state.sizesPricesCombinationsData];
        this.state.sizesPricesCombinationsData.forEach((product) => {
          if (product.main_color === this.state.combinationsData[index].main_color.value) {
            const data = {
              user_id: this.props.user_id,
              ip_address: this.props.ip_address,
            };
            this.setState({ loadedCombinations: false });
            axios
              .delete("/combinations/" + product.product_id + ".json", {
                data: data,
                headers: { Authorization: `Bearer ${this.props.token}` },
              })
              .then(async () => {
                await this.getCombinations(this.props.productData.order_product.id);
              })
              .catch((err) => {
                catchResponse(err);
              });
            // Usunięcie z tablicy rozmiarów wprowadzonych kombinacji

            const sizeIndex = sizesArr.findIndex((x) => x.id === product.id);
            if (sizeIndex !== -1) {
              sizesArr.splice(sizeIndex, 1);
            }
          }
        });
        this.setState({
          sizesPricesCombinationsData: sizesArr,
        });
      } else {
        const sizesArr = [...this.state.sizesPricesCombinationsData];
        this.state.sizesPricesCombinationsData.forEach((product) => {
          if (product.product_id === this.state.combinationsData[index].id) {
            // Usunięcie z tablicy rozmiarów wprowadzonych kombinacji
            const sizeIndex = sizesArr.findIndex((x) => x.id === product.id);
            if (sizeIndex !== -1) {
              sizesArr.splice(sizeIndex, 1);
            }
          }
        });
        this.setState({
          sizesPricesCombinationsData: sizesArr,
        });
      }
      toast.success("Combinations successfully destroyed!");
    }
  };
  copyCombinationsGroup = (added_product_id) => {
    const array = [...this.state.combinationsData];
    const index = array.findIndex((x) => x.id === added_product_id);

    if (index !== -1) {
      const newCombinations = _.cloneDeep(array[index]);
      newCombinations.id = (Math.random() * 1e6) | 1;
      newCombinations.main_color = "";
      newCombinations.swatch_files = [];
      newCombinations.swatch_color = null;
      newCombinations.new = true;
      newCombinations.copied = true;

      // Brak kopiowania wybranych elementów
      newCombinations.fabric_name = null;
      newCombinations.colour_for_hz = null;
      newCombinations.colour_for_website = null;
      newCombinations.product_type = null;

      array.push(newCombinations);

      this.setState({
        combinationsData: array,
        combinationsQuantity: this.state.combinationsQuantity + 1,
      });
    }
  };

  // Funkcje na potrzeby onSubmit produktu
  updatePreviewFiles = (preview_files, productID, callback) => {
    if (preview_files === null) {
      const formDataPreview = new FormData();
      formDataPreview.append("preview_files[]", null);
      formDataPreview.append("user_id", this.props.user_id);
      formDataPreview.append("ip_address", this.props.ip_address);
      axios
        .post("/products/preview-files/" + productID, formDataPreview, {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .catch((err) => {
          callback();
          catchResponse(err);
        });
    } else if (preview_files.length > 0) {
      const formDataPreview = new FormData();
      for (let i = 0; i < preview_files.length; i++) {
        formDataPreview.append("preview_files[]", preview_files[i]);
      }
      formDataPreview.append("user_id", this.props.user_id);
      formDataPreview.append("ip_address", this.props.ip_address);
      axios
        .post("/products/preview-files/" + productID, formDataPreview, {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .catch((err) => {
          callback();
          catchResponse(err);
        });
    }
  };
  updatePatchFiles = (swatch_files, productID, callback) => {
    // Dodawanie plików tylko jeżeli jest wgrany, w przeciwnym razie ignoruje funkcje
    if (swatch_files === null) {
      const formDataPatch = new FormData();
      formDataPatch.append("swatch_files[]", null);
      formDataPatch.append("user_id", this.props.user_id);
      formDataPatch.append("ip_address", this.props.ip_address);
      axios
        .post("/combinations/swatch-files/" + productID, formDataPatch, {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .catch((err) => {
          callback();
          catchResponse(err);
        });
    } else if (swatch_files.length !== 0) {
      // Sprawdzam czy obiekt w tablicy jest plikiem
      if (typeof swatch_files[0].name !== "undefined") {
        const formDataPatch = new FormData();
        for (let i = 0; i < swatch_files.length; i++) {
          formDataPatch.append("swatch_files[]", swatch_files[i]);
        }
        formDataPatch.append("user_id", this.props.user_id);
        formDataPatch.append("ip_address", this.props.ip_address);
        axios
          .post("/combinations/swatch-files/" + productID, formDataPatch, {
            headers: { Authorization: `Bearer ${this.props.token}` },
          })
          .catch((err) => {
            callback();
            catchResponse(err);
          });
      }
    }
  };

  addOrUpdateCombinations = (productID, callback) => {
    this.state.combinationsData.forEach((combination, index) => {
      this.state.sizesPricesCombinationsData.forEach((product) => {
        if (product.main_color === combination.main_color.value) {
          const combinationData = {
            combination: {
              product_id: productID,
              color_id: combination.main_color.value,

              composition: combination.composition ? combination.composition.toUpperCase() : null,
              composition_2: combination.composition_2 ? combination.composition_2.toUpperCase() : null,
              collection_ref: combination.collection_ref ? combination.collection_ref.toUpperCase() : null,
              fabric_name: combination.fabric_name ? combination.fabric_name.toUpperCase() : null,
              swatch_color: combination.swatch_color,

              price:
                typeof combination.price !== "undefined"
                  ? combination.price !== null
                    ? combination.price.toString().replace(" ", "").replace(",", ".")
                    : null
                  : null,

              more_information: combination.more_information.toUpperCase(),
              colour_for_hz: combination.colour_for_hz.label,
              colour_for_website: combination.colour_for_website,
              // product_type: combination.product_type.label,

              // Dotyczące size i quantity
              size_id: product.size_id,
              quantity: product.quantity,

              //order
              order: index + 1,
            },
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
          };

          if (combination.new) {
            axios
              .post("/combinations", combinationData, { headers: { Authorization: `Bearer ${this.props.token}` } })
              .then((res) => {
                // Dodanie ID produktu
                const sizesPricesCombinationsData = [...this.state.sizesPricesCombinationsData];
                const indexSiz = sizesPricesCombinationsData.findIndex((x) => x.id === product.id);
                if (indexSiz !== -1) {
                  sizesPricesCombinationsData[indexSiz].product_id = res.data.custom_data;
                  this.setState({ sizesPricesCombinationsData: sizesPricesCombinationsData });
                }

                // Update patch files dla każdej kombinacji
                this.updatePatchFiles(combination.swatch_files, res.data.custom_data, callback());
              })
              .catch((err) => {
                callback();
                catchResponse(err);
              });
          } else {
            // Check if colour_for_hz and colour_for_website is not empty after color_id update
            if (combination.colour_for_hz === "" || combination.colour_for_website === "") {
              if (combination.colour_for_hz === "") toast.error(`You must set colour for HZ for ${product.style_name}`);
              if (combination.colour_for_website === "")
                toast.error(`You must set colour for WEBSITE for ${product.style_name}`);
            } else {
              axios
                .put("/combinations/" + product.product_id, combinationData, {
                  headers: { Authorization: `Bearer ${this.props.token}` },
                })
                .then((res) => {
                  // Update patch files dla każdej kombinacji
                  this.updatePatchFiles(combination.swatch_files, res.data.custom_data, callback());
                })
                .catch((err) => {
                  callback();
                  catchResponse(err);
                });
            }
          }
        }
      });
      // Ustawienie flagi new na false
      const combinationsData = [...this.state.combinationsData];
      const indexCom = combinationsData.findIndex((x) => x.id === combination.id);
      if (indexCom !== -1) {
        combinationsData[indexCom].new = false;
        this.setState({ combinationsData: combinationsData });
      }
    });
  };

  deleteProduct = () => {
    const data = {
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .delete("/products/" + this.state.productData.id, {
        data: data,
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then(() => {
        this.props.rerenderForms();
        toast.success("Product and combinations successfully deleted!!");
      });
  };

  render() {
    // Nadaje InitialValues w zależności czy produkt istnieje bądź nie
    let unchangedInitialValues;
    if (this.state.loaded) {
      if (this.state.editForm && this.state.productData.length !== 0) {
        const product = this.state.productData;
        unchangedInitialValues = {
          description: {
            value: product.order_description.id,
            label: product.order_description.code + " (" + product.order_description.title + ")",
          },
          style_name: product.style_name,
          sex: { value: product.sex.id, label: product.sex.code + " (" + product.sex.description + ")" },
          fabric: product.fabric !== "" ? { value: product.fabric.id, label: product.fabric.description } : "",
          product_details: product.details,
          preview_files: product.preview_files,
          product_length:
            product.product_length !== "" && product.product_length !== null
              ? { value: product.product_length, label: product.product_length }
              : "",
          sleeves:
            product.sleeves !== "" && product.sleeves !== null
              ? { value: product.sleeves, label: product.sleeves }
              : "",
          waist: product.waist !== "" && product.waist !== null ? { value: product.waist, label: product.waist } : "",
          shape: product.shape !== "" && product.shape !== null ? { value: product.shape, label: product.shape } : "",
          assortment_group: product.index !== null ? product.index : "",
        };
      } else {
        unchangedInitialValues = {
          description: "",
          style_name: "",
          sex: "",
          fabric: "",
          product_details: "",
          preview_files: [],
          product_length: "",
          sleeves: "",
          waist: "",
          shape: "",
          assortment_group: "",
        };
      }
    }

    // Renderuje odpowiednią ilość formularzy kombinacji w zależności od wprowadzonej liczby
    const combinationForms = [];
    if (this.state.loaded) {
      for (let i = 0; i < this.state.combinationsQuantity; i++) {
        combinationForms.push(
          <CombinationsForm
            key={i}
            // Funkcje po dodaniu produktu do tablicy
            removeProduct={(added_product_id, createdProduct) =>
              this.removeCombinationsGroup(added_product_id, createdProduct)
            } // ZROBIONE
            copyColour={(added_product_id) => this.copyCombinationsGroup(added_product_id)} // ZROBIONE
            addNewColour={() => this.setState({ combinationsQuantity: this.state.combinationsQuantity + 1 })}
            removeUnaddedProduct={() => this.setState({ combinationsQuantity: this.state.combinationsQuantity - 1 })}
            // Zmienna potrzebne do generowania treści
            sizes={this.state.sizes}
            onSizesChange={(sizes) => this.setState({ sizes: sizes })}
            productId={this.state.productData.id}
            colors={this.state.colors}
            subColors={this.state.sub_colors}
            editForm={this.state.editForm}
            combinationsData={this.state.combinationsData}
            combinationData={this.state.combinationsData[i]}
            sizesPricesCombinationsData={this.state.sizesPricesCombinationsData}
            updateSizesPricesData={(fullSizesPrices) =>
              this.setState({
                sizesPricesCombinationsData: fullSizesPrices,
                sizesPricesData: [],
              })
            }
            updateCombinations={(array) => this.setState({ combinationsData: array })}
            disabled={this.props.disabled}
            descriptionCode={this.state.description.label.substring(0, 2)}
          />
        );
      }
    }

    // Formularz produktu i kombinacji
    let content = <Spinner />;
    if (this.state.fabrics.length !== 0 && this.state.loaded) {
      content = (
        <Paper className={classes.ProductCreateForm}>
          {this.state.redirection}
          <div className={classes.ChangeProductOrderReactSelect}>
            <p>Product Order:</p>
            <ReactSelect
              className={classes.ProductOrderSelect}
              options={this.state.orderProductsOptions}
              value={this.state.currentOrder}
              onChange={(e) => this.setState({ currentOrder: e })}
            />
          </div>
          <h1>Product {this.state.editForm ? "edit" : "create"} form</h1>
          {this.state.productData.in_progress && (
            <div className={classes.WarningWrapper}>
              <WarningInfo text={"Product has status: In Progress!"} />
            </div>
          )}

          {/* FORMIK */}

          <Formik
            initialValues={unchangedInitialValues}
            validationSchema={ProductFormSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              if (this.state.description !== null) {
                const style_name = typeof values.style_name !== "undefined" ? values.style_name.toUpperCase() : null;
                const productData = {
                  product: {
                    style_name: this.state.description.label.substring(0, 2) + style_name + this.props.defined_part,
                    sex_id: values.sex.value,
                    fabric_id: values.fabric.value,
                    product_details:
                      typeof values.product_details !== "undefined" ? values.product_details.toUpperCase() : null,
                    order_id: this.props.orderId,
                    sizeToAdd: this.state.sizeToAdd,
                    product_order: this.state.currentOrder.value,
                    product_length: values.product_length.value,
                    sleeves: values.sleeves.value,
                    waist: values.waist.value,
                    shape: values.shape.value,
                    index: this.state.productIndex !== null ? this.state.productIndex.toUpperCase() : null,
                  },
                  user_id: this.props.user_id,
                  ip_address: this.props.ip_address,
                };

                // Dwa stany formularza w zależności czy jest to edycja produktu czy dodanie nowego
                if (this.state.editForm) {
                  axios
                    .put("/products/" + this.state.productData.id, productData, {
                      headers: { Authorization: `Bearer ${this.props.token}` },
                    })
                    .then(async (res) => {
                      this.props.handleProductsStartQuantity(this.props.productsQuantity);
                      this.updatePreviewFiles(this.state.previewFiles, res.data.custom_data, () =>
                        setSubmitting(false)
                      );
                      if (this.state.combinationsData.length > 0) {
                        this.addOrUpdateCombinations(res.data.custom_data, () => setSubmitting(false));
                      }
                      toast.success("Product and combinations updated!");
                      setSubmitting(false);
                    })
                    .catch((err) => {
                      setSubmitting(false);
                      catchResponse(err);
                    });
                } else {
                  axios
                    .post("/products", productData, { headers: { Authorization: `Bearer ${this.props.token}` } })
                    .then((res) => {
                      this.props.handleProductsStartQuantity(this.props.productsQuantity);
                      this.updatePreviewFiles(this.state.previewFiles, res.data.custom_data, () =>
                        setSubmitting(false)
                      );
                      if (this.state.combinationsData.length > 0) {
                        this.addOrUpdateCombinations(res.data.custom_data, () => setSubmitting(false));
                      }
                      // W celu uniknięcia ponownego tworzenia produktu podczas jednej sesji
                      axios
                        .get("/products/" + res.data.custom_data + "/order-page.json", {
                          headers: { Authorization: `Bearer ${this.props.token}` },
                        })
                        .then((res) => {
                          this.setState({
                            productData: res.data.order_product,
                            editForm: true,
                          });
                        });
                      toast.success("Product and combinations created!");
                      setSubmitting(false);
                    })
                    .catch((err) => {
                      setSubmitting(false);
                      catchResponse(err);
                    });
                }
              } else {
                toast.error("You must set description code!");
                setFieldError("style_name", "Set description code!");
                setSubmitting(false);
              }
            }}
            innerRef={this.state.formRef}
          >
            {({
              submitForm,
              touched,
              errors,
              isSubmitting,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form className={classes.Form}>
                <SplitPane split="vertical">
                  <Pane initialSize="30%" className={classes.ProductSection}>
                    <FormControl className={classes.FormControlStyleWithSelect}>
                      <p className={classes.Label}>
                        Style name<span className={classes.RequireDot}>*</span>
                      </p>
                      <div className={classes.ReactSelect}>
                        <ReactSelect
                          className={errors.style_name && touched.style_name && classes.errorBorder}
                          isDisabled={this.props.disabled}
                          placeholder="Product type"
                          value={values.description}
                          onChange={(e) => {
                            this.setState({ description: e, sizes: [] });
                            setFieldValue("description", e);
                            if (this.state.sex !== null) {
                              this.fetchSizes(e.value, this.state.sex.value);
                            }
                          }}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                          options={this.state.descriptions}
                          filterOption={createFilter({
                            ignoreCase: true,
                            ignoreAccents: false,
                            trim: true,
                            matchFrom: "start",
                          })}
                        />
                      </div>
                      <div className={classes.StyleInput}>
                        <Input
                          disabled={this.props.disabled}
                          name="style_name"
                          id="style_name"
                          placeholder="01"
                          value={values.style_name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputComponent={StyleNameMaskNumber}
                        />
                      </div>
                      <div className={classes.DefinedPart}>
                        <Input disabled={true} value={this.props.defined_part} />
                      </div>
                      {errors.style_name && touched.style_name && (
                        <div className={classes.InputFeedback}>{errors.style_name}</div>
                      )}
                    </FormControl>

                    <FormControl className={classes.FormControlSelect}>
                      <p className={classes.Label}>
                        Assortment Group<span className={classes.RequireDot}>*</span>
                      </p>
                      <Input
                        disabled={this.state.disableEditingAssortmentGroup}
                        name="assortment_group"
                        id="assortment_group"
                        placeholder="CO"
                        value={values.assortment_group}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          this.setState({ productIndex: e.target.value });
                        }}
                        inputComponent={values.assortment_group.length === 5 ? undefined : AssortmentGroupMask}
                      />
                      {errors.assortment_group && touched.assortment_group && (
                        <div className={classes.InputFeedback}>{errors.assortment_group}</div>
                      )}
                    </FormControl>

                    <FormControl className={classes.FormControlSelect}>
                      <p className={classes.Label}>
                        Sex<span className={classes.RequireDot}>*</span>
                      </p>
                      <ReactSelect
                        className={errors.sex && touched.sex && classes.errorBorder}
                        isDisabled={this.props.disabled}
                        name="sex"
                        id="sex"
                        value={values.sex}
                        onChange={(e) => {
                          this.setState({ sex: e });
                          setFieldValue("sex", e);
                          if (this.state.description !== null) {
                            this.fetchSizes(this.state.description.value, e.value);
                          }
                        }}
                        onBlur={() => setFieldTouched("sex", true)}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                        options={this.state.sexes}
                      />
                      {errors.sex && touched.sex && <div className={classes.InputFeedback}>{errors.sex}</div>}
                    </FormControl>

                    <FormControl className={classes.FormControlSelect}>
                      <p className={classes.Label}>Fabric</p>
                      <ReactSelect
                        isDisabled={this.props.disabled}
                        name="fabric"
                        id="fabric"
                        value={values.fabric}
                        onChange={(e) => setFieldValue("fabric", e)}
                        onBlur={() => setFieldTouched("fabric", true)}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                        options={this.state.fabrics.sort((a, b) => {
                          if (a.label < b.label) {
                            return -1;
                          }
                          if (a.label > b.label) {
                            return 1;
                          }
                          return 0;
                        })}
                      />
                      {errors.fabric && touched.fabric && <div className={classes.InputFeedback}>{errors.fabric}</div>}
                    </FormControl>

                    <FormControl className={classes.FormControlTextArea}>
                      <p className={classes.Label}>Product details</p>
                      <TextField
                        disabled={this.props.disabled}
                        multiline
                        rows="12"
                        variant="outlined"
                        name="product_details"
                        id="product_details"
                        value={values.product_details}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {errors.product_details && touched.product_details && (
                        <div className={classes.InputFeedback}>{errors.product_details}</div>
                      )}
                    </FormControl>

                    <div className={classes.DropzoneContainer}>
                      <p className={classes.Label}>Preview images/scratches</p>
                      <div className={classes.Dropzone}>
                        <Button
                          className={classes.DropzoneDelete}
                          onClick={(e) => {
                            e.preventDefault();
                            dropzone.current.setInitialFiles();
                            const productData = this.state.productData;
                            productData.preview_files = [];
                            this.setState({ previewFiles: null, productData: productData });
                            setFieldValue("preview_files", null);
                          }}
                        >
                          <Delete />
                        </Button>
                        <Dropzone
                          disabled={false}
                          ref={dropzone}
                          files={
                            typeof this.state.productData.preview_files !== "undefined"
                              ? this.state.productData.preview_files
                              : []
                          }
                          onDrop={(files) => {
                            setFieldValue("preview_files", files);
                            this.setState({ previewFiles: files });
                          }}
                        />
                      </div>
                      {errors.preview_files && touched.preview_files && (
                        <div className={classes.InputFeedback} style={{ textAlign: "center" }}>
                          {errors.preview_files}
                        </div>
                      )}
                    </div>

                    <h3>Additional information</h3>

                    <FormControl className={classes.FormControlSelect}>
                      <p className={classes.Label}>
                        Length<span className={classes.RequireDot}>*</span>
                      </p>
                      <ReactSelect
                        className={errors.product_length && touched.product_length && classes.errorBorder}
                        name="product_length"
                        id="product_length"
                        value={values.product_length}
                        onChange={(e) => {
                          setFieldValue("product_length", e);
                        }}
                        onBlur={() => setFieldTouched("product_length", true)}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                        options={[
                          { value: "--", label: "--" },
                          { value: "MINI", label: "MINI" },
                          { value: "KNEE", label: "KNEE" },
                          { value: "MIDI", label: "MIDI" },
                          { value: "MAXI", label: "MAXI" },
                          { value: "LONG", label: "LONG" },
                          { value: "ANKLE", label: "ANKLE" },
                          { value: "7/8", label: "7/8" },
                          { value: "REGULAR", label: "REGULAR" },
                          { value: "SHORT", label: "SHORT" },
                          { value: "FOOT", label: "FOOT" },
                        ]}
                      />
                      {errors.product_length && touched.product_length && (
                        <div className={classes.InputFeedback}>{errors.product_length}</div>
                      )}
                    </FormControl>

                    <FormControl className={classes.FormControlSelect}>
                      <p className={classes.Label}>
                        Sleeves<span className={classes.RequireDot}>*</span>
                      </p>
                      <ReactSelect
                        className={errors.sleeves && touched.sleeves && classes.errorBorder}
                        name="sleeves"
                        id="sleeves"
                        value={values.sleeves}
                        onChange={(e) => {
                          setFieldValue("sleeves", e);
                        }}
                        onBlur={() => setFieldTouched("sleeves", true)}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                        options={[
                          { value: "--", label: "--" },
                          { value: "LONG", label: "LONG" },
                          { value: "SHORT", label: "SHORT" },
                          { value: "3/4 SLEEVE", label: "3/4 SLEEVE" },
                          { value: "ELBOW LENGTH", label: "ELBOW LENGTH" },
                          { value: "SLEEVELESS", label: "SLEEVELESS" },
                        ]}
                      />
                      {errors.sleeves && touched.sleeves && (
                        <div className={classes.InputFeedback}>{errors.sleeves}</div>
                      )}
                    </FormControl>

                    <FormControl className={classes.FormControlSelect}>
                      <p className={classes.Label}>
                        Waist<span className={classes.RequireDot}>*</span>
                      </p>
                      <ReactSelect
                        className={errors.waist && touched.waist && classes.errorBorder}
                        name="waist"
                        id="waist"
                        value={values.waist}
                        onChange={(e) => {
                          setFieldValue("waist", e);
                        }}
                        onBlur={() => setFieldTouched("waist", true)}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                        options={[
                          { value: "--", label: "--" },
                          { value: "HIGH", label: "HIGH" },
                          { value: "REGULAR", label: "REGULAR" },
                          { value: "MEDIUM", label: "MEDIUM" },
                          { value: "LOW", label: "LOW" },
                        ]}
                      />
                      {errors.waist && touched.waist && <div className={classes.InputFeedback}>{errors.waist}</div>}
                    </FormControl>

                    <FormControl className={classes.FormControlSelect}>
                      <p className={classes.Label}>
                        Shape<span className={classes.RequireDot}>*</span>
                      </p>
                      <ReactSelect
                        className={errors.shape && touched.shape && classes.errorBorder}
                        name="shape"
                        id="shape"
                        value={values.shape}
                        onChange={(e) => {
                          setFieldValue("shape", e);
                        }}
                        onBlur={() => setFieldTouched("shape", true)}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                        options={[
                          { value: "--", label: "--" },
                          { value: "SLIM", label: "SLIM" },
                          { value: "FITTED", label: "FITTED" },
                          { value: "REGULAR", label: "REGULAR" },
                          { value: "FLARE", label: "FLARE" },
                          { value: "WIDE", label: "WIDE" },
                          { value: "OVERSIZE", label: "OVERSIZE" },
                        ]}
                      />
                      {errors.shape && touched.shape && <div className={classes.InputFeedback}>{errors.shape}</div>}
                    </FormControl>
                  </Pane>

                  <Pane initialSize="70%" className={classes.CombinationsSection}>
                    {this.state.description === null || this.state.sex === null ? (
                      <div className={classes.AlertInfo}>
                        <i>Select description code and sex!</i>
                      </div>
                    ) : this.state.sizes.length === 0 ? (
                      <div className={classes.AlertInfo}>
                        <i>For given product type sizes does not exist!</i>
                      </div>
                    ) : (
                      <>
                        {this.state.combinationsQuantity === 0 ? (
                          <div className={classes.AlertInfo}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => this.setState({ combinationsQuantity: 1 })}
                            >
                              Add first combination group
                            </Button>
                          </div>
                        ) : this.state.loadedCombinations ? (
                          combinationForms
                        ) : (
                          <Spinner />
                        )}
                      </>
                    )}
                  </Pane>
                </SplitPane>
                <>
                  <div className="submitSection">
                    <Button
                      type="submit"
                      className={classes.SubmitButton}
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={() => {
                        submitForm().then(() => {
                          const errors = this.state.formRef.current.errors;
                          if (Object.keys(errors).length > 0) {
                            Object.keys(errors).forEach((key) => {
                              toast.error(errors[key]);
                            });
                          }
                        });
                      }}
                      style={{ display: "none" }}
                    >
                      Save
                    </Button>
                  </div>

                  {this.state.editForm ? (
                    <Button
                      className={classes.DeleteButton}
                      variant="contained"
                      disabled={isSubmitting}
                      onClick={() => this.deleteProduct()}
                    >
                      Delete
                    </Button>
                  ) : null}
                </>
              </Form>
            )}
          </Formik>
        </Paper>
      );
    }

    return content;
  }
}

export default ProductForm;
