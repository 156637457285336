import React, {Component} from 'react';
import axios from '../../../axios-sw';
import {connect} from 'react-redux';

import MaterialTable, {MTableBodyRow, MTableEditRow, MTableToolbar} from 'material-table';
import {tableIcons, titleTemplate} from '../../../shared/tableVariables';

import Columns from '../../../shared/tables';

import {HotKeys} from "../../../components/System/HotKeys/HotKeys";

import {
    catchResponse,
    authRedirect,
    roleLimitedView
} from '../../../shared/utility';

import Spinner from "../../../components/UI/Spinner/Spinner";
import {defaultPageSize} from "../../../shared/defines";
import {Chip} from "@material-ui/core";
import {HighlightOff} from "@material-ui/icons";
import {json2excel} from "js2excel";
import ContainerInvoices from "./ContainerInvoices/ContainerInvoices";

class Containers extends Component {

    state = {
        columns: Columns.vm.containers,
        data: [],
        parentChildData: "",
        loaded: false
    };

    componentDidMount() {
        this.indexContainers();
    }

    indexContainers = () => {
        axios.get('/containers.json', {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.setState({data: res.data, loaded: true});
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    render() {

        let tableRef = React.createRef();

        let emptyDataMessage;
        if (!this.state.loaded) {
            emptyDataMessage = <Spinner />
        } else {
            emptyDataMessage = 'No data to display'
        }

        return (
            <div>
                {authRedirect(this.props.token)}
                {roleLimitedView('vm', this.props.role, this.props.user_id)}
                <HotKeys/>

                <MaterialTable
                    tableRef={tableRef}
                    title={titleTemplate('VM Containers')}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataMessage,
                            editRow: {
                                cancelTooltip: 'Back'
                            }
                        }
                    }}
                    icons={tableIcons}
                    style={{width: '100%'}}
                    data={this.state.data}
                    columns={this.state.columns}
                    detailPanel={rowData => {
                        return (
                            <div className="detailPanel">
                                <ContainerInvoices
                                    token={this.props.token}
                                    user_id={this.props.user_id}
                                    ip_address={this.props.ip_address}
                                    containerId={rowData.id}
                                    containerNumber={rowData.number}
                                    filterByColumn={this.state.filterByColumn}
                                    filterByConditional={this.state.filterByConditional}
                                    filteredPhrase={this.state.filterPhrase}
                                    colorFilteredPhrase={this.colorFilteredPhrase}
                                />
                            </div>
                        )
                    }}
                    options={{
                        filtering: true,
                        pageSize: defaultPageSize
                    }}
                    components={{
                        EditRow: props => (
                            <MTableEditRow className='editingRow uppercase' {...props} />
                        ),
                        Row: props => (
                            <MTableBodyRow className={props.data.styles} {...props} />
                        ),
                        Toolbar: props => {
                            return (
                                <div className='mainToolbar'>
                                    <MTableToolbar {...props} />

                                    {/* Usuwanie filtra z tabeli */}
                                    <Chip variant="outlined" style={{marginLeft: 24}} avatar={<HighlightOff />} label="Clear filters" onClick={() => {
                                        const table = tableRef.current;
                                        for (let i=0; i < table.state.columns.length; i++) {
                                            table.onFilterChange(i, null);
                                            table.state.columns[i].tableData.filterValue = null;
                                        }
                                    }} />
                                </div>
                            )
                        }
                    }}
                />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        role: state.auth.role
    }
};

export default connect(mapStateToProps)(Containers);