import React, { Component } from "react";
import axios from "../../../axios-sw";
import { connect } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, { MTableBody, MTableBodyRow, MTableToolbar } from "material-table";
import { tableIcons, titleTemplate } from "../../../shared/tableVariables";

import Columns from "../../../shared/tables/designersTables/bookingsTable";
import { HotKeys } from "../../../components/System/HotKeys/HotKeys";
import {
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  formatDate,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  sendEmailAboutLoadingDelay,
} from "../../../shared/utility";
import classes from "./DesignerStatus.module.scss";
import { Button, Checkbox, Chip, TablePagination } from "@material-ui/core";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../../shared/defines";
import { ArrowDownward, ArrowUpward, Check, Close, GetApp, HighlightOff } from "@material-ui/icons";
import { json2excel } from "js2excel";
import Modal from "../../../components/UI/Modal/Modal";
import CustomSlider from "../../../components/UI/Slider/Slider";
import SearchInput from "../../../components/UI/SearchInput/SearchInput";
import moment from "moment";
import ProgressCircular from "../../../components/UI/ProgressCircular/ProgressCircular";
import ColumnsMultiSelect from "../../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

const formatterCurrency = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const sortingPhraseHelper = [
  { key: "accepted", path: "orders.accepted" },
  { key: "designer", path: "designers.code" },
  { key: "style_name", path: "products.style_name" },
  { key: "index", path: "products.index" },
  { key: "comments", path: "products.comments" },
  { key: "order_number", path: "orders.number" },
  { key: "shipment_date", path: "orders.shipment_date" },
  { key: "contact_name", path: "contacts.name" },
  { key: "port", path: "ports.code" },
  {
    key: "quantity",
    path: "(SELECT SUM(`combinations`.`quantity`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)",
  },
  {
    key: "price",
    path: "(SELECT AVG(`combinations`.`price`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)",
  },
  {
    key: "total",
    path: "(SELECT SUM(`combinations`.`quantity` * `combinations`.`price`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)",
  },
  { key: "index_check_date", path: "bookings.index_check_date" },
  { key: "sample_confirm_date", path: "bookings.sample_confirm_date" },
  { key: "booking_date", path: "bookings.booking_date" },
  { key: "readiness_date", path: "bookings.readiness_date" },
  { key: "forwarder_confirm_date", path: "bookings.forwarder_confirm_date" },
  { key: "quantity_invoice", path: "bookings.quantity_invoice" },
  { key: "price_invoice", path: "bookings.price_invoice" },
  {
    key: "value",
    path: "(SELECT SUM(quantity_invoice * price_invoice) FROM `bookings` WHERE `bookings`.`product_id` = products.id)",
  },
  { key: "lc_number", path: "orders.lc_number" },
  { key: "container_number", path: "containers.number" },
  { key: "invoice_number", path: "bookings.invoice_number" },
  { key: "etd", path: "containers.etd" },
  { key: "eta", path: "containers.eta" },
  { key: "warehouse", path: "containers.warehouse" },
  { key: "deposit", path: "bookings.deposit" },
  {
    key: "amount_percentage",
    path: "(SELECT ((SELECT SUM(quantity_invoice * 100) FROM `bookings` WHERE `bookings`.`product_id` = products.id) / (SELECT SUM(`combinations`.`quantity`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)))",
  },
  { key: "validated", path: "bookings.validated" },
  { key: "discount", path: "products.discount" },
  { key: "description", path: "descriptions.title" },
  { key: "sex", path: "sexes.code" },
  { key: "fabric", path: "fabrics.title" },
  // Wprowadzone 8.09.2022
  { key: "production_approval", path: "bookings.production_approval" },
  { key: "check_date", path: "bookings.check_date" },
  { key: "shipping_date_to_stores", path: "bookings.shipping_date_to_stores" },
  { key: "designer_comment", path: "bookings.designer_comment" },
  { key: "claim", path: "bookings.claim" },
  { key: "claim_content", path: "bookings.claim_content" },
  // Wprowadzone 13.06.2023
  { key: "movie_received", path: "bookings.movie_received" },
];

class DesignerStatus extends Component {
  state = {
    columns: Columns.designerStatusColumns,
    columnsHidden: Columns.designerStatusColumns,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalQuantity: 0,
    totalQuantityInvoice: 0,
    quantityPercentage: 0,
    quantityInvoicePercentage: 0,
    totalValue: 0,
    totalValueInvoice: 0,
    valuePercentage: 0,
    valueInvoicePercentage: 0,
    totalRow: 0,
    sortBy: null,
    order: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    hidden: false,
    loaded: false,
    isModal: false,
    rowData: null,
    exportChosen: false,
    modalValue: 0,
    filterPhrase: "",
    tableRef: React.createRef(),
  };

  componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );

    const localStorageLimit = localStorage.getItem(`${this.constructor.name}Limit`);
    if (localStorageLimit === null) {
      this.indexBookings(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    } else {
      this.setState({ limit: parseInt(localStorageLimit) });
      this.indexBookings(page - 1, localStorageLimit, search, sortingColumnsList, singleColumnFilterList);
    }

    this.indexPorts();

    document.querySelectorAll(".MuiTableSortLabel-root").forEach((icon, index) => {
      icon.addEventListener("click", () => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const table = this.state.tableRef.current;

        const columnsWithoutHidden = [...table.state.columns];

        let indexIsExist = this.state.sortingColumnsList.findIndex(
          (column) => column.sortBy.key === columnsWithoutHidden[index].field
        );
        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnsWithoutHidden[index].field);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "all");
        if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "empty");
        if (filter.column.key === "proforma_document" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (filter.column.key === "index" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (filter.column.key === "accepted" && filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`)
          table.onFilterChange(index, "accepted");
        if (
          filter.column.key === "proforma_document" &&
          filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
        )
          table.onFilterChange(index, "added");
        if (filter.column.key === "index" && filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`)
          table.onFilterChange(index, "added");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) table.onFilterChange(index, "hold");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`)
          table.onFilterChange(index, "cancel");
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => item.trim());
        table.onFilterChange(index, valueArray);
      }
    });
    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexBookings(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
  }

  handleSerach = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/designers/status-of-product?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexBookings(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
  };

  indexBookings = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons =
      this.props.currentSeasons !== null ? this.props.currentSeasons.map((season) => season.value) : [];
    const userId = this.props.user_id;

    const startTime = Date.now();
    axios
      .get(
        `/bookings/${page}/${limit}.json`,
        {
          params: {
            phrase: phrase,
            sortPath: sortPath,
            filterPath: filterPath,
            currentSeasons: currentSeasons,
            user_id: userId,
          },
        },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";
        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");

        const columnSettings = JSON.parse(window.localStorage.getItem(`user_status_of_product_${this.props.user_id}`));
        let columnsHidden = [];

        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        this.setState({
          data: res.data.bookings,
          totalRow: res.data.total_row,
          totalQuantity: res.data.total_quantity,
          totalValue: parseFloat(res.data.total_value),
          totalQuantityInvoice: res.data.total_quantity_invoice,
          totalValueInvoice: parseFloat(res.data.total_value_invoice),
          totalFinalOrders: res.data.total_final_orders,
          quantityPercentage: res.data.total_quantity_percentage,
          valuePercentage: res.data.total_value_percentage,
          quantityInvoicePercentage: res.data.total_quantity_invoice_percentage,
          valueInvoicePercentage: res.data.total_value_invoice_percentage,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.bookings,
          columnsHidden: columnsHidden,
          loaded: true,
        });

        if (responseTime > 10) {
          let ids = "";
          res.data.bookings.forEach((booking) => (ids += `${booking.id}, `));
          sendEmailAboutLoadingDelay(
            "Bookings",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["code"], res.data[i]["code"]);
        }
        let obj = Object.fromEntries(map);
        let newState = { ...this.state.columns };
        let newStateHidden = { ...this.state.columnsHidden };

        const portIndex = this.state.columns.findIndex((column) => column.field === "port");
        if (portIndex !== -1) {
          newState[portIndex].lookup = obj;
          newStateHidden[portIndex].lookup = obj;
          this.setState({ columns: Object.values(newState), columnsHidden: Object.values(newStateHidden) });
        }
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateDesignerBooking = (
    id,
    production_approval,
    movie_received,
    check_date,
    designer_comment,
    claim,
    claim_content
  ) => {
    const data = {
      id,
      production_approval,
      movie_received,
      check_date,
      designer_comment,
      claim,
      claim_content,
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .put(`/bookings/${id}/designer.json`, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const fetchedBooking = res.data.booking;
        const array = [...this.state.filteredData];
        const index = array.findIndex((x) => x.id === fetchedBooking.id);
        if (index !== -1) {
          array[index].production_approval = fetchedBooking.production_approval;
          array[index].movie_received = fetchedBooking.movie_received;
          array[index].check_date = fetchedBooking.check_date;
          array[index].designer_comment = fetchedBooking.designer_comment;
          array[index].claim = fetchedBooking.claim;
          array[index].claim_content = fetchedBooking.claim_content;
        }
        this.setState({ data: array });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        <ToastContainer />
        {this.state.isModal && (
          <Modal show={this.state.isModal} modalClosed={this.isModalHandler}>
            <div className={classes.WrapperModal}>
              <CustomSlider
                maxValue={parseInt(this.state.rowData.quantity_invoice.toString().replace(" ", ""))}
                onChange={this.modalValueHandler}
                tooltipLabel={`Based quantity: ${
                  parseInt(this.state.rowData.quantity_invoice.toString().replace(" ", "")) - this.state.modalValue
                } / Cloned quantity: ${this.state.modalValue}`}
              />
              <Button variant="contained" color="primary" className={classes.ModalButton} onClick={this.cloneBooking}>
                Clone Booking
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.ModalButton}
                onClick={this.isModalHandler}
              >
                Close
              </Button>
            </div>
          </Modal>
        )}
        <HotKeys />

        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("Status of product")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.hidden ? this.state.columns : this.state.columnsHidden}
          data={this.state.filteredData}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                resolve();
                this.updateDesignerBooking(
                  oldData.id,
                  newData.production_approval,
                  newData.movie_received,
                  newData.check_date,
                  newData.designer_comment,
                  newData.claim,
                  newData.claim_content
                );
              }),
          }}
          options={{
            pageSize: defaultPageSize,
            exportButton: false,
            exportFileName: "status_of_products_" + formatDate(Date.now()),
            filtering: true,
            search: false,
          }}
          onChangeRowsPerPage={(pages) => {
            this.setState({ limit: pages, loaded: false, data: [], filteredData: [] });
            localStorage.setItem(`${this.constructor.name}Limit`, pages);
            this.indexBookings(
              0,
              pages,
              this.state.filterPhrase,
              this.state.sortingColumnsList,
              this.state.singleColumnFilterList
            );
          }}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columnsHidden[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columnsHidden[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columnsHidden[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columnsHidden[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "select") {
                      if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "accepted" || value === "added")
                        value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                      if (indexIsExist !== -1) {
                        let multiFiltersValues = "";
                        value.forEach((filter, index) => {
                          multiFiltersValues +=
                            index === 0
                              ? `LIKE '%${value[index]}%'`
                              : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                        });
                        singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                        table.onFilterChange(columnId, value);
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "multiSelect",
                          },
                        ];
                        table.onFilterChange(columnId, value);
                      }
                    }
                    this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            Pagination: (props) => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={this.state.limit}
                  count={this.state.totalRow}
                  page={this.state.page}
                  onChangePage={(e, page) => {
                    this.state.tableRef.current.onChangePage(e, page);
                    this.setState({ page: page });
                    this.goPage(
                      page,
                      this.state.limit,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                />
              );
            },
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            Toolbar: (props) => {
              const table = this.state.tableRef.current;
              let totalQuantity = 0,
                totalValue = 0,
                totalQuantityInvoice = 0,
                totalValueInvoice = 0;
              for (let i = 0; i < props.data.length; i++) {
                if (props.data[i].id === props.data[i].product_id) {
                  totalQuantity += isNaN(props.data[i].quantity)
                    ? parseInt(props.data[i].quantity.replace(/\s/g, ""))
                    : parseInt(props.data[i].quantity);
                  totalValue += isNaN(props.data[i].total)
                    ? parseFloat(props.data[i].total.replace(",", ".").replace(/\s/g, ""))
                    : parseFloat(props.data[i].total);
                }
                totalQuantityInvoice += isNaN(props.data[i].quantity_invoice)
                  ? parseFloat(props.data[i].quantity_invoice.replace(/\s/g, ""))
                  : parseFloat(props.data[i].quantity_invoice);
                totalValueInvoice += isNaN(props.data[i].value)
                  ? parseFloat(props.data[i].value.replace(",", ".").replace(/\s/g, ""))
                  : parseFloat(props.data[i].value);
              }
              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 5 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      this.goPage();
                      this.props.history.push(`/designers/status-of-product?page=${1}&limit=${20}`);

                      table.state.columns.forEach((column, index) => {
                        if (column.field === "accepted") {
                          table.onFilterChange(index, "all");
                          table.state.columns[index].tableData.filterValue = "all";
                        } else {
                          table.onFilterChange(index, null);
                          table.state.columns[index].tableData.filterValue = null;
                          column.tableData.initialValue = "";
                        }
                      });
                    }}
                  />

                  <div className={classes.exportToExcelWrapper}>
                    {/* Eksportowanie danych z tabeli */}
                    <Chip
                      variant="outlined"
                      style={{ marginLeft: 24, marginTop: 10 }}
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => {
                        const startTime = Date.now();
                        this.setState({ loaded: false, filteredData: [] });
                        const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                        const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                          this.props.location.search,
                          sortingPhraseHelper
                        );
                        const sortPath = getSortPath(sortingColumnsList);
                        const filterPath = getFilterPath(singleColumnFilterList);

                        axios
                          .get(
                            `/export-bookings-to-excel.json`,
                            {
                              params: {
                                phrase: search,
                                sortPath: sortPath,
                                filterPath: filterPath,
                                currentSeasons: currentSeasons,
                                user_id: this.props.user_id,
                              },
                            },
                            { headers: { Authorization: `Bearer ${this.props.token}` } }
                          )
                          .then((res) => {
                            console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                            const bookingsData = res.data.bookings;
                            let filteredBookingsData = bookingsData.map((booking) => {
                              return {
                                id: booking.id,
                                accepted:
                                  !booking.accepted || booking.accepted.trim() === ""
                                    ? null
                                    : booking.accepted.includes("CANCEL")
                                    ? booking.accepted
                                    : booking.accepted.includes("HOLD")
                                    ? booking.accepted
                                    : new Date(booking.accepted),
                                designer: booking.designer,
                                style_name: booking.style_name,
                                index: booking.index,
                                order_number: booking.order_number,
                                shipment_date: booking.shipment_date ? new Date(booking.shipment_date) : null,
                                contact_name: booking.contact_name,
                                quantity: booking.quantity
                                  ? parseInt(booking.quantity.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                price: booking.price
                                  ? parseFloat(booking.price.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                production_approval: booking.production_approval
                                  ? new Date(booking.production_approval)
                                  : null,
                                movie_received: booking.movie_received,
                                index_check_date: booking.index_check_date ? new Date(booking.index_check_date) : null,
                                sample_confirm_date: booking.sample_confirm_date
                                  ? new Date(booking.sample_confirm_date)
                                  : null,
                                discount: booking.discount,
                                etd: booking.etd ? new Date(booking.etd) : null,
                                eta: booking.eta ? new Date(booking.eta) : null,
                                warehouse: booking.warehouse ? new Date(booking.warehouse) : null,
                                shipping_date_to_stores: booking.shipping_date_to_stores
                                  ? new Date(booking.shipping_date_to_stores)
                                  : null,
                                check_date: booking.check_date ? new Date(booking.check_date) : null,
                                designer_comment: booking.designer_comment,
                                comments: booking.comments,
                                claim: booking.claim,
                                claim_content: booking.claim_content,
                                sex: booking.sex,
                                fabric: booking.fabric,
                                description: booking.description,
                                quantity_invoice: booking.quantity_invoice
                                  ? parseInt(booking.quantity_invoice.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                price_invoice: booking.price_invoice,
                                lc_number: booking.lc_number,
                              };
                            });

                            if (this.state.exportChosen) {
                              const exportColumns = this.state.columnsHidden.map((column) => {
                                if (column.field === "shipment_date") column.field = "shipment_per_order";
                                if (column.field === "contact_name") column.field = "shipper_name";
                                if (column.field === "price") column.field = "price_per_order";
                                if (column.field === "total") column.field = "total_per_order";
                                if (column.field === "quantity") column.field = "quantity_per_order";
                                if (column.field === "quantity_invoice")
                                  column.field = "quantity_as_per_commercial_invoices";
                                if (column.field === "price_invoice") column.field = "price_as_per_commercial_invocies";
                                if (column.field === "value") column.field = "value_per_commercial_invoices";
                                if (column.field === "deposit") column.field = "deposit_date";
                                return column.field;
                              });

                              filteredBookingsData = filteredBookingsData.map((row) => {
                                const toDelete = exportColumns;
                                const filtered = Object.keys(row)
                                  .filter((key) => toDelete.includes(key))
                                  .reduce((obj, key) => {
                                    obj[key] = row[key];
                                    return obj;
                                  }, {});

                                return filtered;
                              });
                            }

                            json2excel({
                              data: filteredBookingsData,
                              name: "status_of_products_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            this.setState({ loaded: true, filteredData: this.state.data });
                          })
                          .catch((err) => {
                            catchResponse(err);
                          });
                      }}
                    />
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={this.state.exportChosen}
                        size={"small"}
                        onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                        color="primary"
                      />
                      <span>Export only chosen columns</span>
                    </div>
                  </div>

                  {/*Generowanie tagu szukania*/}
                  {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        this.goPage(
                          0,
                          this.state.limit,
                          "",
                          this.state.sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ filterPhrase: "" });
                      }}
                      label={`search: ${this.state.filterPhrase}`}
                      color="primary"
                    />
                  )}

                  {/*Generowanie tagów filtrów kolumn*/}
                  {this.state.singleColumnFilterList.map((filter) => {
                    let value;
                    let key;
                    let icon = "";
                    if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                    else if (filter.type === "text")
                      value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                    else if (filter.type === "select")
                      value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                    else if (filter.type === "multiSelect")
                      value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",");
                    else if (filter.type === "number") value = filter.value.replace("=", "");
                    else if (filter.type === "boolean") {
                      if (filter.value.includes("true")) {
                        value = "checked";
                        icon = <Check />;
                      } else {
                        value = "unchecked";
                        icon = <Close />;
                      }
                    }

                    /*WYJĄTKI*/
                    if (
                      filter.column.key === "accepted" &&
                      filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`
                    )
                      value = "ALL";
                    if (filter.column.key === "index" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
                      value = "ALL";
                    if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                      value = "EMPTY";
                    if (
                      filter.column.key === "accepted" &&
                      filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`
                    )
                      value = "ACCEPTED";
                    if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) value = "HOLD";
                    if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`) value = "CANCEL";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `="" OR ${filter.column.path} IS NULL`
                    )
                      value = "UNADDED";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`
                    )
                      value = "ADDED";
                    if (filter.column.key === "index" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                      value = "UNADDED";
                    if (filter.column.key === "index" && filter.value === `<> '' AND ${filter.column.path} IS NOT NULL`)
                      value = "ADDED";
                    filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);
                    if (filter.column.key === "payment_term_id") {
                      let currentValue = "";
                      const column = this.state.columns.filter((item) => item.field === "payment_term_id")[0].lookup;
                      const paymnetValue = value.replace(/\s/g, "").split(",");
                      paymnetValue.forEach((item, index) =>
                        index + 1 === paymnetValue.length
                          ? (currentValue += `${column[parseInt(item)]}`)
                          : (currentValue += `${column[parseInt(item)]}, `)
                      );
                      value = currentValue;
                      key = "PAYMENT";
                    }

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                            (item) => item.column.key !== filter.column.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            this.state.sortingColumnsList,
                            singleColumnFilterList
                          );
                          this.setState({ singleColumnFilterList: singleColumnFilterList });
                          const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                          if (index !== -1) {
                            if (table.state.columns[index].field === "accepted") {
                              table.onFilterChange(index, "all");
                            } else {
                              table.state.columns[index].tableData.initialValue = "";
                              table.onFilterChange(index, null);
                            }
                          }
                        }}
                        label={`${key.replace("_", " ").toUpperCase()}:  ${value.toUpperCase()}`}
                        icon={icon}
                        variant="outlined"
                      />
                    );
                  })}

                  {/*Generowanie tagów sortowania*/}
                  {this.state.sortingColumnsList.map((column) => {
                    let key;
                    let icon = "";
                    if (column.order === "ASC") icon = <ArrowDownward />;
                    if (column.order === "DESC") icon = <ArrowUpward />;

                    /*WYJĄTKI*/
                    column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const sortingColumnsList = this.state.sortingColumnsList.filter(
                            (item) => item.sortBy.key !== column.sortBy.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ sortingColumnsList: sortingColumnsList });
                        }}
                        label={`${key.toUpperCase().replace("_", " ")}`}
                        icon={icon}
                      />
                    );
                  })}

                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "bookings",
                          };
                        })}
                      options={Columns.designerStatusColumns
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "bookings",
                          };
                        })}
                      onChangeColumns={(columns) => {
                        let selectedColumns = [];
                        columns.forEach((column) => {
                          this.state.columns.forEach((item) => {
                            if (column.value === item.field) {
                              selectedColumns = [...selectedColumns, item];
                            }
                          });
                        });

                        this.setState({
                          columnsHidden: selectedColumns,
                          currentStateColumns: selectedColumns,
                        });
                      }}
                    />
                    <Button
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_status_of_product_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_status_of_product_${this.props.user_id}`);
                          this.setState({
                            columnsHidden: this.state.columns,
                            currentStateColumns: null,
                          });
                          toast.success("Column settings have been removed!");
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>

                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                  </div>
                </div>
              );
            },
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(DesignerStatus);
