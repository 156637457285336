import React, { Component } from "react";
import axios from "../../../axios-sw";
import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { tableIcons, titleTemplate } from "../../../shared/tableVariables";

import Columns from "../../../shared/tables";
import { HotKeys } from "../../../components/System/HotKeys/HotKeys";
import { authRedirect, catchResponse, formatDate, roleLimitedView } from "../../../shared/utility";
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../../shared/defines";
import { GetApp, HighlightOff } from "@material-ui/icons";
import { json2excel } from "js2excel";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

class Bookings extends Component {
  state = {
    columns: Columns.vm.bookings,
    data: [],
    loaded: false,
  };

  componentDidMount() {
    this.indexPorts();
    this.indexBookings();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexBookings();
    }
  }

  indexBookings = () => {
    const startTime = Date.now();
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    axios
      .get(
        "/bookings.json",
        { params: { currentSeasons: currentSeasons } },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
        this.setState({ data: res.data, loaded: true });
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };
  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["code"], res.data[i]["code"]);
        }
        let obj = Object.fromEntries(map);
        let newState = { ...this.state.columns };
        newState[10].lookup = obj;
        this.setState({ columns: Object.values(newState) });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    let tableRef = React.createRef();

    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        {authRedirect(this.props.token)}
        {roleLimitedView("vm", this.props.role, this.props.user_id)}
        <ToastContainer />
        <HotKeys />

        <MaterialTable
          tableRef={tableRef}
          title={titleTemplate("VM Bookings")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={this.state.data}
          options={{
            pageSize: defaultPageSize,
            filtering: true,
          }}
          components={{
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Toolbar: (props) => {
              let totalQuantity = 0,
                totalValue = 0;
              for (let i = 0; i < props.data.length; i++) {
                totalQuantity += parseInt(props.data[i].quantity.replace(/\s/g, ""));
                totalValue += parseFloat(props.data[i].total.replace(/\s/g, ""));
              }
              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      const table = tableRef.current;
                      for (let i = 0; i < table.state.columns.length; i++) {
                        table.onFilterChange(i, null);
                        table.state.columns[i].tableData.filterValue = null;
                      }
                    }}
                  />

                  {/* Eksportowanie danych z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24 }}
                    avatar={<GetApp style={{ padding: 5 }} />}
                    label="Export data to Excel"
                    onClick={() => {
                      const data = tableRef.current.state.data;
                      const columns = tableRef.current.state.columns;

                      data.forEach((row) => {
                        const columnsToReplace = ["port"];
                        for (let i = 0; i < columns.length; i++) {
                          for (let j = 0; j < columnsToReplace.length; j++) {
                            // Podmiana ID na wybrane frazy ze zdefiniowanej listy
                            if (columns[i].field === columnsToReplace[j]) {
                              for (let key in columns[i].lookup) {
                                if (parseInt(key) === parseInt(row[columnsToReplace[j]])) {
                                  row[columnsToReplace[j]] = columns[i].lookup[key];
                                }
                              }
                            }
                          }
                        }
                        const columnsToConvert = [
                          "quantity",
                          "price",
                          "total",
                          "price_invoice",
                          "quantity_invoice",
                          "value",
                        ];
                        for (let i = 0; i < columns.length; i++) {
                          for (let j = 0; j < columnsToConvert.length; j++) {
                            if (columns[i].field === columnsToConvert[j] && row[columnsToConvert[j]] !== null) {
                              row[columnsToConvert[j]] = parseFloat(
                                row[columnsToConvert[j]].replace(/\s/g, "").replace(",", ".")
                              );
                            }
                          }
                        }

                        row.shipment_date = row.shipment_date ? new Date(row.shipment_date) : null;
                        row.index_check_date = row.index_check_date ? new Date(row.index_check_date) : null;
                        row.sample_confirm_date = row.sample_confirm_date ? new Date(row.sample_confirm_date) : null;
                        row.booking_date = row.booking_date ? new Date(row.booking_date) : null;
                        row.readiness_date = row.readiness_date ? new Date(row.readiness_date) : null;
                        row.forwarder_confirm_date = row.forwarder_confirm_date
                          ? new Date(row.forwarder_confirm_date)
                          : null;
                        row.eta = row.eta ? new Date(row.eta) : null;
                        row.etd = row.etd ? new Date(row.etd) : null;
                        row.warehouse = row.warehouse ? new Date(row.warehouse) : null;
                        row.deposit = row.deposit ? new Date(row.deposit) : null;

                        delete row["order_id"];
                        delete row["product_id"];
                        delete row["styles"];
                        delete row["tableData"];
                        delete row["validated"];
                        delete row["discount"];
                      });

                      json2excel({
                        data,
                        name: "bookings_" + formatDate(Date.now()),
                        formateDate: "dd/mm/yyyy",
                      });
                    }}
                  />

                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(totalQuantity)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              );
            },
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Bookings);
