import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, {
  MTableBody,
  MTableBodyRow,
  MTableEditRow,
  MTableFilterRow,
  MTableToolbar,
} from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";

import { Button, Checkbox, Chip, FormControl, Input, TablePagination } from "@material-ui/core";

import Columns from "../../shared/tables";
import MultipackProducts from "./MultipackProducts/MultipackProducts";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";

import {
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  formatDate,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  postFile,
  sendEmailAboutLoadingDelay,
  thenResponse,
} from "../../shared/utility";

import { ArrowDownward, ArrowUpward, Check, Close, GetApp, HighlightOff } from "@material-ui/icons";
import Spinner from "../../components/UI/Spinner/Spinner";

import { defaultPageSize } from "../../shared/defines";

import { json2excel } from "js2excel";
import Modal from "../../components/UI/Modal/Modal";
import classes from "../Bookings/Bookings.module.scss";
import ReactSelect from "react-select";
import moment from "moment";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";
import BeenhereIcon from "@material-ui/icons/Beenhere";

const sortingPhraseHelper = [
  { key: "barcode_multi", path: "multipacks.barcode_multi" },
  { key: "barcode_check", path: "multipacks.barcode_check" },
  { key: "contact_person", path: "contacts.name" },
  { key: "quantity_of_multi", path: "multipacks.quantity_of_multi" },
  {
    key: "total_pcs_per_one_ctn",
    path:
      "(SELECT SUM(`product_multipacks`.`quantity_per_each_style_per_one_carton`) FROM `product_multipacks` WHERE `product_multipacks`.`multipack_id` = multipacks.id )",
  },
  {
    key: "total_pcs",
    path:
      "(SELECT (SUM(`product_multipacks`.`quantity_per_each_style_per_one_carton`) * multipacks.quantity_of_multi) FROM `product_multipacks` WHERE `product_multipacks`.`multipack_id` = multipacks.id )",
  },
  { key: "sent_to_shipper", path: "multipacks.sent_to_shipper" },
  { key: "confirmed_by_shipper", path: "multipacks.confirmed_by_shipper" },
  { key: "shipped", path: "multipacks.shipped" },
  { key: "season", path: "seasons.code" },
  { key: "dimesion_of_carton", path: "multipacks.dimesion_of_carton" },
  { key: "comments", path: "multipacks.comments" },
  { key: "attachment", path: "multipacks.attachment" },
];

class Multipacks extends Component {
  state = {
    columns: Columns.multipacksColumns,
    columnsHidden: Columns.multipacksColumns,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalRow: 0,
    filterPhrase: "",
    sortBy: null,
    multipack: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    firstLoad: true,
    tableRef: React.createRef(),
    exportChosen: false,
    loaded: false,
    rowData: null,
    editable: {},
    actions: [],
    blockedSaveColumnsButton: true,
  };

  /*COMPONENT LIFE CYCLE FUNCTIONS*/
  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );
    this.indexMultipacks(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);

    this.assigmentFiltersValueToInputs(singleColumnFilterList);
    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexMultipacks(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
    if (this.props.role !== prevProps.role) this.checkActionsAndEditablePermissions();
  }

  /*CRUD FUNCTIONS*/
  createMultipack = (newData) => {
    const multipackData = {
      multipack: {
        barcode_multi: !newData.barcode_multi ? null : newData.barcode_multi.toUpperCase().trim(),
        barcode_check: newData.barcode_check,
        quantity_of_multi: !newData.quantity_of_multi ? null : newData.quantity_of_multi,
        sent_to_shipper: !newData.sent_to_shipper ? null : newData.sent_to_shipper,
        confirmed_by_shipper: !newData.confirmed_by_shipper ? null : newData.confirmed_by_shipper,
        shipped: !newData.shipped ? false : newData.shipped,
        attachment: newData.attachment ? { url: newData.attachment } : null,
        dimesion_of_carton: newData.dimesion_of_carton,
        comments: newData.comments,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };

    const url = `/multipacks.json`;
    axios
      .post(url, multipackData, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
              this.props.location.search,
              sortingPhraseHelper
            );
            this.indexMultipacks(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
          })
          .then(() => {
            postFile(
              "/multipack-upload-attachment/",
              "attachment",
              newData,
              this.props.user_id,
              this.props.ip_address,
              this.props.token,
              () => {
                axios
                  .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
                  .then((res) => {
                    this.setMultipack(res.data);
                  })
                  .catch((err) => {
                    catchResponse(err);
                  });
              }
            );
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateMultipack = (id, newData) => {
    const multipackData = {
      multipack: {
        barcode_multi: !newData.barcode_multi ? null : newData.barcode_multi.toUpperCase().trim(),
        barcode_check: newData.barcode_check,
        quantity_of_multi: !newData.quantity_of_multi ? null : newData.quantity_of_multi,
        sent_to_shipper: !newData.sent_to_shipper ? null : newData.sent_to_shipper,
        confirmed_by_shipper: !newData.confirmed_by_shipper ? null : newData.confirmed_by_shipper,
        attachment: newData.attachment ? { url: newData.attachment } : null,
        shipped: !newData.shipped ? false : newData.shipped,
        dimesion_of_carton: newData.dimesion_of_carton,
        comments: newData.comments,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };

    const url = `/multipacks/${id}.json`;

    axios
      .put(url, multipackData, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            this.setMultipack(res.data);
          })
          .then(() => {
            postFile(
              "/multipack-upload-attachment/",
              "attachment",
              newData,
              this.props.user_id,
              this.props.ip_address,
              this.props.token,
              () => {
                axios
                  .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
                  .then((res) => {
                    this.setMultipack(res.data);
                  })
                  .catch((err) => {
                    catchResponse(err);
                  });
              }
            );
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  deleteMultipack = (id) => {
    axios
      .get("/multipack-products/" + id + ".json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        if (res.data.length === 0) {
          const data = {
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
          };
          axios
            .delete("/multipacks/" + id + ".json", {
              data: data,
              headers: { Authorization: `Bearer ${this.props.token}` },
            })
            .then((res) => {
              const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                this.props.location.search,
                sortingPhraseHelper
              );
              this.indexMultipacks(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
              thenResponse(res);
            })
            .catch((err) => {
              catchResponse(err);
            });
        } else {
          toast.error("You can not delete Multipack if has products!");
        }
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  /*HELPER FUNCTIONS FOR COMPONENT*/
  indexMultipacks = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    const startTime = Date.now();

    axios
      .get(
        `/multipacks/${page}/${limit}.json`,
        {
          params: {
            phrase: phrase,
            sortPath: sortPath,
            filterPath: filterPath,
            currentSeasons: currentSeasons,
          },
        },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");
        const columnSettings = JSON.parse(window.localStorage.getItem(`user_multipacks_${this.props.user_id}`));
        let columnsHidden = [];

        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        this.setState({
          data: res.data.multipacks,
          totalRow: res.data.total_row,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.multipacks,
          columnsHidden: columnsHidden,
          loaded: true,
        });

        if (responseTime > 10) {
          let ids = "";
          res.data.multipacks.forEach((multipack) => (ids += `${multipack.id}, `));
          sendEmailAboutLoadingDelay(
            "Multipacks",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  setMultipack = (fetchedMultipack) => {
    const array = [...this.state.data];
    const index = array.findIndex((x) => x.id === fetchedMultipack.id);
    if (index !== -1) {
      array[index].barcode_multi = fetchedMultipack.barcode_multi;
      array[index].barcode_check = fetchedMultipack.barcode_check;
      array[index].quantity_of_multi = fetchedMultipack.quantity_of_multi;
      array[index].total_pcs_per_one_ctn = fetchedMultipack.total_pcs_per_one_ctn;
      array[index].total_pcs = fetchedMultipack.total_pcs;
      array[index].sent_to_shipper = fetchedMultipack.sent_to_shipper;
      array[index].confirmed_by_shipper = fetchedMultipack.confirmed_by_shipper;
      array[index].attachment = fetchedMultipack.attachment;
      array[index].shipped = fetchedMultipack.shipped;
      array[index].dimesion_of_carton = fetchedMultipack.dimesion_of_carton;
      array[index].comments = fetchedMultipack.comments;
    }
    this.setState({ data: array });
  };

  confirmDialog = (msg) => {
    return new Promise(function(resolve, reject) {
      let confirmed = window.confirm(msg);
      return confirmed ? resolve(true) : reject(false);
    });
  };

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_multipacks_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  handleSerach = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/multipacks?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexMultipacks(
      page,
      limit,
      search,
      sortingColumnsList,
      singleColumnFilterList,
      this.state.currentStateColumns
    );
  };

  assigmentFiltersValueToInputs = (singleColumnFilterList) => {
    const table = this.state.tableRef.current;
    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value
          .replace("LIKE", "")
          .replace("'%", "")
          .replace("%'", "")
          .trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => item.trim());
        table.onFilterChange(index, valueArray);
      }
    });
    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  };

  mapOrder = (array, order, key) => {
    array.sort((a, b) => {
      let A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  };

  fetchDataMultipackProducts = async (multipacksData) => {
    const multipacksBarcodeMulti = multipacksData.map((item) => item.barcode_multi);

    const fetchURL = (url, params) => axios.get(url, params);
    let multipackProductsData = [];

    let promiseArray = multipacksData.map((item) => {
      return fetchURL(`/multipack-products/${item.id}.json`, {
        params: {
          filterByColumn: this.state.filterByColumn ? this.state.filterByColumn.value : null,
          filterByConditional: this.state.filterByConditional ? this.state.filterByConditional.value : null,
        },
      });
    });

    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          multipackProductsData = [...multipackProductsData, ...item.data];
        });
      })
      .catch((err) => {
        toast.error(err);
      });

    /*FILTROWANIE DANYCH NA PODSTAWIE FILTRÓW*/
    let filteredMultipackProductsData = [];
    multipackProductsData.map((multipackProduct) => {
      multipacksBarcodeMulti.map((barcodeMulti) => {
        if (multipackProduct.barcode_multi === barcodeMulti) {
          filteredMultipackProductsData = [...filteredMultipackProductsData, multipackProduct];
        }
      });
    });

    /*SORTOWANIE DANYCH NA PODSTAWIE KOLEJNOŚCI KONTENERÓW*/
    filteredMultipackProductsData = this.mapOrder(
      filteredMultipackProductsData,
      multipacksBarcodeMulti,
      "barcode_multi"
    );

    return filteredMultipackProductsData;
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              resolve();
              this.createMultipack(newData);
            }),
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              resolve();
              this.updateMultipack(newData.id, newData);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              this.deleteMultipack(oldData.id);
            }),
        },
        actions: [],
      });
    }
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        <ToastContainer />
        <HotKeys />
        {this.state.isModal && (
          <Modal show={this.state.isModal} modalClosed={this.isModalHandler}>
            <div className={classes.WrapperModal}>
              <p>{`Multipack number: ${this.state.rowData.number}`}</p>
              <FormControl className={classes.FormControlStyleWithSelect}>
                <p className={classes.Label}>Multipack number</p>
                <div className={classes.Wrapper}>
                  <div className={classes.ReactSelect}>
                    <ReactSelect
                      placeholder=""
                      onChange={(e) => {
                        this.setState({ season: e });
                      }}
                      value={this.state.season}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={this.state.seasons}
                    />
                  </div>
                  <div className={classes.ReactSelect}>
                    <ReactSelect
                      placeholder=""
                      onChange={(e) => {
                        this.setState({ designer: e });
                      }}
                      value={this.state.designer}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={this.state.designers}
                    />
                  </div>
                  <div className={classes.ReactSelectContact}>
                    <ReactSelect
                      placeholder=""
                      onChange={(e) => {
                        this.setState({ contact: e });
                      }}
                      value={this.state.contact}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={this.state.contacts}
                    />
                  </div>
                  <div className={classes.StyleInput}>
                    <Input placeholder={"23"} inputRef={this.state.multipack_number} />
                  </div>
                </div>
                <div className={classes.checkboxWrapper}>
                  <Checkbox
                    checked={this.state.repeatMultipack}
                    size={"small"}
                    onChange={() => this.setState({ repeatMultipack: true })}
                    color="primary"
                  />
                  <span>Repeat multipack</span>
                </div>
              </FormControl>
              <Button variant="contained" color="primary" className={classes.ModalButton} onClick={this.cloneMultipack}>
                Clone Multipack
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.ModalButton}
                onClick={this.isModalHandler}
              >
                Close
              </Button>
            </div>
          </Modal>
        )}
        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("Multipacks")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          columns={this.state.columnsHidden}
          data={this.state.filteredData}
          actions={this.state.actions}
          style={{ width: "100%" }}
          options={{
            search: false,
            pageSize: defaultPageSize,
            filtering: true,
          }}
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <MultipackProducts
                  multipackId={rowData.id}
                  setMultipack={this.setMultipack}
                  multipackData={rowData}
                  barcodeMulti={rowData.barcode_multi}
                  user_id={this.props.user_id}
                  ip_address={this.props.ip_address}
                  token={this.props.token}
                  role={this.props.role}
                />
              </div>
            );
          }}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columnsHidden[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columnsHidden[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columnsHidden[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columnsHidden[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "select") {
                      if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "accepted" || value === "added")
                        value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                      if (this.state.columnsHidden[columnId].field === "accepted") {
                        let convertValue = "";
                        value.forEach((item, index) => {
                          if (item === "all")
                            convertValue +=
                              index < value.length - 1
                                ? `LIKE "%%" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                : `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                          else if (item === "empty" || item === "unadded")
                            convertValue +=
                              index < value.length - 1
                                ? ` ="" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                : `="" OR ${sqlColumn[0].path} IS NULL`;
                          else if (item === "accepted" || item === "added")
                            convertValue +=
                              index < value.length - 1
                                ? `OR ${sqlColumn[0].path} <> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} OR ${sqlColumn[0].path} `
                                : `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                          else
                            convertValue +=
                              index < value.length - 1
                                ? `LIKE "%${item}%" OR ${sqlColumn[0].path} `
                                : `LIKE "%${item}%"`;
                        });

                        if (value.length > 0) {
                          if (indexIsExist !== -1) {
                            singleColumnFilterList[indexIsExist].value = convertValue;
                            table.state.columns[columnId].tableData.initialValue = value;
                          } else {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: convertValue,
                                type: "multiSelect",
                              },
                            ];
                            table.state.columns[columnId].tableData.initialValue = value;
                          }
                        } else {
                          singleColumnFilterList = singleColumnFilterList.filter(
                            (filter) => filter.column.key !== "accepted"
                          );
                          table.state.columns[columnId].tableData.initialValue = ["all"];
                        }
                      } else {
                        if (indexIsExist !== -1) {
                          let multiFiltersValues = "";
                          value.forEach((filter, index) => {
                            multiFiltersValues +=
                              index === 0
                                ? `LIKE '%${value[index]}%'`
                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                          });
                          singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                          table.onFilterChange(columnId, value);
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "multiSelect",
                            },
                          ];
                          table.onFilterChange(columnId, value);
                        }
                      }
                    }
                    this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            Pagination: (props) => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={this.state.limit}
                  count={this.state.totalRow}
                  page={this.state.page}
                  onChangePage={(e, page) => {
                    this.state.tableRef.current.onChangePage(e, page);
                    this.setState({ page: page });
                    this.goPage(
                      page,
                      this.state.limit,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.setState({ limit: event.target.value });
                    this.goPage(
                      this.state.page,
                      event.target.value,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                />
              );
            },
            FilterRow: (props) => {
              return <MTableFilterRow className="filterRow" {...props} />;
            },
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Toolbar: (props) => {
              const table = this.state.tableRef.current;

              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 5 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      this.goPage();
                      this.props.history.push(`/multipacks?page=${1}&limit=${20}`);

                      table.state.columns.forEach((column, index) => {
                        if (column.field === "accepted") {
                          table.state.columns[index].tableData.filterValue = "all";
                          column.tableData.initialValue = "all";
                        } else {
                          table.onFilterChange(index, null);
                          table.state.columns[index].tableData.filterValue = null;
                          column.tableData.initialValue = "";
                        }
                      });
                    }}
                  />
                  <div className={classes.exportToExcelWrapper}>
                    {/* Eksportowanie danych z tabeli */}
                    <Chip
                      variant="outlined"
                      style={{ marginLeft: 24, marginTop: 10 }}
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => {
                        const startTime = Date.now();
                        this.setState({ loaded: false, filteredData: [] });
                        const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                        const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                          this.props.location.search,
                          sortingPhraseHelper
                        );
                        const sortPath = getSortPath(sortingColumnsList);
                        const filterPath = getFilterPath(singleColumnFilterList);

                        axios
                          .get(
                            `/export-multipacks-to-excel.json`,
                            {
                              params: {
                                phrase: search,
                                sortPath: sortPath,
                                filterPath: filterPath,
                                currentSeasons: currentSeasons,
                              },
                            },
                            { headers: { Authorization: `Bearer ${this.props.token}` } }
                          )
                          .then(async (res) => {
                            console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                            const multipacksData = res.data.multipacks;
                            let dataMultipackProducts = await this.fetchDataMultipackProducts(multipacksData);

                            let filteredMultipacksData = multipacksData.map((multipack) => {
                              return {
                                barcode_multi: multipack.barcode_multi,
                                contact_person: multipack.contact_person,
                                quantity_of_multi: multipack.quantity_of_multi,
                                total_pcs_per_one_ctn: multipack.total_pcs_per_one_ctn,
                                total_pcs: multipack.total_pcs,
                                sent_to_shipper: multipack.sent_to_shipper,
                                confirmed_by_shipper: multipack.confirmed_by_shipper,
                                shipped: multipack.shipped,
                                season: multipack.season,
                                attachment: multipack.attachment,
                                dimesion_of_carton: multipack.dimesion_of_carton,
                                comments: multipack.comments,
                              };
                            });

                            let filteredMultipackProductsData = dataMultipackProducts.map((product) => {
                              return {
                                order_number: product.order_number,
                                style_name: product.style_name,
                                index: product.index,
                                quantity_per_each_style_per_one_carton: product.quantity_per_each_style_per_one_carton,
                                quantity_of_each_style_in_all_cartons: product.quantity_of_each_style_in_all_cartons,
                                barcode_multi: product.barcode_multi,
                              };
                            });

                            if (this.state.exportChosen) {
                              const exportColumns = this.state.columnsHidden;

                              filteredMultipacksData = filteredMultipacksData.map((row) => {
                                const toDelete = exportColumns;
                                const filtered = Object.keys(row)
                                  .filter((key) => toDelete.includes(key))
                                  .reduce((obj, key) => {
                                    obj[key] = row[key];
                                    return obj;
                                  }, {});

                                return filtered;
                              });
                            }

                            json2excel({
                              data: filteredMultipacksData,
                              name: "multipacks_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            json2excel({
                              data: filteredMultipackProductsData,
                              name: "mul_products_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            this.setState({ loaded: true, filteredData: this.state.data });
                          })
                          .catch((err) => {
                            catchResponse(err);
                          });
                      }}
                    />
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={this.state.exportChosen}
                        size={"small"}
                        onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                        color="primary"
                      />
                      <span>Export only chosen columns</span>
                    </div>
                  </div>

                  {/*Generowanie tagu szukania*/}
                  {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        this.goPage(
                          0,
                          this.state.limit,
                          "",
                          this.state.sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ filterPhrase: "" });
                      }}
                      label={`search: ${this.state.filterPhrase}`}
                      color="primary"
                    />
                  )}

                  {/*Generowanie tagów filtrów kolumn*/}
                  {this.state.singleColumnFilterList.map((filter) => {
                    let value;
                    let key = filter.column.key;
                    let icon = "";
                    if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                    else if (filter.type === "text")
                      value = filter.value
                        .replace("LIKE", "")
                        .replace("'%", "")
                        .replace("%'", "");
                    else if (filter.type === "select")
                      value = filter.value
                        .replace("LIKE", "")
                        .replace("'%", "")
                        .replace("%'", "");
                    else if (filter.type === "multiSelect")
                      value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",");
                    else if (filter.type === "number") value = filter.value.replace("=", "");
                    else if (filter.type === "boolean") {
                      if (filter.value.includes("true")) {
                        value = "checked";
                        icon = <Check />;
                      } else {
                        value = "unchecked";
                        icon = <Close />;
                      }
                    }

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                            (item) => item.column.key !== filter.column.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            this.state.sortingColumnsList,
                            singleColumnFilterList
                          );
                          this.setState({ singleColumnFilterList: singleColumnFilterList });
                          const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                          if (index !== -1) {
                            if (table.state.columns[index].field === "accepted") {
                              table.state.columns[index].tableData.initialValue = ["all"];
                            } else {
                              table.state.columns[index].tableData.initialValue = "";
                              table.onFilterChange(index, null);
                            }
                          }
                        }}
                        label={`${this.state.columns
                          .find((item) => item.field === key)
                          .title.toUpperCase()}:  ${value.toUpperCase()}`}
                        icon={icon}
                        variant="outlined"
                      />
                    );
                  })}

                  {/*Generowanie tagów sortowania*/}
                  {this.state.sortingColumnsList.map((column) => {
                    let key = column.sortBy.key;
                    let icon = "";
                    if (column.order === "ASC") icon = <ArrowDownward />;
                    if (column.order === "DESC") icon = <ArrowUpward />;

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const sortingColumnsList = this.state.sortingColumnsList.filter(
                            (item) => item.sortBy.key !== column.sortBy.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ sortingColumnsList: sortingColumnsList });
                        }}
                        label={`${this.state.columns.find((item) => item.field === key).title.toUpperCase()}`}
                        icon={icon}
                      />
                    );
                  })}

                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      onOpen={() =>
                        !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                      }
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "multipack_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "multipacks",
                          };
                        })}
                      options={Columns.multipacksColumns
                        .filter((column) => column.field !== "multipack_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "multipacks",
                          };
                        })}
                      onChangeColumns={async (columns) => {
                        await this.setState({ columnsHidden: [], currentStateColumns: null });
                        await this.setSelectedColumns(columns);
                        this.setState({ blockedSaveColumnsButton: false });
                        this.setSortingForColumns();
                      }}
                    />
                    <Button
                      disabled={this.state.blockedSaveColumnsButton}
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_multipacks_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                          this.setState({ blockedSaveColumnsButton: true });
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_multipacks_${this.props.user_id}`);
                          await this.setState({ columnsHidden: this.state.columns, currentStateColumns: null });
                          toast.success("Column settings have been removed!");
                          this.setSortingForColumns();
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>

                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                  </div>
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Multipacks);
