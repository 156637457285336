import React, { Component } from "react";
import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";

import MaterialTable, { MTableEditRow, MTableToolbar, MTableBodyRow, MTableFilterRow } from "material-table";
import { titleTemplate, tableIcons } from "../../../shared/tableVariables";

import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import Columns from "../../../shared/tables";
import OrderProducts from "./OrderProducts/OrderProducts";
import { HotKeys } from "../../../components/System/HotKeys/HotKeys";

import {
  authRedirect,
  indexDetails,
  roleLimitedView,
  formatDate,
  catchResponse,
} from "../../../shared/utility";

import { HighlightOff, GetApp, OpenInNew, PictureAsPdf, Cancel, WrapText } from "@material-ui/icons";
import Spinner from "../../../components/UI/Spinner/Spinner";

import { defaultPageSize } from "../../../shared/defines";

import { json2excel } from "js2excel";
import axios from "../../../axios-sw";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

class Orders extends Component {
  confirmDialog = (msg) => {
    return new Promise(function (resolve, reject) {
      let confirmed = window.confirm(msg);
      return confirmed ? resolve(true) : reject(false);
    });
  };

  state = {
    actions: [
      {
        icon: PictureAsPdf,
        tooltip: "Show order pdf preview",
        onClick: (event, rowData) => {
          this.props.history.push("/orders/" + rowData.id + "/pdf-preview");
        },
      },
    ],
    columns: Columns.vm.orders,
    data: [],
    ports: [],
    loaded: false,
  };

  componentDidMount() {
    this.indexOrders();
    indexDetails("seasons", 5, "code", this.props.token, this.state.columns, (newState) => this.setState({ newState }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexOrders();
    }
  }

  indexOrders = () => {
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    const startTime = Date.now();
    axios
      .get(
        "/orders.json",
        { params: { currentSeasons: currentSeasons } },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
        this.setState({ data: res.data, loaded: true });
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  render() {
    let tableRef = React.createRef();

    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        {authRedirect(this.props.token)}
        {roleLimitedView("vm", this.props.role, this.props.user_id)}
        <ToastContainer />
        <HotKeys />

        <MaterialTable
          tableRef={tableRef}
          title={titleTemplate("VM Orders")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          columns={this.state.columns}
          data={this.state.data}
          style={{ width: "100%" }}
          actions={this.state.actions}
          options={{
            pageSize: defaultPageSize,
            filtering: true,
          }}
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <OrderProducts
                  orderNumber={rowData.number}
                  orderId={rowData.id}
                  user_id={this.props.user_id}
                  ip_address={this.props.ip_address}
                  token={this.props.token}
                />
              </div>
            );
          }}
          components={{
            FilterRow: (props) => {
              return <MTableFilterRow className="filterRow" {...props} />;
            },
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Toolbar: (props) => {
              let totalQuantity = 0,
                totalValue = 0,
                finalOrders = 0;
              for (let i = 0; i < props.data.length; i++) {
                totalQuantity += parseInt(props.data[i].total_quantity.replace(/\s/g, ""));
                totalValue += parseFloat(props.data[i].total_value.replace(/\s/g, ""));
                if (props.data[i].final_order) {
                  finalOrders++;
                }
              }
              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24 }}
                    avatar={<HighlightOff style={{ padding: 5 }} />}
                    label="Clear filters"
                    onClick={() => {
                      const table = tableRef.current;
                      for (let i = 0; i < table.state.columns.length; i++) {
                        table.onFilterChange(i, null);
                        table.state.columns[i].tableData.filterValue = null;
                      }
                    }}
                  />

                  {/* Eksportowanie danych z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24 }}
                    avatar={<GetApp style={{ padding: 5 }} />}
                    label="Export data to Excel"
                    onClick={() => {
                      const data = tableRef.current.state.data;
                      const columns = tableRef.current.state.columns;

                      data.forEach((row) => {
                        const columnsToReplace = ["payment_term_id", "port_id", "season_id"];
                        for (let i = 0; i < columns.length; i++) {
                          for (let j = 0; j < columnsToReplace.length; j++) {
                            // Podmiana ID na wybrane frazy ze zdefiniowanej listy
                            if (columns[i].field === columnsToReplace[j]) {
                              for (let key in columns[i].lookup) {
                                if (parseInt(key) === parseInt(row[columnsToReplace[j]])) {
                                  row[columnsToReplace[j]] = columns[i].lookup[key];
                                }
                              }
                            }
                          }
                        }
                        const columnsToConvert = ["total_quantity", "total_value"];
                        for (let i = 0; i < columns.length; i++) {
                          for (let j = 0; j < columnsToConvert.length; j++) {
                            if (columns[i].field === columnsToConvert[j] && row[columnsToConvert[j]] !== null) {
                              row[columnsToConvert[j]] = parseFloat(
                                row[columnsToConvert[j]].replace(/\s/g, "").replace(",", ".")
                              );
                            }
                          }
                        }

                        row.shipment_date = row.shipment_date ? new Date(row.shipment_date) : null;
                        row.accepted = row.accepted ? new Date(row.accepted) : null;

                        delete row["payment_term_id"];
                        delete row["lc_number"];
                        delete row["total_value"];
                        delete row["barcodes_in_process"];
                        delete row["proforma_document"];
                        delete row["styles"];
                        delete row["tableData"];
                      });

                      json2excel({
                        data,
                        name: "vm_orders_" + formatDate(Date.now()),
                        formateDate: "dd/mm/yyyy",
                      });
                    }}
                  />

                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Final orders</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(finalOrders)}/{props.data.length}
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(totalQuantity)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              );
            },
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Orders);
