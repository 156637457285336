import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../../axios-sw";
import { Redirect, withRouter } from "react-router-dom";

import { tableIcons, titleTemplate } from "../../../shared/tableVariables";
import MaterialTable, { MTableBodyRow, MTableEditRow } from "material-table";
import Columns from "../../../shared/tables";
import { catchResponse } from "../../../shared/utility";

import { Edit, OpenInNew, PictureAsPdf } from "@material-ui/icons";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { Paper } from "@material-ui/core";

const alert = {
  padding: 20,
  textAlign: "center",
};

const DesignerOrdersTable = (props) => {
  const [designerOrdersData, setDesignerOrdersData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [redirection, setRedirection] = useState(null);
  const [columns] = useState(Columns.designerFormTables.designerOrders);
  const currentSeasons = useSelector(({ systemInfo }) => systemInfo.currentSeasons);
  const [actions] = useState([
    {
      icon: OpenInNew,
      tooltip: "Order details page",
      onClick: (event, rowData) => {
        props.history.push("/orders/" + rowData.id);
      },
    },
    {
      icon: PictureAsPdf,
      tooltip: "Show order pdf preview",
      onClick: (event, rowData) => {
        props.history.push("/orders/" + rowData.id + "/pdf-preview");
      },
    },
    (rowData) => {
      let editable = null;
      if (!rowData.barcode_generated && !rowData.accepted) {
        editable = {
          icon: Edit,
          tooltip: "Edit order",
          onClick: (event, rowData) => {
            setRedirection(
              <Redirect
                to={{
                  pathname: "/order-form/" + props.user_id,
                  hash: "#edit-order=" + rowData.id,
                  state: {
                    order: rowData.id,
                    source: window.location.pathname,
                  },
                }}
              />
            );
          },
        };
      }
      return editable;
    },
  ]);

  useEffect(() => {
    showDesignerOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeasons]);

  const showDesignerOrders = () => {
    const seasons = currentSeasons.map((season) => season.value);
    setDesignerOrdersData([]);
    setLoaded(false);
    axios
      .get(
        "/orders/designer/" + props.user_id + ".json",
        { params: { currentSeasons: seasons } },
        { headers: { Authorization: `Bearer ${props.token}` } }
      )
      .then((res) => {
        setDesignerOrdersData(res.data);
        setLoaded(true);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  let content = <Spinner />;
  if (loaded) {
    if (designerOrdersData.length > 0) {
      content = (
        <>
          {redirection}
          <MaterialTable
            title={titleTemplate("Your orders")}
            icons={tableIcons}
            style={{ width: "100%" }}
            columns={columns}
            data={designerOrdersData}
            actions={actions}
            localization={{
              editRow: {
                cancelTooltip: "Back",
              },
            }}
            options={{
              exportButton: false,
              search: false,
              paging: true,
              pageSize: 10,
              filtering: true,
            }}
            components={{
              Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
              EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            }}
          />
        </>
      );
    } else {
      content = (
        <Paper style={alert}>
          <p>Add your first order by using "Create new order" button</p>
        </Paper>
      );
    }
  }

  return content;
};

export default withRouter(DesignerOrdersTable);
