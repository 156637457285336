import React, { Component } from "react";
import { connect } from "react-redux";
import { json2excel } from "js2excel";

import axios from "../../axios-sw";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, { MTableBody, MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";

import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";
import Columns from "../../shared/tables";

import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Check, Close, HighlightOff, GetApp, OpenInNew } from "@material-ui/icons";

import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import {
  authRedirect,
  catchResponse,
  thenResponse,
  nonAdminRedirect,
  formatDate,
  getAndConvertULRParams,
  getSortPath,
  getFilterPath,
  createSortPath,
  createSingleColumnFilterVal,
  sendEmailAboutLoadingDelay,
} from "../../shared/utility";
import classes from "../Order/OrderForm/OrderForm.module.scss";
import { defaultPageSize } from "../../shared/defines";

import Spinner from "../../components/UI/Spinner/Spinner";
import moment from "moment";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

const formatterCurrency = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const sortingPhraseHelper = [
  { key: "style_name", path: "products.style_name" },
  { key: "index", path: "products.index" },
  { key: "store_price", path: "products.store_price" },
  { key: "product_details", path: "products.product_details" },
  { key: "comments", path: "products.comments" },
  { key: "contact_name", path: "contacts.name" },
  { key: "company", path: "contacts.company" },
  { key: "designer", path: "designers.code" },
  { key: "season", path: "seasons.code" },
  { key: "port", path: "ports.code" },
  { key: "shipment_date", path: "orders.shipment_date" },
  { key: "payment_ref", path: "orders.lc_number" },
  { key: "order_number", path: "orders.number" },
  { key: "accepted", path: "orders.accepted" },
  { key: "payment_term", path: "payment_terms.code" },
  { key: "barcode_check", path: "products.barcode_check" },
  { key: "accessory", path: "descriptions.accessory" },
  { key: "m", path: "products.m" },
  {
    key: "quantity",
    path:
      "(SELECT SUM(`combinations`.`quantity`) FROM `combinations` WHERE `combinations`.`product_id` = products.id )",
  },
  {
    key: "total",
    path:
      "(SELECT SUM(`combinations`.`quantity` * `combinations`.`price`) FROM `combinations` WHERE `combinations`.`product_id` = products.id )",
  },
  {
    key: "price",
    path: "(SELECT AVG(`combinations`.`price`) FROM `combinations` WHERE `combinations`.`product_id` = products.id)",
  },
  { key: "tt_amount", path: "products.style_name" },
  { key: "discount", path: "products.discount" },
  { key: "description", path: "descriptions.title" },
  { key: "sex", path: "sexes.code" },
  { key: "fabric_title", path: "fabrics.title" },
  { key: "barcode_multi", path: "multipacks.barcode_multi" },
];

class CancelledProducts extends Component {
  state = {
    columns: Columns.productsColumns,
    columnsHidden: Columns.productsColumns,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalQuantity: 0,
    quantityPercentage: 0,
    totalValue: 0,
    valuePercentage: 0,
    selection: false,
    hidden: false,
    sortBy: null,
    order: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    firstLoad: true,
    tableRef: React.createRef(),
    exportChosen: false,
    ports: [],
    actions: [
      () => ({
        icon: OpenInNew,
        tooltip: "Product order details",
        onClick: (event, rowData) => {
          this.props.history.push("/orders/" + rowData.order_id);
        },
      }),
    ],
    editable: {},
    blockedSaveColumnsButton: true,
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );
    this.indexProducts(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    this.indexPorts();
    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value
          .replace("LIKE", "")
          .replace("'%", "")
          .replace("%'", "")
          .trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "all");
        if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "empty");
        if (
          filter.column.key === "accepted" &&
          filter.value ===
            `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path} NOT LIKE "%cancel%" AND ${filter.column.path} NOT LIKE "%hold%"`
        )
          table.onFilterChange(index, "accepted");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) table.onFilterChange(index, "hold");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`)
          table.onFilterChange(index, "cancel");
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => item.trim());
        table.onFilterChange(index, valueArray);
      }
    });
    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexProducts(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
  }

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_cancelled_products_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  handleSerach = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/cancelled-products?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexProducts(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
  };

  indexProducts = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    const startTime = Date.now();

    axios
      .get(
        `/cancelled-products/${page}/${limit}.json`,
        {
          params: {
            phrase: phrase,
            sortPath: sortPath,
            filterPath: filterPath,
            currentSeasons: currentSeasons,
          },
        },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");
        const columnSettings = JSON.parse(window.localStorage.getItem(`user_canceledProducts_${this.props.user_id}`));
        let columnsHidden = [];

        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        this.setState({
          data: res.data.products,
          totalRow: res.data.total_row,
          totalQuantity: res.data.total_quantity,
          totalValue: parseFloat(res.data.total_value),
          quantityPercentage: res.data.total_quantity_percentage,
          valuePercentage: res.data.total_value_percentage,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.products,
          columnsHidden: columnsHidden,
          loaded: true,
        });

        if (responseTime > 10) {
          let ids = "";
          res.data.products.forEach((product) => (ids += `${product.id}, `));
          sendEmailAboutLoadingDelay(
            "Products",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };

  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["code"], res.data[i]["code"]);
        }
        let obj = Object.fromEntries(map);
        let newState = { ...this.state.columns };
        let newStateHidden = { ...this.state.columnsHidden };
        const portIndex = this.state.columns.findIndex((column) => column.field === "port");
        newState[portIndex].lookup = obj;
        newStateHidden[portIndex].lookup = obj;
        this.setState({ columns: Object.values(newState), columnsHidden: Object.values(newStateHidden) });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateProduct = (id, newData) => {
    const productData = {
      product: {
        index: newData.index == null ? null : newData.index.toUpperCase(),
        barcode_check: newData.barcode_check, // boolean
        shipment_date: newData.shipment_date,
        discount: newData.discount == null ? null : newData.discount.toUpperCase(),
        m: newData.m, // boolean
        comments: newData.comments == null ? null : newData.comments.toUpperCase(),
        canceled: newData.canceled, // boolean
        // Tylko pod update i ustawiania description
        style_name: newData.style_name,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    const url = "/products/" + id + ".json";
    axios
      .put(url, productData, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedProduct = res.data;
            const array = [...this.state.data];
            const index = array.findIndex((x) => x.id === fetchedProduct.id);
            if (index !== -1) {
              array[index].index = fetchedProduct.index;
              array[index].barcode_check = fetchedProduct.barcode_check;
              array[index].shipment_date = fetchedProduct.shipment_date;
              array[index].discount = fetchedProduct.discount;
              array[index].m = fetchedProduct.m;
              array[index].comments = fetchedProduct.comments;
              array[index].canceled = fetchedProduct.canceled;
              this.setState({ data: array });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  destroyProduct = (id) => {
    const data = {
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .delete("/products/" + id, {
        data: data,
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.indexProducts();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateProduct(oldData.id, newData);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              this.destroyProduct(oldData.id);
            }),
        },
      });
    }
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    const table = (
      <MaterialTable
        tableRef={this.state.tableRef}
        title={titleTemplate("Cancelled products")}
        localization={{
          body: {
            emptyDataSourceMessage: emptyDataMessage,
            editRow: {
              cancelTooltip: "Back",
            },
          },
        }}
        icons={tableIcons}
        style={{ width: "100%" }}
        columns={this.state.hidden ? this.state.columns : this.state.columnsHidden}
        data={this.state.data}
        actions={this.state.actions}
        options={{
          pageSize: defaultPageSize,
          exportButton: true,
          exportFileName: "cancelled_products_" + formatDate(Date.now()),
          filtering: true,
          actions: true,
          search: false,
          selection: this.state.selection,
        }}
        components={{
          Body: (props) => {
            return (
              <MTableBody
                {...props}
                onFilterChanged={(columnId, value) => {
                  const columnName = this.state.columnsHidden[columnId].field;
                  let singleColumnFilterList = this.state.singleColumnFilterList;
                  let indexIsExist = this.state.singleColumnFilterList.findIndex(
                    (filter) => filter.column.key === columnName
                  );
                  const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                  const table = this.state.tableRef.current;

                  if (this.state.columnsHidden[columnId].type === "dateType") {
                    if (indexIsExist !== -1) {
                      if (value.isEmpty) {
                        singleColumnFilterList[indexIsExist].value = "IS NULL";
                      } else if (value.isFull) {
                        singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                      } else {
                        const start = moment(value.start).format("YYYY-MM-DD");
                        const end = moment(value.end).format("YYYY-MM-DD");
                        singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                      }
                    } else {
                      if (value.isEmpty) {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "IS NULL",
                            type: "dateType",
                          },
                        ];
                      } else if (value.isFull) {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "IS NOT NULL",
                            type: "dateType",
                          },
                        ];
                      } else {
                        const start = moment(value.start).format("YYYY-MM-DD");
                        const end = moment(value.end).format("YYYY-MM-DD");
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `BETWEEN "${start}" AND "${end}"`,
                            type: "dateType",
                          },
                        ];
                      }
                    }
                  } else if (this.state.columnsHidden[columnId].type === "text") {
                    if (indexIsExist !== -1) {
                      singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                      table.state.columns[columnId].tableData.initialValue = value;
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: `LIKE '%${value}%'`,
                          type: "text",
                        },
                      ];
                      table.state.columns[columnId].tableData.initialValue = value;
                    }
                  } else if (this.state.columnsHidden[columnId].type === "boolean") {
                    if (indexIsExist !== -1) {
                      if (value === "checked") {
                        singleColumnFilterList.splice(indexIsExist, 1);
                        table.onFilterChange(columnId, null);
                      } else if (value === "unchecked") {
                        singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                        table.onFilterChange(columnId, undefined);
                      } else if (!value) {
                        singleColumnFilterList[indexIsExist].value = "=true";
                        table.onFilterChange(columnId, "checked");
                      }
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: "=true",
                          type: "boolean",
                        },
                      ];
                      table.onFilterChange(columnId, "checked");
                    }
                  } else if (this.state.columnsHidden[columnId].type === "number") {
                    const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                    if (indexIsExist !== -1) {
                      singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                      table.state.columns[columnId].tableData.initialValue = convertValue;
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: `= ${convertValue}`,
                          type: "number",
                        },
                      ];
                      table.state.columns[columnId].tableData.initialValue = convertValue;
                    }
                  } else if (this.state.columnsHidden[columnId].type === "select") {
                    if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                    else if (value === "empty") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                    else if (value === "accepted")
                      value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} NOT LIKE "%cancel%" AND ${sqlColumn[0].path} NOT LIKE "%hold%"`;
                    else value = `LIKE "%${value}%"`;

                    if (indexIsExist !== -1) {
                      singleColumnFilterList[indexIsExist].value = value;
                      table.state.columns[columnId].tableData.initialValue = value;
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: value,
                          type: "select",
                        },
                      ];
                      table.state.columns[columnId].tableData.initialValue = value;
                    }
                  } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                    if (indexIsExist !== -1) {
                      let multiFiltersValues = "";
                      value.forEach((filter, index) => {
                        multiFiltersValues +=
                          index === 0
                            ? `LIKE '%${value[index]}%'`
                            : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                      });
                      singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                      table.onFilterChange(columnId, value);
                    } else {
                      singleColumnFilterList = [
                        ...singleColumnFilterList,
                        {
                          column: sqlColumn[0],
                          value: `LIKE '%${value}%'`,
                          type: "multiSelect",
                        },
                      ];
                      table.onFilterChange(columnId, value);
                    }
                  }
                  this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                  this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                }}
              />
            );
          },
          Pagination: (props) => {
            return (
              <TablePagination
                {...props}
                rowsPerPage={this.state.limit}
                count={this.state.totalRow}
                page={this.state.page}
                onChangePage={(e, page) => {
                  this.state.tableRef.current.onChangePage(e, page);
                  this.setState({ page: page });
                  this.goPage(
                    page,
                    this.state.limit,
                    this.state.filterPhrase,
                    this.state.sortingColumnsList,
                    this.state.singleColumnFilterList
                  );
                }}
                onChangeRowsPerPage={(event) => {
                  props.onChangeRowsPerPage(event);
                  this.setState({ limit: event.target.value });
                  this.goPage(
                    this.state.page,
                    event.target.value,
                    this.state.filterPhrase,
                    this.state.sortingColumnsList,
                    this.state.singleColumnFilterList
                  );
                }}
              />
            );
          },
          Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
          EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
          Toolbar: (props) => {
            const table = this.state.tableRef.current;
            return (
              <div className="mainToolbar">
                <MTableToolbar {...props} />

                {/* Usuwanie filtra z tabeli */}
                <Chip
                  variant="outlined"
                  style={{ marginLeft: 24, marginTop: 5 }}
                  avatar={<HighlightOff />}
                  label="Clear filters"
                  onClick={() => {
                    this.goPage();
                    this.props.history.push(`/cancelled-products?page=${1}&limit=${20}`);

                    table.state.columns.forEach((column, index) => {
                      if (column.field === "accepted") {
                        table.onFilterChange(index, "all");
                        table.state.columns[index].tableData.filterValue = "all";
                      } else {
                        table.onFilterChange(index, null);
                        table.state.columns[index].tableData.filterValue = null;
                        column.tableData.initialValue = "";
                      }
                    });
                  }}
                />

                {/* Eksportowanie danych z tabeli */}
                <div className={classes.exportToExcelWrapper}>
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 10 }}
                    avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                    label="Export data to Excel"
                    onClick={() => {
                      const startTime = Date.now();
                      this.setState({ loaded: false, filteredData: [] });
                      const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                      const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                        this.props.location.search,
                        sortingPhraseHelper
                      );
                      const sortPath = getSortPath(sortingColumnsList);
                      const filterPath = getFilterPath(singleColumnFilterList);

                      axios
                        .get(
                          `/export-to-excel.json`,
                          {
                            params: {
                              phrase: search,
                              sortPath: sortPath,
                              filterPath: filterPath,
                              currentSeasons: currentSeasons,
                              cancelled: true,
                            },
                          },
                          { headers: { Authorization: `Bearer ${this.props.token}` } }
                        )
                        .then((res) => {
                          console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                          const productsData = res.data.products;
                          let filteredProductsData = productsData.map((product) => {
                            return {
                              id: product.id,
                              accepted:
                                !product.accepted || product.accepted.trim() === ""
                                  ? null
                                  : product.accepted.includes("CANCEL")
                                  ? product.accepted
                                  : product.accepted.includes("HOLD")
                                  ? product.accepted
                                  : new Date(product.accepted),
                              contact_name: product.contact_name,
                              company: product.company,
                              designer: product.designer,
                              season: product.season,
                              order_number: product.order_number,
                              style_name: product.style_name,
                              index: product.index,
                              store_price: product.store_price,
                              barcode_check: product.barcode_check,
                              port: product.port,
                              quantity: product.quantity
                                ? parseFloat(product.quantity.replace(/\s/g, "").replace(",", "."))
                                : 0,
                              price: product.price ? parseFloat(product.price.replace(/\s/g, "").replace(",", ".")) : 0,
                              total: product.total ? parseFloat(product.total.replace(/\s/g, "").replace(",", ".")) : 0,
                              shipment_date: product.shipment_date ? new Date(product.shipment_date) : null,
                              payment_term: product.payment_term,
                              tt_amount: product.tt_amount,
                              discount: product.discount,
                              m: product.m,
                              accessory: product.accessory,
                              sex: product.sex,
                              description: product.description,
                              fabric_title: product.fabric_title,
                              payment_ref: product.payment_ref,
                              comments: product.comments,
                            };
                          });

                          if (this.state.exportChosen) {
                            const exportColumns = this.state.columnsHidden.map((column) => {
                              return column.field;
                            });
                            filteredProductsData = filteredProductsData.map((row) => {
                              const toDelete = exportColumns;
                              const filtered = Object.keys(row)
                                .filter((key) => toDelete.includes(key))
                                .reduce((obj, key) => {
                                  obj[key] = row[key];
                                  return obj;
                                }, {});

                              return filtered;
                            });
                          }

                          json2excel({
                            data: filteredProductsData,
                            name: "cancelled_products_" + formatDate(Date.now()),
                            formateDate: "dd/mm/yyyy",
                          });

                          this.setState({ loaded: true, filteredData: this.state.data });
                        })
                        .catch((err) => {
                          catchResponse(err);
                        });
                    }}
                  />
                  <div className={classes.checkboxWrapper}>
                    <Checkbox
                      checked={this.state.exportChosen}
                      size={"small"}
                      onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                      color="primary"
                    />
                    <span>Export only chosen columns</span>
                  </div>
                </div>

                {/*Generowanie tagu szukania*/}
                {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                  <Chip
                    style={{ marginLeft: 24, marginTop: 10 }}
                    onDelete={() => {
                      this.goPage(
                        0,
                        this.state.limit,
                        "",
                        this.state.sortingColumnsList,
                        this.state.singleColumnFilterList
                      );
                      this.setState({ filterPhrase: "" });
                    }}
                    label={`search: ${this.state.filterPhrase}`}
                    color="primary"
                  />
                )}

                {/*Generowanie tagów filtrów kolumn*/}
                {this.state.singleColumnFilterList.map((filter) => {
                  let value;
                  let key;
                  let icon = "";
                  if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                  else if (filter.type === "text")
                    value = filter.value
                      .replace("LIKE", "")
                      .replace("'%", "")
                      .replace("%'", "");
                  else if (filter.type === "select")
                    value = filter.value
                      .replace("LIKE", "")
                      .replace("'%", "")
                      .replace("%'", "");
                  else if (filter.type === "multiSelect")
                    value = filter.value
                      .split("LIKE")
                      .join("")
                      .split("'%")
                      .join("")
                      .split("%'")
                      .join("")
                      .split(`OR ${filter.column.path}`)
                      .join(",");
                  else if (filter.type === "number") value = filter.value.replace("=", "");
                  else if (filter.type === "boolean") {
                    if (filter.value.includes("true")) {
                      value = "checked";
                      icon = <Check />;
                    } else {
                      value = "unchecked";
                      icon = <Close />;
                    }
                  }

                  /*WYJĄTKI*/
                  if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
                    value = "ALL";
                  if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                    value = "EMPTY";
                  if (
                    filter.column.key === "accepted" &&
                    filter.value ===
                      `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path} NOT LIKE "%cancel%" AND ${filter.column.path} NOT LIKE "%hold%"`
                  )
                    value = "ACCEPTED";
                  if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) value = "HOLD";
                  if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`) value = "CANCEL";
                  filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);

                  return (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                          (item) => item.column.key !== filter.column.key
                        );
                        this.goPage(
                          0,
                          this.state.limit,
                          this.state.filterPhrase,
                          this.state.sortingColumnsList,
                          singleColumnFilterList
                        );
                        this.setState({ singleColumnFilterList: singleColumnFilterList });
                        const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                        if (index !== -1) {
                          if (table.state.columns[index].field === "accepted") {
                            table.onFilterChange(index, "all");
                          } else {
                            table.state.columns[index].tableData.initialValue = "";
                            table.onFilterChange(index, null);
                          }
                        }
                      }}
                      label={`${key.replace("_", " ").toUpperCase()}:  ${value.toUpperCase()}`}
                      icon={icon}
                      variant="outlined"
                    />
                  );
                })}

                {/*Generowanie tagów sortowania*/}
                {this.state.sortingColumnsList.map((column) => {
                  let key;
                  let icon = "";
                  if (column.order === "ASC") icon = <ArrowDownward />;
                  if (column.order === "DESC") icon = <ArrowUpward />;

                  /*WYJĄTKI*/
                  column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                  return (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        const sortingColumnsList = this.state.sortingColumnsList.filter(
                          (item) => item.sortBy.key !== column.sortBy.key
                        );
                        this.goPage(
                          0,
                          this.state.limit,
                          this.state.filterPhrase,
                          sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ sortingColumnsList: sortingColumnsList });
                      }}
                      label={`${key.toUpperCase().replace("_", " ")}`}
                      icon={icon}
                    />
                  );
                })}

                <div className={classes.CustomToolbar}>
                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      onOpen={() =>
                        !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                      }
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "products",
                          };
                        })}
                      options={Columns.productsColumns
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "products",
                          };
                        })}
                      onChangeColumns={async (columns) => {
                        await this.setState({ columnsHidden: [], currentStateColumns: null });
                        await this.setSelectedColumns(columns);
                        this.setState({ blockedSaveColumnsButton: false });
                        this.setSortingForColumns();
                      }}
                    />
                    <Button
                      disabled={this.state.blockedSaveColumnsButton}
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_canceledProducts_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                          this.setState({ blockedSaveColumnsButton: true });
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_canceledProducts_${this.props.user_id}`);
                          await this.setState({
                            columnsHidden: this.state.columns,
                            currentStateColumns: null,
                          });
                          toast.success("Column settings have been removed!");
                          this.setSortingForColumns();
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>
                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                  </div>
                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Value</TableCell>
                        <TableCell align="right">Quantity Percentange</TableCell>
                        <TableCell align="right">Value Percentange</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "20%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(this.state.totalQuantity)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterCurrency.format(this.state.totalValue)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "15%" }}>
                          {`${this.state.quantityPercentage}`.replace(".", ",")} %
                        </TableCell>
                        <TableCell align="right" style={{ width: "15%" }}>
                          {`${this.state.valuePercentage}`.replace(".", ",")} %
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            );
          },
        }}
        onSelectionChange={(rows) => {
          this.sumTotalProducts(rows);
        }}
        editable={this.state.editable}
      />
    );

    return (
      <div>
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />
        <HotKeys />

        {table}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(CancelledProducts);
