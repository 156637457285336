import React, {Component} from 'react';
import axios from "../../../axios-sw";

import Paper from '@material-ui/core/Paper';
import {tableIcons, titleTemplate} from "../../../shared/tableVariables";
import { role } from "../../../config";

import MaterialTable, {MTableBodyRow, MTableEditRow, MTableToolbar} from "material-table";
import Columns from "../../../shared/tables";
import {HotKeysSubtable} from "../../../components/System/HotKeys/HotKeys";

import {catchResponse, postFile, thenResponse} from '../../../shared/utility';

const sortingPhraseHelper = [
    {key: "order_number", path: "orders.number"},
    {key: "style_name", path: "products.style_name"},
    {key: "index", path: "products.index"},
    {key: "quantity_per_each_style_per_one_carton", path: "products.quantity_per_each_style_per_one_carton"}
]

class MultipackProducts extends Component {

    state = {
        columns: Columns.multipackProductsColumns,
        editable: {},
        actions: [],
        data: []
    };

    componentDidMount() {
        this.indexMultipackProducts();
        this.checkActionsAndEditablePermissions();
    }

    indexMultipackProducts = () => {
        let index;
        let filterByColumnSql;
        if (this.props.filterByColumn) {
            index = this.state.columns.findIndex(column => column.field === this.props.filterByColumn.value);
            if (index !== -1) filterByColumnSql = sortingPhraseHelper.find(helper => helper.key === this.props.filterByColumn.value).path
        }

        axios.get('/multipack-products/' + this.props.multipackId + '.json', {
            params: {
                filterByColumn: this.props.filterByColumn && index !== -1 ? filterByColumnSql : null,
                filterByConditional: this.props.filterByConditional && index !== -1 ? this.props.filterByConditional.value : null
            }
        }, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.setState({data: res.data});
                if (this.props.filteredPhrase && this.props.filteredPhrase.trim().length > 0) this.props.colorFilteredPhrase(this.props.filteredPhrase);
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    updateMultipackProduct = (id, newData) => {
        const multipackProductsData = {
            product_multipack: {
                multipack_id: this.props.multipackId,
                quantity_per_each_style_per_one_carton: !newData.quantity_per_each_style_per_one_carton ? null : newData.quantity_per_each_style_per_one_carton
            },
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address
        };

        const urlPut = `/update-product-multipack/${id}.json`;
        const urlGet = `/show-multipack-product/${id}.json`;
        const urlGetMultipack = `/multipacks/${this.props.multipackId}.json`;

        axios.put(urlPut, multipackProductsData, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                axios.get(urlGet, {params: {multipack_id:this.props.multipackId }},{headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(res => {
                        const fetchedMultipack = res.data;
                        const array = [...this.state.data];
                        const index = array.findIndex(x => x.id === fetchedMultipack.id);
                        if (index !== -1) {
                            array[index].barcode_multi = fetchedMultipack.barcode_multi;
                            array[index].style_name = fetchedMultipack.style_name;
                            array[index].order_number = fetchedMultipack.order_number;
                            array[index].index = fetchedMultipack.index;
                            array[index].quantity_per_each_style_per_one_carton = fetchedMultipack.quantity_per_each_style_per_one_carton;
                            array[index].quantity_of_each_style_in_all_cartons = fetchedMultipack.quantity_of_each_style_in_all_cartons;
                            this.setState({data: array});
                        }
                    })
                thenResponse(res)
            })
            .then(res => {
                axios.get(urlGetMultipack, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(res => {
                        this.props.setMultipack(res.data)
                    })
                thenResponse(res)
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    checkActionsAndEditablePermissions = () => {
        if(this.props.role === role.MANAGEMENT) this.setState({
            editable: {},
            actions: []
        })
        else {
            this.setState({
                editable: {
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            resolve();
                            this.updateMultipackProduct(oldData.id, newData)
                        }),
                },
                actions : []
            })
        }
    }

    render() {

        const title = (
            <div>
                Multipack products in container: {this.props.barcodeMulti}
            </div>
        );

        return (
            <Paper>
                <HotKeysSubtable/>
                <MaterialTable
                    title={titleTemplate(title)}
                    icons={tableIcons}
                    style={{width: '100%'}}
                    columns={this.state.columns}
                    data={this.state.data}
                    localization={{
                        editRow: {
                            cancelTooltip: 'Back'
                        }
                    }}
                    options={{
                        pageSize: 5,
                        exportButton: false,
                        filtering: false,
                        search: false,
                        paging: false
                    }}
                    components={{
                        EditRow: props => (
                            <MTableEditRow autoFocus className='subtableEditingRow uppercase ' {...props} />
                        ),
                        Row: props => (
                            <MTableBodyRow className={props.data.styles} {...props} />
                        ),
                        Toolbar: props => {
                            return (
                                <div className='subtableToolbar'>
                                    <MTableToolbar {...props} />
                                </div>
                            )
                        }
                    }}
                    editable={this.state.editable}
                />
            </Paper>
        );
    }
}

export default MultipackProducts;