import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Grid, LinearProgress, Paper } from "@material-ui/core";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";

// Custom components
import axios from "../../../axios-sw";
import { authRedirect, catchResponse } from "../../../shared/utility";
import classes from "../SyncProductsFormDesc/SyncProductsFormDesc.module.scss";
import { ArrowBackIos } from "@material-ui/icons";

class SyncProductsFormCSV extends Component {
    state = {
        loading: false,
    };

    syncCombinationsData = (data) => {
        this.setState({ loading: true });

        axios
            .post(
                "/sync-combinations/mass-update.json",
                { data: data },
                { headers: { Authorization: `Bearer ${this.props.token}` } }
            )
            .then((res) => {
                res.data.synced.length > 0 &&
                    toast.success(`Successfully synced combination HZ IDs: ${res.data.synced.toString()}`);
                res.data.not_added_to_sync.length > 0 &&
                    toast.warn(
                        `List of combination HZ IDs not added to sync table: ${res.data.not_added_to_sync.toString()}`
                    );
                res.data.not_hz_id.length > 0 &&
                    toast.error(`List of combination HZ IDs that do not exist: ${res.data.not_hz_id.toString()}`);
                this.setState({ loading: false });
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    render() {
        return (
            <>
                <ToastContainer />
                {authRedirect(this.props.token)}

                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: "30vh" }}
                >
                    <Grid item>
                        <Paper style={{ width: 900 }}>
                            {this.state.loading && (
                                <LinearProgress
                                    style={{
                                        marginBottom: "-4px",
                                        zIndex: 1,
                                        borderRadius: "4px 4px 0 0",
                                    }}
                                />
                            )}

                            <div className={classes.FormNavigation}>
                                <ArrowBackIos
                                    onClick={() => this.props.history.push(this.props.location.state.source)}
                                />
                            </div>

                            <div style={{ padding: 20 }}>
                                <Importer
                                    assumeNoHeaders={false}
                                    restartable
                                    processChunk={async (rows) => {
                                        this.syncCombinationsData(rows);
                                    }}
                                    delimiter=";"
                                >
                                    <ImporterField name="reference" label="Reference" />
                                    <ImporterField name="name" label="Name" />
                                    <ImporterField name="description" label="Description" />
                                    <ImporterField name="composition" label="Composition" />
                                </Importer>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        token: state.auth.token,
        full_name: state.auth.full_name,
        role: state.auth.role,
    };
};

export default connect(mapStateToProps)(withRouter(SyncProductsFormCSV));
