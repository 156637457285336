import React, {Component} from 'react';
import {connect} from "react-redux";
import axios from "../../../axios-sw";

import {authRedirect, catchResponse, formatDate, postFile, thenResponse} from "../../../shared/utility";
import {toast, ToastContainer} from "react-toastify";

import MaterialTable, {MTableEditRow, MTableFilterRow} from "material-table";
import {tableIcons, titleTemplate} from "../../../shared/tableVariables";
import Columns from "../../../shared/tables/designersTables/productsTable";

import Spinner from "../../../components/UI/Spinner/Spinner";
import {HotKeys} from "../../../components/System/HotKeys/HotKeys";

class DesignerProducts extends Component {

    state = {
        columns: Columns.designerProducts,
        data: [],
        loaded: false
    };

    componentDidMount() {
        this.indexDesignerProducts();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentSeasons !== prevProps.currentSeasons) {
            this.setState({data:[], filteredData: [], loaded: false})
            this.indexDesignerProducts();
        }
    }

    indexDesignerProducts = () => {
        const currentSeasons = this.props.currentSeasons.map(season => season.value);
        axios.get('/designers/' + this.props.match.params.id + '/products.json', {params: {currentSeasons: currentSeasons}}, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.setState({data: res.data, loaded: true})
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    updateProduct = (newData, oldData) => {
        postFile('/product-size-file/',
            'file',
            newData,
            this.props.user_id,
            this.props.ip_address,
            this.props.token,
            () => {
                this.indexDesignerProducts();
                toast.success('File is added !')
            });
    };

    render() {

        let emptyDataMessage;
        if (!this.state.loaded) {
            emptyDataMessage = <Spinner/>
        } else {
            emptyDataMessage = 'No data to display'
        }

        return (
            <div>
                {authRedirect(this.props.token)}
                <ToastContainer/>
                <HotKeys/>

                <MaterialTable
                    title={titleTemplate('Your Products')}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataMessage,
                            editRow: {
                                cancelTooltip: 'Back'
                            }
                        }
                    }}
                    icons={tableIcons}
                    columns={this.state.columns}
                    data={this.state.data}
                    actions={this.state.actions}
                    style={{width: '100%'}}
                    options={{
                        pageSize: 15,
                        exportButton: true,
                        exportFileName: 'designer_' + this.props.user_id + '_products_' + formatDate(Date.now()),
                        filtering: true
                    }}
                    components={{
                        FilterRow: props => (
                            <MTableFilterRow className='filterRow' {...props} />
                        ),
                        EditRow: props => (
                            <MTableEditRow
                                className='editingRow uppercase' {...props} />
                        )
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                resolve();
                                this.updateProduct(newData)
                            })
                    }}
                />

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        token: state.auth.token,
        role: state.auth.role,
        currentSeasons: state.systemInfo.currentSeasons
    }
};

export default connect(mapStateToProps)(DesignerProducts);