import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, { MTableBody, MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";

import Columns from "../../shared/tables";
import InvoiceProducts from "./InvoiceProducts/InvoiceProducts";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import {
  addWorkingDaysToDate,
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  formatDate,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  nonAdminRedirect,
  sendEmailAboutLoadingDelay,
  thenResponse,
} from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";
import { currencyDateDayAdd, defaultPageSize } from "../../shared/defines";
import {
  Button,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Check, Close, GetApp, HighlightOff, Loop } from "@material-ui/icons";
import { json2excel } from "js2excel";
import moment from "moment";
import classes from "../Bookings/Bookings.module.scss";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import $ from "jquery";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";
import FilterInput from "../../components/System/FilterInput/FilterInput";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

const formatterCurrency = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const convertWithDelimetes = (number) => {
  let convertValue = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if (convertValue.indexOf(".") === -1) convertValue += ",00";
  else convertValue = convertValue.replace(".", ",");

  return convertValue;
};

const sortingPhraseHelper = [
  { key: "container_number", path: "containers.number" },
  { key: "shipper", path: "container_invoices.shipper" },
  { key: "number", path: "invoices.number" },
  { key: "ctns", path: "container_invoices.ctns" },
  { key: "validated", path: "invoices.validated" },
  { key: "invoice_date", path: "invoices.invoice_date" },
  { key: "contact_person", path: "contacts.name" },
  { key: "invoice_shipper", path: "invoices.invoice_shipper" },
  {
    key: "invoice_quantity",
    path:
      "(SELECT SUM(`bookings`.`quantity_invoice`) FROM `bookings` WHERE `bookings`.`invoice_number` = invoices.number )",
  },
  {
    key: "invoice_value",
    path:
      "(SELECT SUM(`bookings`.`quantity_invoice` * `bookings`.`price_invoice`) FROM `bookings` WHERE `bookings`.`invoice_number` = invoices.number )",
  },
  { key: "amount_to_pay_dp", path: "invoices.amount_to_pay_dp" },
  { key: "amount_to_pay_lc", path: "invoices.amount_to_pay_lc" },
  { key: "amount_to_pay_tt", path: "invoices.amount_to_pay_tt" },
  { key: "payment_terms", path: "payment_terms.code" },
  { key: "payment_ref", path: "invoices.payment_ref" },
  { key: "eta", path: "containers.eta" },
  { key: "warehouse", path: "containers.warehouse" },
  { key: "has_bank", path: "invoices.has_bank" },
  { key: "payment_date", path: "invoices.payment_date" },
  { key: "dox_received", path: "invoices.dox_received" },
  { key: "currency_date", path: "invoices.currency_date" },
  { key: "season", path: "seasons.code" },
  { key: "comments", path: "invoices.comments" },
  { key: "currency_rate", path: "nbp_rates.rate" },
  {
    key: "convert_invoice_value",
    path:
      "(SELECT ROUND (SUM(nbp_rates.rate * (`bookings`.`quantity_invoice` * `bookings`.`price_invoice`)),2) FROM `bookings` WHERE `bookings`.`invoice_number` = invoices.number)",
  },
  { key: "bank_name", path: "COALESCE(lcs_bank.name, NULL, invoices_bank.name)", exception: "INVOICES" },
];

class Invoices extends Component {
  state = {
    columns: Columns.invoicesColumns,
    columnsHidden: Columns.invoicesColumns,
    optionsColumns: Columns.invoicesColumns,
    currentStateColumns: null,
    freeDays: [],
    data: [],
    filteredData: [],
    totalQuantity: 0,
    quantityPercentage: 0,
    totalValue: 0,
    valuePercentage: 0,
    totalRow: 0,
    filterPhrase: "",
    sortBy: null,
    order: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    firstLoad: true,
    exportChosen: false,
    productsInInvoices: [],
    parentChildData: "",
    loaded: false,
    tableRef: React.createRef(),
    filterByColumn: null,
    filterByConditional: null,
    editable: {},
    actions: [],
    blockedSaveColumnsButton: false,
    banks: [],
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );

    const localStorageLimit = localStorage.getItem(`${this.constructor.name}Limit`);
    if (localStorageLimit === null) {
      this.indexInvoices(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    } else {
      this.setState({ limit: parseInt(localStorageLimit) });
      this.indexInvoices(page - 1, localStorageLimit, search, sortingColumnsList, singleColumnFilterList);
    }

    await this.indexFreeDays();
    this.indexBanks();
    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value
          .replace("LIKE", "")
          .replace("'%", "")
          .replace("%'", "")
          .trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "all");
        if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "empty");
        if (filter.column.key === "proforma_document" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (
          filter.column.key === "accepted" &&
          filter.value ===
            `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path} NOT LIKE "%cancel%" AND ${filter.column.path} NOT LIKE "%hold%"`
        )
          table.onFilterChange(index, "accepted");
        if (
          filter.column.key === "proforma_document" &&
          filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
        )
          table.onFilterChange(index, "added");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) table.onFilterChange(index, "hold");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`)
          table.onFilterChange(index, "cancel");
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => {
          if (item.trim() === "IS NULL") return "";
          else return item.trim();
        });
        table.onFilterChange(index, valueArray);
      }
    });
    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexInvoices(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
    if (this.props.role !== prevProps.role) this.checkActionsAndEditablePermissions();
  }

  indexBanks = () => {
    axios
      .get("/banks.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        let localMap = new Map();
        for (let i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["name"], res.data[i]["name"]);
          localMap.set(res.data[i]["id"], res.data[i]["name"]);
        }
        let obj = Object.fromEntries(map);
        let localObj = Object.fromEntries(localMap);
        let newState = { ...this.state.columns };
        const portIndex = this.state.columns.findIndex((column) => column.field === "bank_name");
        const lastIndexObj = Object.keys(localObj).length;
        localObj = { ...localObj, [`${lastIndexObj + 1}`]: "" };
        obj = { ...obj, "": "EMPTY" };
        newState[portIndex].lookup = obj;
        this.setState({ columns: Object.values(newState), banks: localObj });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_invoices_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  handleSearch = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/invoices?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexInvoices(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
  };

  convertInvoicesValues = (data) => {
    const amountToPayDp = parseFloat(data.amount_to_pay_dp.replace(/\s/g, "").replace(",", "."));
    const amountToPayLc = parseFloat(data.amount_to_pay_lc.replace(/\s/g, "").replace(",", "."));
    const amountToPayTt = parseFloat(data.amount_to_pay_tt.replace(/\s/g, "").replace(",", "."));
    //const invoiceValue = parseFloat(data.invoice_value.replace(/\s/g, '').replace(',', '.'));
    const currencyDate = data.currency_date;
    //const arr = Array.from(data.payment_terms.replace(/\s/g,''));
    if (data.payment_terms) {
      if (amountToPayDp === 0 && data.payment_terms === "DP") {
        data = { ...data, amount_to_pay_dp: data.invoice_value };
      } else if (amountToPayLc === 0 && data.payment_terms === "LC") {
        data = { ...data, amount_to_pay_lc: data.invoice_value };
      } else if (amountToPayTt === 0 && data.payment_terms.replace(/\s/g, "").includes("100%TT")) {
        data = { ...data, amount_to_pay_tt: data.invoice_value };
      }
    }

    /* Zakomentowane w razie użycia w przyszłości
        if(amountToPayTt === 0 && data.payment_terms.replace(/\s/g,'').includes("%TT")) {
            let number = "";

             for(let i= data.payment_terms.replace(/\s/g,'').indexOf("%TT") ; (arr[i] !== "," && i >= 0) ; i--) {
                 if(Number.isInteger(parseInt(arr[i]))) number += arr[i];
             }
            number = parseInt(number.split("").reverse().join(""));
            const numberWithDelimeter = convertWithDelimetes((invoiceValue * number) / 100)

            data = {...data, amount_to_pay_tt: numberWithDelimeter}
        }

        if(amountToPayDp === 0 && data.payment_terms.replace(/\s/g,'').includes("%DP")) {
            let number = "";

            for(let i= data.payment_terms.replace(/\s/g,'').indexOf("%DP") ; (arr[i] !== "," && i >= 0) ; i--) {
                if(Number.isInteger(parseInt(arr[i]))) number += arr[i];
            }

            number = parseInt(number.split("").reverse().join(""));
            const numberWithDelimeter = convertWithDelimetes((invoiceValue * number) / 100)

            data = {...data, amount_to_pay_dp: numberWithDelimeter}
        }

        if(amountToPayDp === 0 && data.payment_terms.replace(/\s/g,'').includes("%LC")) {
            let number = "";

            for(let i= data.payment_terms.replace(/\s/g,'').indexOf("%LC") ; (arr[i] !== "," && i >= 0) ; i--) {
                if(Number.isInteger(parseInt(arr[i]))) number += arr[i];
            }

            number = parseInt(number.split("").reverse().join(""));
            const numberWithDelimeter = convertWithDelimetes((invoiceValue * number) / 100)

            data = {...data, amount_to_pay_lc: numberWithDelimeter}
        }
         */

    if ((!currencyDate || currencyDate.trim() === "") && data.payment_date && data.payment_date.trim() !== "") {
      if (
        data.payment_terms.replace(/\s/g, "").includes("100%TT") ||
        data.payment_terms.replace(/\s/g, "").includes("DP") ||
        data.payment_terms.replace(/\s/g, "").includes("90%DP") ||
        data.payment_terms.replace(/\s/g, "").includes("80%DP")
      ) {
        data = { ...data, currency_date: data.payment_date };
      } else if (data.payment_terms.replace(/\s/g, "").includes("LC")) {
        const newDate = addWorkingDaysToDate(data.payment_date, currencyDateDayAdd, this.state.freeDays);
        data = { ...data, currency_date: newDate };
      }
    }

    return data;
  };

  indexInvoices = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    const startTime = Date.now();
    axios
      .get(
        `/invoices/${page}/${limit}.json`,
        {
          params: {
            phrase: phrase,
            sortPath: sortPath,
            filterPath: filterPath,
            currentSeasons: currentSeasons,
          },
        },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then(async (res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");

        const columnSettings = JSON.parse(window.localStorage.getItem(`user_invoices_${this.props.user_id}`));
        let columnsHidden = [];
        let defaultColumns = this.state.optionsColumns;

        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        let invoices = res.data.invoices.map((data) => {
          const convertValuesData = this.convertInvoicesValues(data);
          return convertValuesData;
        });

        if (
          parseInt(this.props.user_id) === 40 ||
          parseInt(this.props.user_id) === 13 ||
          parseInt(this.props.user_id) === 14
        ) {
          columnsHidden = [
            ...columnsHidden,
            {
              title: "Currency Rates USD",
              field: "currency_rate",
              editable: "never",
              type: "number",
              filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
              },
              headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px",
              },
              cellStyle: {
                textAlign: "right",
              },
              filterCellStyle: {
                paddingRight: "0",
              },
            },
            {
              title: "Convert Invoice Value (PLN)",
              field: "convert_invoice_value",
              editable: "never",
              type: "number",
              filterComponent: (term) => {
                const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                return <FilterInput term={term} onChange={onChange} />;
              },
              headerStyle: {
                textAlign: "right",
                paddingRight: "0",
                paddingLeft: "50px",
              },
              cellStyle: {
                textAlign: "right",
              },
              filterCellStyle: {
                paddingRight: "0",
              },
            },
          ];
          if (this.state.firstLoad) {
            defaultColumns = [
              ...this.state.optionsColumns,
              {
                title: "Currency Rates USD",
                field: "currency_rate",
                editable: "never",
                type: "number",
                filterComponent: (term) => {
                  const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                  return <FilterInput term={term} onChange={onChange} />;
                },
              },
              {
                title: "Convert Invoice Value (PLN)",
                field: "convert_invoice_value",
                editable: "never",
                type: "number",
                filterComponent: (term) => {
                  const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
                  return <FilterInput term={term} onChange={onChange} />;
                },
              },
            ];
          } else {
            defaultColumns = this.state.optionsColumns;
          }
        }
        this.setState({
          filteredData: invoices,
          data: invoices,
          columnsHidden: columnsHidden,
          optionsColumns: defaultColumns,
          loaded: true,
          totalRow: res.data.total_row,
          totalQuantity: res.data.total_quantity,
          totalValue: parseFloat(res.data.total_value),
          quantityPercentage: res.data.total_quantity_percentage,
          valuePercentage: res.data.total_value_percentage,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          firstLoad: false,
        });
        if (responseTime > 10) {
          let ids = "";
          invoices.forEach((invoice) => (ids += `${invoice.id}, `));
          sendEmailAboutLoadingDelay(
            "Invoices",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexFreeDays = () => {
    axios
      .get("/get-free-days-dates.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.setState({ freeDays: res.data });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  createInvoice = (newData) => {
    if (
      !newData.invoice_date ||
      newData.invoice_date.trim().length === 0 ||
      (newData.invoice_date <= moment(Date.now()).format("YYYY-MM-DD") && newData.invoice_date >= "2015-01-02")
    ) {
      const data = {
        invoice: {
          number:
            newData.number == null
              ? null
              : newData.number
                  .replace(/\s/g, "")
                  .toUpperCase()
                  .trim(),
          invoice_date: newData.invoice_date,
          contact_person: newData.contact_person == null ? null : newData.contact_person.toUpperCase(),
          invoice_shipper: newData.invoice_shipper == null ? null : newData.invoice_shipper.toUpperCase(),
          amount_to_pay_dp:
            typeof newData.amount_to_pay_dp !== "undefined"
              ? newData.amount_to_pay_dp !== null
                ? newData.amount_to_pay_dp
                    .toString()
                    .trim()
                    .replace(",", ".")
                : null
              : null,
          amount_to_pay_lc:
            typeof newData.amount_to_pay_lc !== "undefined"
              ? newData.amount_to_pay_lc !== null
                ? newData.amount_to_pay_lc
                    .toString()
                    .trim()
                    .replace(",", ".")
                : null
              : null,
          amount_to_pay_tt:
            typeof newData.amount_to_pay_tt !== "undefined"
              ? newData.amount_to_pay_tt !== null
                ? newData.amount_to_pay_tt
                    .toString()
                    .trim()
                    .replace(",", ".")
                : null
              : null,
          payment_terms: newData.invoice_shipper == null ? null : newData.invoice_shipper.toUpperCase(),
          payment_ref: newData.payment_ref == null ? null : newData.payment_ref.toUpperCase(),
          has_bank: newData.has_bank,
          payment_date: newData.payment_date,
          dox_received: newData.dox_received,
          //currency_date: newData.currency_date,
          comments: newData.comments,
          validated: newData.validated,
        },
        /* Activity logging */
        user_id: this.props.user_id,
        ip_address: this.props.ip_address,
      };
      const url = "/invoices.json";
      axios
        .post(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
        .then((res) => {
          const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
            this.props.location.search,
            sortingPhraseHelper
          );
          this.indexInvoices(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
          thenResponse(res);
        })
        .catch((err) => {
          catchResponse(err);
        });
    } else {
      toast.error("Wrong Invoice Date");
    }
  };

  updateInvoice = (id, newData, oldData) => {
    let bank_id = null;
    Object.keys(this.state.banks).forEach((key) => {
      if (this.state.banks[key] === newData.bank_name) {
        bank_id = parseInt(key);
      }
    });
    if (
      !newData.invoice_date ||
      newData.invoice_date.trim().length === 0 ||
      (newData.invoice_date <= moment(Date.now()).format("YYYY-MM-DD") && newData.invoice_date >= "2015-01-02")
    ) {
      const data = {
        invoice: {
          number: newData.number == null ? null : newData.number.toUpperCase().trim(),
          invoice_date: newData.invoice_date,
          invoice_shipper: newData.invoice_shipper == null ? null : newData.invoice_shipper.toUpperCase(),
          amount_to_pay_dp:
            typeof newData.amount_to_pay_dp !== "undefined"
              ? newData.amount_to_pay_dp !== null
                ? newData.amount_to_pay_dp
                    .toString()
                    .trim()
                    .replace(" ", "")
                    .replace(",", ".")
                : null
              : null,
          amount_to_pay_lc:
            typeof newData.amount_to_pay_lc !== "undefined"
              ? newData.amount_to_pay_lc !== null
                ? newData.amount_to_pay_lc
                    .toString()
                    .trim()
                    .replace(" ", "")
                    .replace(",", ".")
                : null
              : null,
          amount_to_pay_tt:
            typeof newData.amount_to_pay_tt !== "undefined"
              ? newData.amount_to_pay_tt !== null
                ? newData.amount_to_pay_tt
                    .toString()
                    .trim()
                    .replace(" ", "")
                    .replace(",", ".")
                : null
              : null,
          payment_ref: newData.payment_ref == null ? null : newData.payment_ref.toUpperCase(),
          bank_id: bank_id,
          has_bank: newData.has_bank,
          payment_date: newData.payment_date,
          dox_received: newData.dox_received,
          // currency_date: newData.currency_date,
          comments: newData.comments,
          validated: newData.validated,
        },
        /* Update bookings */
        old_number: oldData.number,
        /* Activity logging */
        user_id: this.props.user_id,
        ip_address: this.props.ip_address,
      };
      const url = "/invoices/" + id + ".json";
      axios
        .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
        .then((res) => {
          axios
            .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
              let fetchedInvoice = this.convertInvoicesValues(res.data);
              const array = [...this.state.data];
              const index = array.findIndex((x) => x.id === fetchedInvoice.id);
              if (index !== -1) {
                array[index].number = fetchedInvoice.number;
                array[index].invoice_date = fetchedInvoice.invoice_date;
                array[index].invoice_shipper = fetchedInvoice.invoice_shipper;
                array[index].amount_to_pay_dp = fetchedInvoice.amount_to_pay_dp;
                array[index].amount_to_pay_lc = fetchedInvoice.amount_to_pay_lc;
                array[index].amount_to_pay_tt = fetchedInvoice.amount_to_pay_tt;
                array[index].payment_ref = fetchedInvoice.payment_ref;
                array[index].bank_name = fetchedInvoice.bank_name;
                array[index].has_bank = fetchedInvoice.has_bank;
                array[index].payment_date = fetchedInvoice.payment_date;
                array[index].dox_received = fetchedInvoice.dox_received;
                array[index].currency_date = fetchedInvoice.currency_date;
                array[index].comments = fetchedInvoice.comments;
                array[index].validated = fetchedInvoice.validated;
                array[index].currency_rate = fetchedInvoice.currency_rate;
                array[index].convert_invoice_value = fetchedInvoice.convert_invoice_value;
                this.setState({ data: array, filteredData: array });
              }
            })
            .catch((err) => {
              catchResponse(err);
            });
          thenResponse(res);
        })
        .catch((err) => {
          catchResponse(err);
        });
    } else {
      toast.error("Wrong Invoice Date");
    }
  };

  deleteInvoice = (id) => {
    const data = {
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .delete("/invoices/" + id + ".json", {
        data: data,
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
          this.props.location.search,
          sortingPhraseHelper
        );
        this.indexInvoices(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  fetchDataProducts = async (invoicesData) => {
    const fetchURL = (url) => axios.get(url);
    let productsData = [];

    let promiseArray = invoicesData.map((item) => {
      return fetchURL(`/products-in-invoice/${item.id}.json`);
    });

    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          productsData = [...productsData, ...item.data];
        });
      })
      .catch((err) => {
        toast.error(err);
      });

    return productsData;
  };

  getUnique = (arr, comp) => {
    const unique = arr
      .map((e) => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  };

  colorFilteredPhrase = (searchValue) => {
    $(document).ready(() => {
      if (searchValue.trim() !== "") {
        document.querySelectorAll("td").forEach((item, index) => {
          if (
            item.firstChild &&
            item.firstChild.nodeValue &&
            item.textContent.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            const text = item.firstChild.nodeValue;
            let re = new RegExp(searchValue.toUpperCase(), "g");
            let inner = text.replace(re, `<mark>${searchValue.toUpperCase()}</mark>`);
            document.querySelectorAll("td")[index].innerHTML = inner;
          }
        });
      }
    });
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          // onRowAdd: (newData) =>
          //     new Promise((resolve) => {
          //         resolve();
          //         this.createInvoice(newData);
          //     }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateInvoice(oldData.id, newData, oldData);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              this.deleteInvoice(oldData.id);
            }),
        },
        actions: [],
      });
    }
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <div>
        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />
        <HotKeys />

        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("Invoices")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          data={this.state.filteredData}
          columns={this.state.columnsHidden}
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <InvoiceProducts token={this.props.token} invoiceId={rowData.id} />
              </div>
            );
          }}
          actions={this.state.actions}
          options={{
            filtering: true,
            search: false,
            pageSize: defaultPageSize,
            exportButton: false,
            actionsCellStyle: {
              width: 50,
            },
          }}
          onChangeRowsPerPage={(pages) => {
            this.setState({ limit: pages, loaded: false, data: [], filteredData: [] });
            localStorage.setItem(`${this.constructor.name}Limit`, pages);
            this.indexInvoices(
              0,
              pages,
              this.state.filterPhrase,
              this.state.sortingColumnsList,
              this.state.singleColumnFilterList
            );
          }}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columnsHidden[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columnsHidden[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columnsHidden[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columnsHidden[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "select") {
                      if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "accepted" || value === "added")
                        value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                      let convertValue;
                      if (indexIsExist !== -1) {
                        let multiFiltersValues = "";
                        value.forEach((filter, index) => {
                          if (this.state.columnsHidden[columnId].field === "bank_name" && value[index] === "")
                            multiFiltersValues += index === 0 ? `IS NULL` : ` OR ${sqlColumn[0].path} IS NULL`;
                          else
                            multiFiltersValues +=
                              index === 0
                                ? `LIKE '%${value[index]}%'`
                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                        });
                        singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                        table.onFilterChange(columnId, value);
                      } else {
                        if (this.state.columnsHidden[columnId].field === "bank_name" && value[0] === "")
                          convertValue = `IS NULL`;
                        else convertValue = `LIKE '%${value}%'`;

                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: convertValue,
                            type: "multiSelect",
                          },
                        ];
                        table.onFilterChange(columnId, value);
                      }
                    }
                    this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            Pagination: (props) => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={this.state.limit}
                  count={this.state.totalRow}
                  page={this.state.page}
                  onChangePage={(e, page) => {
                    this.state.tableRef.current.onChangePage(e, page);
                    this.setState({ page: page });
                    this.goPage(
                      page,
                      this.state.limit,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                />
              );
            },
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            Toolbar: (props) => {
              const table = this.state.tableRef.current;
              let totalQuantity = 0,
                totalValue = 0;

              for (let i = 0; i < props.data.length; i++) {
                totalQuantity += isNaN(props.data[i].invoice_quantity)
                  ? parseInt(props.data[i].invoice_quantity.replace(/\s/g, ""))
                  : parseInt(props.data[i].invoice_quantity);
                totalValue += isNaN(props.data[i].invoice_value)
                  ? parseFloat(props.data[i].invoice_value.replace(",", ".").replace(/\s/g, ""))
                  : parseFloat(props.data[i].invoice_value);
              }

              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 5 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      this.goPage();
                      this.props.history.push(`/invoices?page=${1}&limit=${20}`);

                      table.state.columns.forEach((column, index) => {
                        if (column.field === "accepted") {
                          table.onFilterChange(index, "all");
                          table.state.columns[index].tableData.filterValue = "all";
                        } else {
                          table.onFilterChange(index, null);
                          table.state.columns[index].tableData.filterValue = null;
                          column.tableData.initialValue = "";
                        }
                      });
                    }}
                  />
                  {/* Eksportowanie danych z tabeli */}
                  <div className={classes.exportToExcelWrapper}>
                    <Chip
                      variant="outlined"
                      style={{ marginLeft: 24, marginTop: 10 }}
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => {
                        const startTime = Date.now();
                        this.setState({ loaded: false, filteredData: [] });
                        const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                        const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                          this.props.location.search,
                          sortingPhraseHelper
                        );
                        const sortPath = getSortPath(sortingColumnsList);
                        const filterPath = getFilterPath(singleColumnFilterList);

                        axios
                          .get(
                            `/export-invoices-to-excel.json`,
                            {
                              params: {
                                phrase: search,
                                sortPath: sortPath,
                                filterPath: filterPath,
                                currentSeasons: currentSeasons,
                              },
                            },
                            { headers: { Authorization: `Bearer ${this.props.token}` } }
                          )
                          .then(async (res) => {
                            console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                            let productsData = await this.fetchDataProducts(res.data.invoices);

                            const invoicesData = res.data.invoices.map((data) => {
                              const convertValuesData = this.convertInvoicesValues(data);
                              return convertValuesData;
                            });

                            let filteredInvoicesData = invoicesData.map((invoice) => {
                              let invoiceToExport = {
                                id: invoice.id,
                                container_number: invoice.container_number,
                                invoice_number: invoice.number,
                                ctns: invoice.ctns,
                                validated: invoice.validated,
                                invoice_date: invoice.invoice_date ? new Date(invoice.invoice_date) : null,
                                contact_person: invoice.contact_person,
                                invoice_issuer: invoice.invoice_shipper,
                                invoice_quantity: invoice.invoice_quantity
                                  ? parseFloat(invoice.invoice_quantity.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                invoice_value: invoice.invoice_value
                                  ? parseFloat(invoice.invoice_value.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                amount_to_pay_dp: invoice.amount_to_pay_dp
                                  ? parseFloat(invoice.amount_to_pay_dp.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                amount_to_pay_lc: invoice.amount_to_pay_lc
                                  ? parseFloat(invoice.amount_to_pay_lc.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                amount_to_pay_tt: invoice.amount_to_pay_tt
                                  ? parseFloat(invoice.amount_to_pay_tt.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                payment_terms: invoice.payment_terms,
                                bank_name: invoice.bank_name,
                                payment_ref: invoice.payment_ref_lc_number
                                  ? invoice.payment_ref_lc_number
                                  : invoice.payment_ref,
                                eta: invoice.eta ? new Date(invoice.eta) : null,
                                warehouse: invoice.eta ? new Date(invoice.warehouse) : null,
                                has_bank: invoice.has_bank,
                                payment_date: invoice.payment_date ? new Date(invoice.payment_date) : null,
                                dox_received: invoice.dox_received ? new Date(invoice.dox_received) : null,
                                currency_date: invoice.currency_date ? new Date(invoice.currency_date) : null,
                                season: invoice.season,
                                comments: invoice.comments,
                              };
                              if (
                                parseInt(this.props.user_id) === 40 ||
                                parseInt(this.props.user_id) === 13 ||
                                parseInt(this.props.user_id) === 14
                              ) {
                                invoiceToExport = {
                                  ...invoiceToExport,
                                  currency_rates: invoice.currency_rate
                                    ? parseFloat(invoice.currency_rate.replace(/\s/g, "").replace(",", "."))
                                    : "",
                                  convert_invoice_value: invoice.convert_invoice_value
                                    ? parseFloat(invoice.convert_invoice_value.replace(/\s/g, "").replace(",", "."))
                                    : "",
                                };
                              }

                              return invoiceToExport;
                            });

                            if (this.state.exportChosen) {
                              const exportColumns = this.state.columnsHidden.map((column) => {
                                if (column.field === "number") column.field = "invoice_number";
                                if (column.field === "invoice_shipper") column.field = "invoice_issuer";
                                return column.field;
                              });
                              filteredInvoicesData = filteredInvoicesData.map((row) => {
                                const toDelete = exportColumns;
                                const filtered = Object.keys(row)
                                  .filter((key) => toDelete.includes(key))
                                  .reduce((obj, key) => {
                                    obj[key] = row[key];
                                    return obj;
                                  }, {});

                                return filtered;
                              });
                            }

                            const filteredProductsData = productsData.map((product) => {
                              return {
                                id: product.product_id,
                                container_number: product.container_number,
                                warehouse: product.warehouse ? new Date(product.warehouse) : null,
                                order_number: product.order_number,
                                sex: product.sex,
                                fabric: product.fabric,
                                description: product.description,
                                style_name: product.style_name,
                                index: product.index,
                                quantity_invoice: product.quantity_invoice
                                  ? parseFloat(product.quantity_invoice.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                price_invoice: product.price_invoice
                                  ? parseFloat(product.price_invoice.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                value: product.value
                                  ? parseFloat(product.value.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                              };
                            });

                            json2excel({
                              data: filteredInvoicesData,
                              name: "invoices_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            json2excel({
                              data: filteredProductsData,
                              name: "products_invoices_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            this.setState({ loaded: true, filteredData: this.state.data });
                          })
                          .catch((err) => {
                            catchResponse(err);
                          });
                      }}
                    />
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={this.state.exportChosen}
                        size={"small"}
                        onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                        color="primary"
                      />
                      <span>Export only chosen columns</span>
                    </div>
                  </div>

                  {/* Ukrywanie/Pokazywanie akcji - nie ingeruje w stan, więc nie usuwa filtrów (Ważne: dla wykorzystania w innych tabelach sprawdzić kolejność pobieranych elementów w DOM*/}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24 }}
                    avatar={<Loop />}
                    label="Switch Actions"
                    onClick={() => {
                      const tableData = document.querySelectorAll("tbody")[1];
                      if (tableData.querySelector("tr").querySelectorAll("td")[1].style.display !== "none") {
                        tableData.querySelector("tr").querySelectorAll("td")[1].style.display = "none";
                        document.querySelector(".MuiTableCell-paddingCheckbox").style.display = "none";
                        document.querySelectorAll(".MuiTableCell-paddingNone").forEach((cell) => {
                          cell.style.display = "none";
                        });
                      } else {
                        tableData.querySelector("tr").querySelectorAll("td")[1].style.display = "table-cell";
                        document.querySelector(".MuiTableCell-paddingCheckbox").style.display = "table-cell";
                        document.querySelectorAll(".MuiTableCell-paddingNone").forEach((cell) => {
                          cell.style.display = "table-cell";
                        });
                      }
                    }}
                  />

                  {/*Generowanie tagu szukania*/}
                  {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        this.goPage(
                          0,
                          this.state.limit,
                          "",
                          this.state.sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ filterPhrase: "" });
                      }}
                      label={`search: ${this.state.filterPhrase}`}
                      color="primary"
                    />
                  )}

                  {/*Generowanie tagów filtrów kolumn*/}
                  {this.state.singleColumnFilterList.map((filter) => {
                    let value;
                    let key;
                    let icon = "";
                    if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                    else if (filter.type === "text")
                      value = filter.value
                        .replace("LIKE", "")
                        .replace("'%", "")
                        .replace("%'", "");
                    else if (filter.type === "select")
                      value = filter.value
                        .replace("LIKE", "")
                        .replace("'%", "")
                        .replace("%'", "");
                    else if (filter.type === "multiSelect")
                      value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",")
                        .replace("IS NULL", "EMPTY");
                    else if (filter.type === "number") value = filter.value.replace("=", "");
                    else if (filter.type === "boolean") {
                      if (filter.value.includes("true")) {
                        value = "checked";
                        icon = <Check />;
                      } else {
                        value = "unchecked";
                        icon = <Close />;
                      }
                    }

                    /*WYJĄTKI*/
                    if (
                      filter.column.key === "accepted" &&
                      filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`
                    )
                      value = "ALL";
                    if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                      value = "EMPTY";
                    if (
                      filter.column.key === "accepted" &&
                      filter.value ===
                        `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path} NOT LIKE "%cancel%" AND ${filter.column.path} NOT LIKE "%hold%"`
                    )
                      value = "ACCEPTED";
                    if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) value = "HOLD";
                    if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`) value = "CANCEL";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `="" OR ${filter.column.path} IS NULL`
                    )
                      value = "UNADDED";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
                    )
                      value = "ADDED";
                    filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);
                    if (filter.column.key === "payment_term_id") {
                      let currentValue = "";
                      const column = this.state.columns.filter((item) => item.field === "payment_term_id")[0].lookup;
                      const paymnetValue = value.replace(/\s/g, "").split(",");
                      paymnetValue.forEach((item, index) =>
                        index + 1 === paymnetValue.length
                          ? (currentValue += `${column[parseInt(item)]}`)
                          : (currentValue += `${column[parseInt(item)]}, `)
                      );
                      value = currentValue;
                      key = "PAYMENT";
                    }

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                            (item) => item.column.key !== filter.column.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            this.state.sortingColumnsList,
                            singleColumnFilterList
                          );
                          this.setState({ singleColumnFilterList: singleColumnFilterList });
                          const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                          if (index !== -1) {
                            if (table.state.columns[index].field === "accepted") {
                              table.onFilterChange(index, "all");
                            } else {
                              table.state.columns[index].tableData.initialValue = "";
                              table.onFilterChange(index, null);
                            }
                          }
                        }}
                        label={`${key.replace("_", " ").toUpperCase()}:  ${value.toUpperCase()}`}
                        icon={icon}
                        variant="outlined"
                      />
                    );
                  })}

                  {/*Generowanie tagów sortowania*/}
                  {this.state.sortingColumnsList.map((column) => {
                    let key;
                    let icon = "";
                    if (column.order === "ASC") icon = <ArrowDownward />;
                    if (column.order === "DESC") icon = <ArrowUpward />;

                    /*WYJĄTKI*/
                    column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const sortingColumnsList = this.state.sortingColumnsList.filter(
                            (item) => item.sortBy.key !== column.sortBy.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ sortingColumnsList: sortingColumnsList });
                        }}
                        label={`${key.toUpperCase().replace("_", " ")}`}
                        icon={icon}
                      />
                    );
                  })}
                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      onOpen={() =>
                        !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                      }
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "invoices",
                          };
                        })}
                      options={this.state.optionsColumns
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "invoices",
                          };
                        })}
                      onChangeColumns={async (columns) => {
                        await this.setState({ columnsHidden: [], currentStateColumns: null });
                        await this.setSelectedColumns(columns);
                        this.setState({ blockedSaveColumnsButton: false });
                        this.setSortingForColumns();
                      }}
                    />
                    <Button
                      disabled={this.state.blockedSaveColumnsButton}
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_invoices_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                          this.setState({ blockedSaveColumnsButton: true });
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_invoices_${this.props.user_id}`);
                          await this.setState({ columnsHidden: this.state.columns, currentStateColumns: null });
                          toast.success("Column settings have been removed!");
                          this.setSortingForColumns();
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>
                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSearch} />
                  </div>
                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Invoice quantity</TableCell>
                        <TableCell align="right">Invoice value</TableCell>
                        <TableCell align="right">Quantity Percentange</TableCell>
                        <TableCell align="right">Value Percentange</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "20%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {formatterQuantity.format(this.state.totalQuantity)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {formatterCurrency.format(this.state.totalValue)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {`${this.state.quantityPercentage}`.replace(".", ",")} %
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {`${this.state.valuePercentage}`.replace(".", ",")} %
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Invoices);
