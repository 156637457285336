import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";

import { authRedirect, catchResponse, thenResponse } from "../../shared/utility";

import OrderTable from "./OrderTable/OrderTable";
import ProductForm from "./ProductForm/ProductForm";
import Spinner from "../../components/UI/Spinner/Spinner";
import { ToastContainer } from "react-toastify";

import Hotkeys from "react-hot-keys";
import $ from "jquery";

import classes from "./Order.module.scss";
import { Button, Paper } from "@material-ui/core";
import { Prompt } from "react-router";
import { role } from "../../config";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";

class Order extends Component {
  state = {
    designer: null,
    // Wynikające z umożliwienia dodawania większej ilości produktów
    productsData: [],
    productsQuantity: 0,
    productsStartQuantity: 0,
    defined_part: null,
    accepted: null,
    cancelled: false,
    number: null,
    season_id: null,
    barcodeGenerated: false,
    id: null,
    totalQuantity: null,
    totalValue: null,
    submittingTotals: false,
  };

  componentDidMount() {
    axios
      .get("/orders/" + this.props.match.params.id + ".json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.setState({
          designer: res.data.order_designer,
          defined_part: res.data.defined_part,
          accepted: res.data.accepted,
          barcodeGenerated: res.data.barcode_generated,
          cancelled: res.data.cancelled,
          number: res.data.number,
          season_id: res.data.season_id,
          id: res.data.id,
          totalQuantity: res.data.total_quantity,
          totalValue: res.data.total_value,
        });
        this.fetchProduct();
      })
      .catch((err) => {
        catchResponse(err);
      });
  }

  // Fetch'owanie produktu, kombinacji i rozmiarów
  fetchProduct = () => {
    axios
      .get("/orders/" + this.props.match.params.id + "/products.json", {
        headers: { Authorization: `Bearer ${this.props.token}` },
      })
      .then((res) => {
        this.setState({
          productsData: res.data,
          productsStartQuantity: res.data.length,
          productsQuantity: res.data.length,
        });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  saveProducts = () => {
    $(`.submitSection button`).click();
  };

  handleProductsStartQuantity = (value) => {
    this.setState({ productsStartQuantity: value });
  };

  restoreOrder = () => {
    const data = {
      order: {
        cancelled: false,
        number: this.state.number,
        season_id: this.state.season_id,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .put("/orders/" + this.state.id, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.setState({ cancelled: false });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    let disabled;
    if (this.state.accepted === "" || this.state.accepted === null) {
      disabled = false;
    } else {
      disabled = this.props.role === "designer";
    }

    if (this.props.role === role.MANAGEMENT) disabled = true;

    if (this.state.barcodeGenerated && this.props.role === "designer") disabled = true; //Condition, when barcode is generated, designers have locked fields

    const productForms = [];
    for (let i = 0; i < this.state.productsQuantity; i++) {
      productForms.push(
        <ProductForm
          key={"product_" + i}
          defaultProductOrder={this.state.productsData[i]?.order_product?.product_order || i + 1}
          orderId={this.props.match.params.id}
          designer={this.state.designer}
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          // Wynikające z umożliwienia dodawania większej ilości produktów
          rerenderForms={() => {
            this.setState({
              productsData: [],
              productsQuantity: 0,
            });
            this.fetchProduct();
          }}
          productData={this.state.productsData[i]}
          editForm={typeof this.state.productsData[i] !== "undefined"}
          defined_part={this.state.defined_part}
          disabled={disabled}
          productsQuantity={this.state.productsQuantity}
          productsStartQuantity={this.state.productsStartQuantity}
          handleProductsStartQuantity={this.handleProductsStartQuantity}
        />
      );
    }

    let content = (
      <>
        {authRedirect(this.props.token)}
        <ToastContainer />
        <Prompt
          when={this.state.productsStartQuantity !== this.state.productsQuantity}
          message={"You have unsaved products"}
        />
        <Hotkeys
          keyName="alt+s"
          onKeyDown={() => {
            $(`#save-order-button`).click();
            $(`.submitSection button`).click();
            $(`.save-color-button`).click();
          }}
        />

        {this.state.cancelled ? (
          <div className={classes.Blockquote}>
            <p>Order is cancelled!</p>
            {this.props.role === "admin" ? (
              <Button className={classes.RestoreButton} variant="contained" onClick={() => this.restoreOrder()}>
                Restore order
              </Button>
            ) : null}
          </div>
        ) : null}

        <OrderTable
          orderId={this.props.match.params.id}
          token={this.props.token}
          user_id={this.props.user_id}
          ip_address={this.props.ip_address}
          updateDefinedPart={(defined_part) => this.setState({ defined_part: defined_part })}
          role={this.props.role}
          disabled={disabled}
          updateTotal={(quantity, value) => this.setState({ totalQuantity: quantity, totalValue: value })}
          setSubmittingTotals={(value) => this.setState({ submittingTotals: value })}
        />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Paper style={{ marginTop: 20, width: "auto", padding: 10, display: "flex", minWidth: "410px" }}>
            {this.state.submittingTotals ? (
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <ProgressCircular size={20} />
              </div>
            ) : (
              <>
                <div style={{ marginRight: 20 }}>
                  <strong>TOTAL QUANTITY:</strong> {this.state.totalQuantity}
                </div>
                <div>
                  <strong>TOTAL VALUE:</strong> {this.state.totalValue} USD
                </div>
              </>
            )}
          </Paper>
        </div>

        {productForms}

        <div className={classes.ButtonsContainer}>
          {!disabled ? (
            <Button
              variant="contained"
              onClick={() => this.setState({ productsQuantity: this.state.productsQuantity + 1 })}
            >
              Add product
            </Button>
          ) : null}

          {this.state.productsQuantity > 0 ? (
            <Button variant="contained" className={classes.SaveProducts} onClick={() => this.saveProducts()}>
              Save
            </Button>
          ) : null}
        </div>
      </>
    );

    if (this.props.user_id === null || this.state.designer === null) {
      content = <Spinner />;
    }

    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(Order);
