import * as actionTypes from "./actionTypes";
import axios from "../../axios-sw";

import publicIp from "public-ip";
import { setSeasons } from "../reducers/systemInfo";

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("user_id");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, user_id, role, full_name, avatar, position, ip_address, redirection) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    user_id: user_id,
    role: role,
    full_name: full_name,
    avatar: avatar,
    position: position,
    ip_address: ip_address,
    authRedirectPath: redirection,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const auth = (email, password, otpAttempt) => {
  return (dispatch) => {
    dispatch(authStart());
    (async () => {
      const ip_address = await publicIp.v4();
      const authData = {
        user: {
          email: email,
          password: password,
          otp_attempt: otpAttempt,
        },
        id_address: ip_address,
      };
      let url = "/login";
      axios
        .post(url, authData)
        .then((res) => {
          const user = res.data;
          const expirationDate = new Date(new Date().getTime() + user.exp * 1000);
          localStorage.setItem("token", res.headers.authorization.split(" ")[1]);
          localStorage.setItem("expirationDate", expirationDate);
          localStorage.setItem("user_id", user.id);
          localStorage.setItem("user_role", user.role);
          const rolePath = localStorage.getItem("rolePath");
          const originPath = localStorage.getItem("originPath");

          let redirection;
          if (user.role === "designer") {
            redirection = `/designer-dashboard/${user.id}`;
          } else {
            redirection = originPath !== "/login" && rolePath === user.role ? originPath : "/";
          }
          dispatch(
            setSeasons(JSON.parse(window.localStorage.getItem(`user_seasons_${localStorage.getItem("user_id")}`)))
          );
          dispatch(
            authSuccess(
              res.headers.authorization.split(" ")[1],
              user.id,
              user.role,
              user.full_name,
              user.avatar,
              user.position,
              ip_address,
              redirection
            )
          );
        })
        .catch((err) => {
          dispatch(authFail(err));
        });
    })();
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
      if (window.location.pathname !== "/login") window.location.replace("/login");
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
        if (window.location.pathname !== "/login") window.location.replace("/login");
      } else {
        const user_id = localStorage.getItem("user_id");
        const token = localStorage.getItem("token");
        axios
          .get(`/users/${user_id}.json`, { headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            const user = res.data;
            (async () => {
              const ip_address = await publicIp.v4();
              dispatch(
                authSuccess(
                  token,
                  user_id,
                  user.role,
                  user.name + " " + user.surname,
                  user.avatar,
                  user.position,
                  ip_address
                )
              );
            })();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
};

export const accountUpdate = (full_name, avatar) => {
  return {
    type: actionTypes.AUTH_ACCOUNT_UPDATE,
    full_name: full_name,
    avatar: avatar,
  };
};
