import React, {Component} from 'react';
import axios from '../../../axios-sw';
import {connect} from 'react-redux';
import $ from "jquery";

import {toast, ToastContainer} from 'react-toastify';

import MaterialTable, {MTableBodyRow, MTableToolbar} from 'material-table';
import {tableIcons, titleTemplate} from '../../../shared/tableVariables';

import Columns from '../../../shared/tables/warehouseTables/containersTable';

import ContainerInvoices from "./ContainerInvoices/ContainerInvoices";
import {HotKeys} from "../../../components/System/HotKeys/HotKeys";
import SearchInput from "../../../components/UI/SearchInput/SearchInput"

import {
    authRedirect,
    catchResponse,
    formatDate,
    indexDetails,
    nonAdminRedirect, postFile,
    roleLimitedView, thenResponse
} from '../../../shared/utility';
import Spinner from "../../../components/UI/Spinner/Spinner";
import {defaultPageSize} from "../../../shared/defines";
import {Button, Chip} from "@material-ui/core";
import {GetApp, HighlightOff, Loop} from "@material-ui/icons";
import {json2excel} from "js2excel";
import classes from "../../Bookings/Bookings.module.scss";
import AdvencedFilters from "../../../components/System/AdvencedFilters/AdvencedFilters";

class Containers extends Component {

    state = {
        columns: Columns.containersColumns,
        data: [],
        filteredData: [],
        parentChildData: "",
        containerInvoices: null,
        loaded: false,
        openAll: false,
        filterPhrase: '',
        tableRef: React.createRef(),
        filterByColumn: null,
        filterByConditional: null
    };

    componentDidMount() {
        this.indexContainers();
    }

    getUnique = (arr, comp) => {

        const unique = arr.map(e => e[comp])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((e) => arr[e]).map(e => arr[e]);

        return unique;
    }

    colorFilteredPhrase = (searchValue) => {
        $(document).ready(() => {
            if (searchValue.trim() !== '') {
                document.querySelectorAll('td').forEach((item, index) => {
                    if (item.firstChild && item.textContent.toLowerCase() === searchValue.toLowerCase()) {
                        const text = document.querySelectorAll('td')[index].innerHTML
                        document.querySelectorAll('td')[index].innerHTML = '';
                        const p = document.createElement("p");
                        p.classList.add('marked');
                        p.innerHTML = text;
                        document.querySelectorAll('td')[index].append(p);
                    }
                });
            }
        });
    }

    handleSerach = (val) => {
        let table = this.state.tableRef.current;
        table.dataManager.data.map(item => {
            if (item.tableData.showDetailPanel) {
                item.tableData.showDetailPanel = undefined;
            }
        })
        if (val) {
            table.onSearchChange('');
            const searchValue = val.toLowerCase();
            const data = this.state.data;

            let filteredContainersData = data.filter(data => {
                const {comments, container_type, dox_ac, eta, etd, fv_gave_back, number, port_name, season, warehouse} = data;
                return (
                    (comments && comments.toLowerCase().includes(searchValue)) ||
                    (container_type && container_type.toLowerCase().includes(searchValue)) ||
                    (dox_ac && dox_ac.toLowerCase().includes(searchValue)) ||
                    (eta && eta.toLowerCase().includes(searchValue)) ||
                    (fv_gave_back && fv_gave_back.toLowerCase().includes(searchValue)) ||
                    (number && number.toLowerCase().includes(searchValue)) ||
                    (port_name && port_name.toLowerCase().includes(searchValue)) ||
                    (season && season.toLowerCase().includes(searchValue)) ||
                    (warehouse && warehouse.toLowerCase().includes(searchValue)) ||
                    (etd && etd.toLowerCase().includes(searchValue))
                )
            })

            let filteredContainerInvoicesData = this.state.containerInvoices.filter(data => {
                const {shipper, contact_person, ctns, cbm, quantity_in_pcs, value, invoice_number, invoice_date, dpl, net, gross, average_carton_weight, average_item_weight, originals_approved_date} = data;
                return (
                    (shipper && shipper.toLowerCase().includes(searchValue)) ||
                    (contact_person && contact_person.toLowerCase().includes(searchValue)) ||
                    (ctns && String(ctns).toLowerCase().includes(searchValue)) ||
                    (cbm && cbm.toLowerCase().includes(searchValue)) ||
                    (quantity_in_pcs && quantity_in_pcs.toLowerCase().includes(searchValue)) ||
                    (value && value.toLowerCase().includes(searchValue)) ||
                    (invoice_number && invoice_number.toLowerCase().includes(searchValue)) ||
                    (invoice_date && invoice_date.toLowerCase().includes(searchValue)) ||
                    (dpl && dpl.toLowerCase().includes(searchValue)) ||
                    (net && net.toLowerCase().includes(searchValue)) ||
                    (gross && gross.includes(searchValue)) ||
                    (average_carton_weight && String(average_carton_weight).toLowerCase().includes(searchValue)) ||
                    (average_item_weight && String(average_item_weight).toLowerCase().includes(searchValue)) ||
                    (originals_approved_date && originals_approved_date.toLowerCase().includes(searchValue))
                );
            });

            if (filteredContainerInvoicesData.length > 0 && filteredContainersData.length === 0) {
                this.colorFilteredPhrase(searchValue);
                const containersNumbers = filteredContainerInvoicesData.map(item => item.container_number);
                let containers = []
                data.map(data => {
                    containersNumbers.map(number => {
                        if (data.number === number) {
                            containers = [...containers, data];
                        }
                    })
                })

                containers = Array.from(new Set(containers.map(a => a.number))).map(number => {
                    return containers.find(a => a.number === number);
                });
                this.setState({filteredData: containers, filterPhrase: val});
            } else if (filteredContainersData.length > 0 && filteredContainerInvoicesData.length === 0) {
                this.colorFilteredPhrase(searchValue);
                this.setState({filteredData: filteredContainersData, filterPhrase: val});
            } else if (filteredContainersData.length === 0 && filteredContainerInvoicesData.length === 0) {
                this.colorFilteredPhrase(searchValue);
                this.setState({filteredData: [], filterPhrase: val});
            } else {
                this.colorFilteredPhrase(searchValue);
                const containersNumbers = filteredContainerInvoicesData.map(item => item.container_number);
                let containers = []
                data.map(data => {
                    containersNumbers.map(number => {
                        if (data.number === number) {
                            containers = [...containers, data];
                        }
                    })
                })

                let newData = [...filteredContainersData, ...containers]
                newData = this.getUnique(newData, 'number');
                this.setState({filteredData: newData, filterPhrase: val});
            }
        } else {
            table.onSearchChange('')
            this.setState({filteredData: this.state.data, filterPhrase: ''})
        }
    }

    indexContainers = () => {
        axios.get('/containers.json', {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                indexDetails('ports', 6, 'code', this.props.token, this.state.columns, (newState) => this.setState(newState));
                this.setState({data: res.data, filteredData: res.data, loaded: true});
                axios.get('/container-invoices.json', {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(res => {
                        this.setState({containerInvoices: res.data.filter(item => item.container_number !== null)})
                    })
                    .catch(err => {
                        catchResponse(err)
                    })
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    mapOrder = (array, order, key) => {
        array.sort((a, b) => {
            let A = a[key], B = b[key];
            if (order.indexOf(A) > order.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }
        });
        return array;
    };

    fetchDataContainerInvoices = async () => {
        const {data} = this.state;
        const table = this.state.tableRef.current
        const containersNumbers = table.state.data.map(item => item.number);

        const fetchURL = (url) => axios.get(url);
        let containerInvociesData = [];

        let promiseArray = data.map(item => {
            return fetchURL(`/container-invoices/${item.id}.json`)
        })

        await Promise.all(promiseArray)
            .then((data) => {
                data.map(item => {
                    containerInvociesData = [...containerInvociesData, ...item.data]
                })
            })
            .catch((err) => {
                toast.error(err)
            });

        /*FILTROWANIE DANYCH NA PODSTAWIE FILTRÓW*/
        let filteredContainerIncoivesData = [];
        containerInvociesData.map(containerInvoice => {
            containersNumbers.map(number => {
                if (containerInvoice.container_number === number) {
                    filteredContainerIncoivesData = [...filteredContainerIncoivesData, containerInvoice]
                }
            })
        })

        /*SORTOWANIE DANYCH NA PODSTAWIE KOLEJNOŚCI KONTENERÓW*/
        filteredContainerIncoivesData = this.mapOrder(filteredContainerIncoivesData, containersNumbers, 'container_number');

        return filteredContainerIncoivesData;
    }

    onFilterField = (filterByColumn, filterByConditional) => {
        const {containerInvoices, filteredData, data} = this.state;
        const table = this.state.tableRef.current;
        const dataToFilter = filterByColumn.table === "containers" ? data : containerInvoices;

        table.dataManager.data.map(item => {
            if (item.tableData.showDetailPanel) {
                item.tableData.showDetailPanel = undefined;
            }
        })

        if (filterByColumn && filterByConditional) {
            if (filterByColumn.table === "containers") {
                if (filterByConditional.value === "empty") {
                    const filteredData = dataToFilter.filter(data => {
                        return !data[filterByColumn.value] && !data[filterByColumn.value] || data[filterByColumn.value].toString().trim() === ""
                    })
                    this.setState({filteredData: filteredData})
                } else if (filterByConditional.value === "full") {
                    const filteredData = dataToFilter.filter(data => {
                        return data[filterByColumn.value] && data[filterByColumn.value].toString().trim() !== ""
                    })
                    this.setState({filteredData: filteredData})
                } else if (filterByConditional.value === "all") {
                    this.setState({filteredData: dataToFilter})
                }
            } else if (filterByColumn.table === "containersInvoices") {
                if (filterByConditional.value === "empty") {
                    const filteredData = dataToFilter.filter(data => {
                        return !data[filterByColumn.value] && !data[filterByColumn.value] || data[filterByColumn.value].toString().trim() === ""
                    })

                    const containersNumbers = filteredData.map(item => item.container_number);
                    let containers = []
                    data.map(data => {
                        containersNumbers.map(number => {
                            if (data.number === number) {
                                containers = [...containers, data];
                            }
                        })
                    })

                    containers = Array.from(new Set(containers.map(a => a.number))).map(number => {
                        return containers.find(a => a.number === number);
                    });
                    this.setState({
                        filteredData: containers,
                        filterByColumn: filterByColumn,
                        filterByConditional: filterByConditional
                    });

                } else if (filterByConditional.value === "full") {
                    const filteredData = dataToFilter.filter(data => {
                        return data[filterByColumn.value] && data[filterByColumn.value].toString().trim() !== ""
                    })

                    const containersNumbers = filteredData.map(item => item.container_number);
                    let containers = []
                    data.map(data => {
                        containersNumbers.map(number => {
                            if (data.number === number) {
                                containers = [...containers, data];
                            }
                        })
                    })

                    containers = Array.from(new Set(containers.map(a => a.number))).map(number => {
                        return containers.find(a => a.number === number);
                    });
                    this.setState({
                        filteredData: containers,
                        filterByColumn: filterByColumn,
                        filterByConditional: filterByConditional
                    });
                } else if (filterByConditional.value === "all") {
                    this.setState({
                        filteredData: data,
                        filterByColumn: filterByColumn,
                        filterByConditional: filterByConditional
                    })
                }
                setTimeout(() => {
                    table.state.data.map((item, index) => {
                        table.onToggleDetailPanel([index], rowData => {
                            return (
                                <div className="detailPanel">
                                    <ContainerInvoices
                                        token={this.props.token}
                                        user_id={this.props.user_id}
                                        ip_address={this.props.ip_address}
                                        containerId={rowData.id}
                                        containerNumber={rowData.number}
                                        filterByColumn={filterByColumn}
                                        filterByConditional={filterByConditional}
                                    />
                                </div>
                            )
                        })
                    })
                }, 100)
            }
        } else {
            toast.error('Select column or conditional!');
        }
    }

    updateContainer = (number, newData, oldData) => {
        const data = {
            container: {
                number: newData.number == null ? null : newData.number.replace(/\s/g, '').toUpperCase(),
                container_type: newData.container_type == null ? null : newData.container_type.toUpperCase(),
                etd: newData.etd, // datetime
                eta: newData.eta, // datetime
                warehouse: newData.warehouse, // datetime
                port_id: newData.port_id,
                fv: newData.fv, // datetime
                fv_gave_back: newData.fv_gave_back, // datetime
                supplied: newData.supplied, // boolean
                comments: newData.comments == null ? null : newData.comments.toUpperCase(),
                file: newData.file ? {url: newData.file} : null,
                dox_ac: newData.dox_ac
            },
            // Na potrzeby podmiany bookings
            old_number: oldData.number,
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address
        };

        const url = '/containers/' + number + '.json';
        axios.put(url, data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                axios.get(url, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(res => {
                        const fetchedContainer = res.data;
                        const array = [...this.state.data];
                        const index = array.findIndex(x => x.id === fetchedContainer.id);
                        if (index !== -1) {
                            array[index].number = fetchedContainer.number;
                            array[index].container_type = fetchedContainer.container_type;
                            array[index].etd = fetchedContainer.etd;
                            array[index].eta = fetchedContainer.eta;
                            array[index].port_id = fetchedContainer.port_id;
                            array[index].fv = fetchedContainer.fv;
                            array[index].fv_gave_back = fetchedContainer.fv_gave_back;
                            array[index].supplied = fetchedContainer.supplied;
                            array[index].comments = fetchedContainer.comments;
                            array[index].file = fetchedContainer.file;
                            array[index].dox_ac = fetchedContainer.dox_ac;
                            this.setState({data: array});
                        }
                    })
                    .catch(err => {
                        catchResponse(err)
                    });
                thenResponse(res)
            })
            .then(() => {
                postFile('/containers-upload-file/',
                    'file',
                    newData,
                    this.props.user_id,
                    this.props.ip_address,
                    this.props.token,
                    () => {
                        axios.get(url, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                            .then(res => {
                                const fetchedContainer = res.data;
                                const array = [...this.state.data];
                                const index = array.findIndex(x => x.id === fetchedContainer.id);
                                if (index !== -1) {
                                    array[index].number = fetchedContainer.number;
                                    array[index].container_type = fetchedContainer.container_type;
                                    array[index].etd = fetchedContainer.etd;
                                    array[index].eta = fetchedContainer.eta;
                                    array[index].port_id = fetchedContainer.port_id;
                                    array[index].fv = fetchedContainer.fv;
                                    array[index].fv_gave_back = fetchedContainer.fv_gave_back;
                                    array[index].supplied = fetchedContainer.supplied;
                                    array[index].comments = fetchedContainer.comments;
                                    array[index].file = fetchedContainer.file;
                                    array[index].dox_ac = fetchedContainer.dox_ac;
                                    this.setState({data: array});
                                }
                            })
                            .catch(err => {
                                catchResponse(err)
                            });
                    });
            })
            .catch(err => {
                catchResponse(err)
            })
    };

    render() {

        let emptyDataMessage;
        if (!this.state.loaded) {
            emptyDataMessage = <Spinner/>
        } else {
            emptyDataMessage = 'No data to display'
        }

        return (
            <div>
                {authRedirect(this.props.token)}
                {roleLimitedView('warehouse', this.props.role, this.props.user_id)}
                <ToastContainer/>
                <HotKeys/>
                <MaterialTable
                    actions={this.state.actions}
                    tableRef={this.state.tableRef}
                    title={titleTemplate('Containers')}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataMessage,
                            editRow: {
                                cancelTooltip: 'Back'
                            }
                        }
                    }}
                    icons={tableIcons}
                    style={{width: '100%'}}
                    data={this.state.filteredData}
                    columns={this.state.columns}
                    /*parentChildData={(row, rows) => rows.find(a => a.lc_id === row.parent_lc_id)}*/
                    detailPanel={rowData => {
                        return (
                            <div className="detailPanel">
                                <ContainerInvoices
                                    token={this.props.token}
                                    user_id={this.props.user_id}
                                    ip_address={this.props.ip_address}
                                    containerId={rowData.id}
                                    containerNumber={rowData.number}
                                    filterByColumn={this.state.filterByColumn}
                                    filterByConditional={this.state.filterByConditional}
                                />
                            </div>
                        )
                    }}
                    options={{
                        search: false,
                        filtering: true,
                        pageSize: defaultPageSize,
                        exportButton: true,
                        exportFileName: 'containers_' + formatDate(Date.now()),
                    }}
                    components={{
                        Row: props => (
                            <MTableBodyRow className={props.data.styles} {...props} />
                        ),
                        Toolbar: props => {
                            return (
                                <div className='mainToolbar'>
                                    <MTableToolbar {...props} />

                                    {/* Usuwanie filtra z tabeli */}
                                    <Chip variant="outlined" style={{marginLeft: 24}} avatar={<HighlightOff/>}
                                          label="Clear filters" onClick={() => {
                                        const table = this.state.tableRef.current;
                                        for (let i = 0; i < table.state.columns.length; i++) {
                                            table.onFilterChange(i, null);
                                            table.state.columns[i].tableData.filterValue = null;
                                            table.onSearchChange('');
                                            this.setState({
                                                filteredData: this.state.data,
                                                filterPhrase: '',
                                                filterByColumn: null,
                                                filterByConditional: null
                                            })
                                            table.dataManager.data.map(item => {
                                                if (item.tableData.showDetailPanel) {
                                                    item.tableData.showDetailPanel = undefined;
                                                }
                                            })
                                        }
                                    }}/>
                                    {/* Eksportowanie danych z tabeli */}
                                    <Chip variant="outlined" style={{marginLeft: 24}}
                                          avatar={<GetApp style={{padding: 5}}/>} label="Export data to Excel"
                                          onClick={async () => {
                                              let dataContainers = this.state.tableRef.current.state.data;
                                              let dataContainerInvoices = await this.fetchDataContainerInvoices();

                                              /*Dane exportowane do excella*/
                                              const filteredContainersData = dataContainers.map(container => {
                                                  return {
                                                      id: container.id,
                                                      season: container.season,
                                                      container_number: container.number,
                                                      container_type: container.container_type,
                                                      etd: container.etd ? new Date(container.etd) : null,
                                                      eta: container.eta ? new Date(container.eta) : null,
                                                      warehouse: container.warehouse ? new Date(container.warehouse) : null,
                                                      port_name: container.port_name,
                                                      dox_ac: container.dox_ac ? new Date(container.dox_ac) : null,
                                                      fv: container.fv,
                                                      fv_gave_back: container.fv_gave_back,
                                                      comments: container.comments,
                                                      delivered: container.supplied
                                                  }
                                              })

                                              let filteredContainerInvoicesData = dataContainerInvoices.map(item => {
                                                  return {
                                                      id: item.id,
                                                      container_number: item.container_number,
                                                      shipper: item.shipper,
                                                      contact_person: item.contact_person,
                                                      etd: item.etd ? new Date(item.etd) : null,
                                                      eta: item.eta ? new Date(item.eta) : null,
                                                      ctns: item.ctns,
                                                      cbm: item.cbm ? parseFloat(item.cbm.replace(/\s/g, '').replace(',', '.')) : 0,
                                                      quantity_in_pcs: item.quantity_in_pcs ? parseFloat(item.quantity_in_pcs.replace(/\s/g, '').replace(',', '.')) : 0,
                                                      value: item.value ? parseFloat(item.value.replace(/\s/g, '').replace(',', '.')) : 0,
                                                      invoice_date: item.invoice_date ? new Date(item.invoice_date) : null,
                                                      invoice_number: item.invoice_number,
                                                      check_in_request_date: item.check_in_request_date,
                                                      dpl: item.dpl ? new Date(item.dpl) : null,
                                                      net: item.net ? parseFloat(item.net.replace(/\s/g, '').replace(',', '.')) : 0,
                                                      gross: item.gross ? parseFloat(item.gross.replace(/\s/g, '').replace(',', '.')) : 0,
                                                      average_carton_weight: item.average_carton_weight,
                                                      average_item_weight: item.average_item_weight,
                                                      documents_approved: item.documents_approved,
                                                      season_as_per_invoice: item.season,
                                                      originals_approved_date: item.originals_approved_date ? new Date(item.originals_approved_date) : null
                                                  }
                                              });

                                              json2excel({
                                                  data: filteredContainerInvoicesData,
                                                  name: 'container_invoices_' + formatDate(Date.now()),
                                                  formateDate: 'dd/mm/yyyy'
                                              });

                                              json2excel({
                                                  data: filteredContainersData,
                                                  name: 'containers_' + formatDate(Date.now()),
                                                  formateDate: 'dd/mm/yyyy'
                                              });

                                          }}/>
                                    {/* Rozwijanie i zwijanie wszystkich subtabel */}
                                    <Chip variant="outlined" style={{marginLeft: 24}}
                                          avatar={<Loop/>}
                                          label={"Switch all subtales"}
                                          onClick={async () => {
                                              const table = this.state.tableRef.current;
                                              table.state.data.map((item, index) => {
                                                  table.onToggleDetailPanel([index], rowData => {
                                                      return (
                                                          <div className="detailPanel">
                                                              <ContainerInvoices
                                                                  token={this.props.token}
                                                                  user_id={this.props.user_id}
                                                                  ip_address={this.props.ip_address}
                                                                  containerId={rowData.id}
                                                                  containerNumber={rowData.number}
                                                                  filterByColumn={this.state.filterByColumn}
                                                                  filterByConditional={this.state.filterByConditional}
                                                              />
                                                          </div>
                                                      )
                                                  })
                                              })
                                          }}/>
                                    <AdvencedFilters onFilterField={this.onFilterField} options={[
                                        {
                                            label: "Containers Columns",
                                            options: Columns.containersColumns
                                                .filter(column => column.field !== 'id')
                                                .map(column => {
                                                    return {
                                                        label: column.title,
                                                        value: column.field,
                                                        table: "containers"
                                                    }
                                                })
                                        }, {
                                            label: "Containers Invoices Columns",
                                            options: Columns.containerInvoicesColumns
                                                .filter(column => column.field !== 'id')
                                                .map(column => {
                                                    return {
                                                        label: column.title,
                                                        value: column.field,
                                                        table: "containersInvoices"
                                                    }
                                                })
                                        }
                                    ]}
                                                     filters={{
                                                         filterByColumn: this.state.filterByColumn,
                                                         filterByConditional: this.state.filterByConditional
                                                     }}
                                    />
                                    <div className={classes.SearchWrapper}>
                                        <SearchInput filterPhrase={this.state.filterPhrase}
                                                     onChange={this.handleSerach}/>
                                    </div>
                                </div>
                            )
                        }
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                resolve();
                                this.updateContainer(oldData.id, newData, oldData)
                            })
                    }}
                />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        role: state.auth.role
    }
};

export default connect(mapStateToProps)(Containers);