import React, { useState, useEffect } from "react";
import MaterialTable, { MTableBodyRow, MTableEditRow } from "material-table";
import { Grid } from "@material-ui/core";
import axios from "../../../../axios-sw";

import { commonLocation, tableIcons, titleTemplate } from "../../../../shared/tableVariables";
import { catchResponse, thenResponse } from "../../../../shared/utility";

const DetailTable = (props) => {
    // Nazwa klasy
    const classTitle = props.classTitle;

    const [token] = useState(props.token);
    const [user_id] = useState(props.user_id);
    const [ip_address] = useState(props.ip_address);
    const [data, setData] = useState([]);

    useEffect(() => {
        index();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const funcData = (newData) => {
        let result;
        if (typeof newData === "undefined") {
            result = {
                user_id: user_id,
                ip_address: ip_address,
            };
        } else {
            result = {
                ...props.classParams(newData),
                user_id: user_id,
                ip_address: ip_address,
            };
        }
        return result;
    };

    const index = () => {
        axios
            .get("/" + classTitle + ".json", { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };
    const create = (newData) => {
        const data = funcData(newData);
        axios
            .post("/" + classTitle + ".json", data, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                index();
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };
    const destroy = (id) => {
        const data = funcData();
        axios
            .delete("/" + classTitle + "/" + id, { data: data, headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                index();
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };
    const update = (id, newData) => {
        const data = funcData(newData);
        axios
            .put("/" + classTitle + "/" + id + ".json", data, { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                index();
                thenResponse(res);
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    return (
        <Grid item xs={6}>
            <MaterialTable
                title={titleTemplate(classTitle.charAt(0).toUpperCase() + classTitle.slice(1).replace("_", " "))}
                localization={commonLocation}
                icons={tableIcons}
                style={{ width: "100%", marginBottom: "30px" }}
                columns={props.columns[props.classTitle]}
                data={data}
                options={{
                    pageSize: 15,
                    exportButton: false,
                    filtering: true,
                    search: false,
                }}
                components={{
                    EditRow: (props) => <MTableEditRow className="uppercase" {...props} />,
                    Row: (props) => <MTableBodyRow className="customRow" {...props} />,
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            resolve();
                            create(newData);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            resolve();
                            update(oldData.id, newData);
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            resolve();
                            destroy(oldData.id);
                        }),
                }}
            />
        </Grid>
    );
};

export default DetailTable;
