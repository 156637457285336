import React, { useEffect, useState } from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { Container, FormControl, TextField, Button, FormControlLabel, Switch } from "@material-ui/core";
import { useSelector } from "react-redux";

import axios from "../../axios-sw";

// Form
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

// Components
import Spinner from "../../components/UI/Spinner/Spinner";

const accountFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  surname: Yup.string().required("Surname is required!"),
  email: Yup.string().required("Email is required!"),
  password: Yup.string().required("Password is required!"),
  password_confirmation: Yup.string().required("Password confirmation is required!"),
});

type User = {
  name: string;
  surname: string;
  email: string;
  role: string;
  otp_required_for_login: boolean;
  otp_provisioning_uri: string;
};

const AccountPage: React.FC = () => {
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);

  const { user_id, token, ip_address } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (user_id && token) {
      axios.get(`/users/${user_id}.json`, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
        setUser(res.data);
        setLoadingUser(false);
      });
    }
  }, []);

  return (
    <StyledContainer>
      <FormWrapper>
        <h2>Account settings</h2>

        {loadingUser && <Spinner />}

        {user && (
          <Formik
            initialValues={{
              name: user.name,
              surname: user.surname,
              email: user.email,
              password: "",
              password_confirmation: "",
              otp_required_for_login: user.otp_required_for_login,
            }}
            validationSchema={accountFormSchema}
            onSubmit={(values, { setSubmitting }) => {
              axios
                .post(
                  `/users/${user_id}.json`,
                  {
                    user: {
                      name: values.name,
                      surname: values.surname,
                      email: values.email,
                      password: values.password,
                      password_confirmation: values.password_confirmation,
                      otp_required_for_login: values.otp_required_for_login,
                    },
                    user_id: user_id,
                    ip_address: ip_address,
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .then(() => {
                  setSubmitting(false);
                  toast.success("Account updated!");
                })
                .catch(() => {
                  setSubmitting(false);
                  toast.error("Something went wrong!");
                });
            }}
          >
            {({ submitForm, touched, errors, isSubmitting, values, handleChange, handleBlur, setFieldValue }) => (
              <StyledForm>
                <FormControl>
                  <TextField
                    required
                    name="name"
                    id="name"
                    label="Name"
                    value={values.name}
                    defaultValue={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                  {errors.name && touched.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                </FormControl>

                <FormControl>
                  <TextField
                    required
                    name="surname"
                    id="surname"
                    label="Surname"
                    value={values.surname}
                    defaultValue={values.surname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                  {errors.surname && touched.surname && <ErrorMessage>{errors.surname}</ErrorMessage>}
                </FormControl>

                <FormControl>
                  <TextField
                    required
                    name="email"
                    id="email"
                    label="E-mail"
                    value={values.email}
                    defaultValue={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                  {errors.email && touched.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                </FormControl>

                <FormControl>
                  <TextField
                    required
                    name="password"
                    id="password"
                    label="Password"
                    value={values.password}
                    defaultValue={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type="password"
                  />
                  {errors.password && touched.password && <ErrorMessage>{errors.password}</ErrorMessage>}
                </FormControl>

                <FormControl>
                  <TextField
                    required
                    name="password_confirmation"
                    id="password_confirmation"
                    label="Password confirmation"
                    value={values.password_confirmation}
                    defaultValue={values.password_confirmation}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type="password"
                  />
                  {errors.password_confirmation && touched.password_confirmation && (
                    <ErrorMessage>{errors.password_confirmation}</ErrorMessage>
                  )}
                </FormControl>

                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={values.otp_required_for_login}
                      onChange={() => setFieldValue("otp_required_for_login", !values.otp_required_for_login)}
                    />
                  }
                  label="Enabled 2FA"
                />

                {values.otp_required_for_login && (
                  <QRCodeWrapper>
                    <h4>Instruction</h4>
                    <p>
                      To enable 2FA, download the Google Authenticator app for iOS or Android or the{" "}
                      <a href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai">
                        Chrome extension
                      </a>
                      . With each of these applications you will be able to read the QR code below and use the numeric
                      code when logging in.
                    </p>
                    {/* @ts-ignore */}
                    <QRCode value={user.otp_provisioning_uri} size={200} />
                  </QRCodeWrapper>
                )}

                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>
                  {isSubmitting ? "Updating" : "Update account"}
                </Button>
              </StyledForm>
            )}
          </Formik>
        )}
      </FormWrapper>
    </StyledContainer>
  );
};

const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin: 0;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 16px;
  }

  button {
    align-self: flex-end;
    width: 300px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const ErrorMessage = styled.div`
  color: darkred;
`;

const FormWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  background: white;
  box-shadow: 1px 4px 10px 0px rgba(208, 213, 221, 0.2);
  padding: 32px;

  h2 {
    padding: 0;
    margin: 0;
    margin-bottom: 32px;
  }
`;

const StyledContainer = styled(Container)`
  margin: 60px 0;
`;

export default AccountPage;
