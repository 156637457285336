import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";
import $ from "jquery";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, { MTableBody, MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";

import Columns from "../../shared/tables";

import ContainerInvoices from "./ContainerInvoices/ContainerInvoices";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import SearchInput from "../../components/UI/SearchInput/SearchInput";

import {
  authRedirect,
  catchResponse,
  createSingleColumnFilterVal,
  createSortPath,
  formatDate,
  getAndConvertULRParams,
  getFilterPath,
  getSortPath,
  nonAdminRedirect,
  postFile,
  sendEmailAboutLoadingDelay,
  thenResponse,
} from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../shared/defines";
import { Button, Checkbox, Chip, TablePagination } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Check, Close, GetApp, HighlightOff, Loop } from "@material-ui/icons";
import { json2excel } from "js2excel";
import classes from "../Bookings/Bookings.module.scss";
import AdvencedFilters from "../../components/System/AdvencedFilters/AdvencedFilters";
import moment from "moment";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";

const sortingPhraseHelper = [
  { key: "number", path: "containers.number" },
  { key: "container_type", path: "containers.container_type" },
  { key: "supplied", path: "containers.supplied" },
  { key: "port_name", path: "ports.code" },
  { key: "eta", path: "containers.eta" },
  { key: "etd", path: "containers.etd" },
  { key: "warehouse", path: "containers.warehouse" },
  { key: "dox_ac", path: "containers.dox_ac" },
  { key: "cleared", path: "containers.cleared" },
  { key: "comments", path: "containers.comments" },
  { key: "fv", path: "containers.fv" },
  { key: "fv_gave_back", path: "containers.fv_gave_back" },
  { key: "season", path: "seasons.code" },
  { key: "season_invoice", path: "seasons_invoice.code" },
  { key: "container_file", path: "containers.file" },
  { key: "contact_person", path: "container_invoices.contact_person" },
  { key: "shipper", path: "container_invoices.shipper" },
  { key: "ctns", path: "container_invoices.ctns" },
  { key: "cbm", path: "container_invoices.cbm" },
  {
    key: "quantity_in_pcs",
    path:
      "(SELECT SUM(`bookings`.`quantity_invoice`) FROM `bookings` WHERE (bookings.invoice_number = container_invoices.invoice_number))",
  },
  {
    key: "value",
    path:
      "(SELECT SUM(`bookings`.`quantity_invoice` * `bookings`.`price_invoice`) FROM `bookings` WHERE (bookings.invoice_number = container_invoices.invoice_number))",
  },
  { key: "invoice_number", path: "container_invoices.invoice_number" },
  { key: "payment_ref", path: "invoices.payment_ref" },
  { key: "paid", path: "invoices.validated" },
  { key: "code", path: "container_invoices.code" },
  { key: "net", path: "container_invoices.net" },
  { key: "gross", path: "container_invoices.gross" },
  { key: "originals_approved_date", path: "container_invoices.originals_approved_date" },
  { key: "documents_approved", path: "container_invoices.documents_approved" },
  { key: "file", path: "container_invoices.file" },
  { key: "season", path: "seasons.code" },
];

class Containers extends Component {
  state = {
    columns: Columns.containersColumns,
    columnsHidden: Columns.containersColumns,
    data: [],
    filteredData: [],
    parentChildData: "",
    containerInvoices: null,
    loaded: false,
    openAll: false,
    filterPhrase: "",
    tableRef: React.createRef(),
    filterByColumn: null,
    filterByConditional: { value: "all" },
    currentStateColumns: null,
    totalQuantity: 0,
    quantityPercentage: 0,
    totalValue: 0,
    valuePercentage: 0,
    totalRow: 0,
    sortBy: null,
    order: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    firstLoad: true,
    exportChosen: false,
    seasons: [],
    editable: {},
    actions: [],
    blockedSaveColumnsButton: true,
  };

  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        let localMap = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["code"], res.data[i]["code"]);
          localMap.set(res.data[i]["id"], res.data[i]["code"]);
        }
        let obj = Object.fromEntries(map);
        let localObj = Object.fromEntries(localMap);
        let newState = { ...this.state.columns };
        const portIndex = this.state.columns.findIndex((column) => column.field === "port_name");

        newState[portIndex].lookup = obj;
        this.setState({ columns: Object.values(newState), ports: localObj, portBase: res.data });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexSeasons = () => {
    axios
      .get("/seasons.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function(obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ seasons: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );
    this.indexContainers(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    this.indexPorts();
    this.indexSeasons();
    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value
          .replace("LIKE", "")
          .replace("'%", "")
          .replace("%'", "")
          .trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "all");
        if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "empty");
        if (filter.column.key === "proforma_document" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (
          filter.column.key === "accepted" &&
          filter.value ===
            `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path} NOT LIKE "%cancel%" AND ${filter.column.path} NOT LIKE "%hold%"`
        )
          table.onFilterChange(index, "accepted");
        if (
          filter.column.key === "proforma_document" &&
          filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
        )
          table.onFilterChange(index, "added");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) table.onFilterChange(index, "hold");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`)
          table.onFilterChange(index, "cancel");
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => item.trim());
        table.onFilterChange(index, valueArray);
      }
    });
    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  }

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_containers_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  getUnique = (arr, comp) => {
    const unique = arr
      .map((e) => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  };

  colorFilteredPhrase = (searchValue) => {
    $(document).ready(() => {
      if (searchValue.trim() !== "") {
        document.querySelectorAll("td").forEach((item, index) => {
          if (
            item.firstChild &&
            item.firstChild.nodeValue &&
            item.textContent.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            const text = item.firstChild.nodeValue;
            let re = new RegExp(searchValue.toUpperCase(), "g");
            let inner = text.replace(re, `<mark>${searchValue.toUpperCase()}</mark>`);
            document.querySelectorAll("td")[index].innerHTML = inner;
          }
        });
      }
    });
  };

  handleSerach = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(
      `/containers?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`
    );
    this.indexContainers(
      page,
      limit,
      search,
      sortingColumnsList,
      singleColumnFilterList,
      this.state.currentStateColumns
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexContainers(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
  }

  indexContainers = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);
    let advencedFilterByColumn = null;
    let advencedFilterByConditional = { value: "all" };
    const tableColumns = [...Columns.containerInvoicesColumns, ...Columns.containersColumns];

    const startTime = Date.now();
    axios
      .get(
        `/containers/${page}/${limit}.json`,
        {
          params: {
            phrase: phrase,
            sortPath: sortPath,
            filterPath: filterPath,
            currentSeasons: currentSeasons,
          },
        },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");

        const advencedFilterIndex = singleColumnFilterList.findIndex((filter) => filter.type === "advenced");
        if (advencedFilterIndex !== -1) {
          const column = tableColumns.filter(
            (column) => column.field === singleColumnFilterList[advencedFilterIndex].column.key
          );
          advencedFilterByColumn = { label: column[0].title, value: column[0].field };

          if (
            singleColumnFilterList[advencedFilterIndex].value.includes("<> '' AND") ||
            singleColumnFilterList[advencedFilterIndex].value.includes("<> STR_TO_DATE(\"\",'%m-%d-%y') AND")
          )
            advencedFilterByConditional = {
              label: "Full Field",
              value: "full",
            };
          else if (
            singleColumnFilterList[advencedFilterIndex].value.includes('="" OR') ||
            singleColumnFilterList[advencedFilterIndex].value.includes("= STR_TO_DATE(\"\",'%m-%d-%y') OR")
          )
            advencedFilterByConditional = {
              label: "Empty Field",
              value: "empty",
            };
          else if (singleColumnFilterList[advencedFilterIndex].value.includes('LIKE "%%" OR'))
            advencedFilterByConditional = {
              label: "All Field",
              value: "all",
            };
        }

        const columnSettings = JSON.parse(window.localStorage.getItem(`user_containers_${this.props.user_id}`));
        let columnsHidden = [];
        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        this.setState({
          data: res.data.containers,
          totalRow: res.data.total_row,
          totalQuantity: res.data.total_quantity,
          totalValue: parseFloat(res.data.total_value),
          quantityPercentage: res.data.total_quantity_percentage,
          valuePercentage: res.data.total_value_percentage,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.containers,
          columnsHidden: columnsHidden,
          loaded: true,
          filterByColumn: advencedFilterByColumn,
          filterByConditional: advencedFilterByConditional,
        });

        if (responseTime > 10) {
          let ids = "";
          res.data.containers.forEach((container) => (ids += `${container.id}, `));
          sendEmailAboutLoadingDelay(
            "Containers",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }

        if (phrase && phrase.trim().length > 0) this.colorFilteredPhrase(phrase);
        if (advencedFilterByColumn) {
          setTimeout(() => {
            this.state.tableRef.current.state.data.map((item, index) => {
              this.state.tableRef.current.onToggleDetailPanel([index], (rowData) => {
                return (
                  <div className="detailPanel">
                    <ContainerInvoices
                      number={index}
                      token={this.props.token}
                      user_id={this.props.user_id}
                      ip_address={this.props.ip_address}
                      containerId={rowData.id}
                      containerNumber={rowData.number}
                      filterByColumn={advencedFilterByColumn}
                      filterByConditional={advencedFilterByConditional}
                      filteredPhrase={this.state.filterPhrase}
                      colorFilteredPhrase={this.colorFilteredPhrase}
                      role={this.props.role}
                    />
                  </div>
                );
              });
            });
          }, 100);
        }
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  createContainer = (newData) => {
    let port = [];
    if (newData.port_name) port = this.state.portBase.filter((port) => port.code === newData.port_name);
    const data = {
      container: {
        number: newData.number == null ? null : newData.number.toUpperCase().trim(),
        container_type: newData.container_type == null ? null : newData.container_type.toUpperCase(),
        etd: newData.etd, // datetime
        eta: newData.eta, // datetime
        warehouse: newData.warehouse, // datetime
        port_id: newData.port_name ? port[0].id : null,
        fv: newData.fv, // datetime
        fv_gave_back: newData.fv_gave_back, // datetime
        supplied: newData.supplied, // boolean
        comments: newData.comments == null ? null : newData.comments.toUpperCase(),
        file: newData.container_file ? { url: newData.container_file } : null,
        dox_ac: newData.dox_ac,
        cleared: newData.cleared,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    const url = "/containers.json";
    axios
      .post(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        if (newData.season) {
          let seasons = newData.season.map((season) => season.value);
          axios
            .post(
              `/create-container-seasons/${newData.number}`,
              { seasons: seasons },
              { headers: { Authorization: `Bearer ${this.props.token}` } }
            )
            .then((res) => {
              const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                this.props.location.search,
                sortingPhraseHelper
              );
              this.indexContainers(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
            })
            .catch((err) => {
              catchResponse(err);
            });
        } else {
          const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
            this.props.location.search,
            sortingPhraseHelper
          );
          this.indexContainers(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
        }
        thenResponse(res);
      })
      .then(() => {
        postFile(
          "/containers-upload-file/",
          "file",
          newData,
          this.props.user_id,
          this.props.ip_address,
          this.props.token,
          () => {
            const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
              this.props.location.search,
              sortingPhraseHelper
            );
            this.indexContainers(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
          }
        );
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateContainer = (number, newData, oldData) => {
    let port = [];
    if (newData.port_name) port = this.state.portBase.filter((port) => port.code === newData.port_name);
    const data = {
      container: {
        number:
          newData.number == null
            ? null
            : newData.number
                .replace(/\s/g, "")
                .toUpperCase()
                .trim(),
        container_type: newData.container_type == null ? null : newData.container_type.toUpperCase(),
        etd: newData.etd, // datetime
        eta: newData.eta, // datetime
        warehouse: newData.warehouse, // datetime
        port_id: newData.port_name ? port[0].id : null,
        fv: newData.fv, // datetime
        fv_gave_back: newData.fv_gave_back, // datetime
        supplied: newData.supplied, // boolean
        comments: newData.comments == null ? null : newData.comments.toUpperCase(),
        file: newData.container_file ? { url: newData.container_file } : null,
        dox_ac: newData.dox_ac,
        cleared: newData.cleared,
      },
      // Na potrzeby podmiany bookings
      old_number: oldData.number,
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .put(`/containers/${number}.json`, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let seasons = [];
        if (newData.season) {
          if (typeof newData.season === "string") {
            newData.season
              .trim()
              .split(" ")
              .forEach((season) => {
                let index = this.state.seasons.findIndex((item) => item.label === season);
                if (index !== -1) seasons = [...seasons, this.state.seasons[index].value];
              });
          } else {
            seasons = newData.season.map((season) => season.value);
          }

          axios
            .post(
              `/update-container-seasons/${newData.id}`,
              { seasons: seasons },
              { headers: { Authorization: `Bearer ${this.props.token}` } }
            )
            .then(() => {
              axios
                .get(`/containers/${number}.json`, { headers: { Authorization: `Bearer ${this.props.token}` } })
                .then((res) => {
                  const fetchedContainer = res.data;
                  const array = [...this.state.filteredData];
                  const index = array.findIndex((x) => x.id === fetchedContainer.id);
                  if (index !== -1) {
                    array[index].number = fetchedContainer.number;
                    array[index].container_type = fetchedContainer.container_type;
                    array[index].etd = fetchedContainer.etd;
                    array[index].eta = fetchedContainer.eta;
                    array[index].warehouse = fetchedContainer.warehouse;
                    array[index].port_id = fetchedContainer.port_id;
                    array[index].port_name = fetchedContainer.port_name;
                    array[index].fv = fetchedContainer.fv;
                    array[index].fv_gave_back = fetchedContainer.fv_gave_back;
                    array[index].supplied = fetchedContainer.supplied;
                    array[index].comments = fetchedContainer.comments;
                    array[index].season = fetchedContainer.season;
                    array[index].container_file = fetchedContainer.container_file;
                    array[index].dox_ac = fetchedContainer.dox_ac;
                    array[index].cleared = fetchedContainer.cleared;
                    this.setState({ filteredData: array });
                  }
                })
                .catch((err) => {
                  catchResponse(err);
                });
            })
            .catch((err) => {
              catchResponse(err);
            });
        } else {
          axios
            .get(`/containers/${number}.json`, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
              const fetchedContainer = res.data;
              const array = [...this.state.filteredData];
              const index = array.findIndex((x) => x.id === fetchedContainer.id);
              if (index !== -1) {
                array[index].number = fetchedContainer.number;
                array[index].container_type = fetchedContainer.container_type;
                array[index].etd = fetchedContainer.etd;
                array[index].eta = fetchedContainer.eta;
                array[index].warehouse = fetchedContainer.warehouse;
                array[index].port_id = fetchedContainer.port_id;
                array[index].port_name = fetchedContainer.port_name;
                array[index].fv = fetchedContainer.fv;
                array[index].fv_gave_back = fetchedContainer.fv_gave_back;
                array[index].supplied = fetchedContainer.supplied;
                array[index].comments = fetchedContainer.comments;
                array[index].season = fetchedContainer.season;
                array[index].container_file = fetchedContainer.container_file;
                array[index].dox_ac = fetchedContainer.dox_ac;
                array[index].cleared = fetchedContainer.cleared;
                this.setState({ filteredData: array });
              }
            })
            .catch((err) => {
              catchResponse(err);
            });
        }
        thenResponse(res);
      })
      .then(() => {
        postFile(
          "/containers-upload-file/",
          "container_file",
          newData,
          this.props.user_id,
          this.props.ip_address,
          this.props.token,
          () => {
            axios
              .get(`/containers/${number}.json`, { headers: { Authorization: `Bearer ${this.props.token}` } })
              .then((res) => {
                const fetchedContainer = res.data;
                const array = [...this.state.data];
                const index = array.findIndex((x) => x.id === fetchedContainer.id);
                if (index !== -1) {
                  array[index].number = fetchedContainer.number;
                  array[index].container_type = fetchedContainer.container_type;
                  array[index].etd = fetchedContainer.etd;
                  array[index].eta = fetchedContainer.eta;
                  array[index].warehouse = fetchedContainer.warehouse;
                  array[index].port_id = fetchedContainer.port_id;
                  array[index].fv = fetchedContainer.fv;
                  array[index].fv_gave_back = fetchedContainer.fv_gave_back;
                  array[index].supplied = fetchedContainer.supplied;
                  array[index].comments = fetchedContainer.comments;
                  array[index].container_file = fetchedContainer.container_file;
                  array[index].dox_ac = fetchedContainer.dox_ac;
                  this.setState({ data: array });
                }
              })
              .catch((err) => {
                catchResponse(err);
              });
          }
        );
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  deleteContainer = (id, number) => {
    axios
      .get("/container-invoices/" + id + ".json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        if (res.data.length === 0) {
          const data = {
            /* Activity logging */
            user_id: this.props.user_id,
            ip_address: this.props.ip_address,
          };
          axios
            .delete("/containers/" + id + ".json", {
              data: data,
              headers: { Authorization: `Bearer ${this.props.token}` },
            })
            .then((res) => {
              const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                this.props.location.search,
                sortingPhraseHelper
              );
              this.indexContainers(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
              thenResponse(res);
            })
            .catch((err) => {
              catchResponse(err);
            });
        } else {
          toast.error("You can not delete container with containers invoices!");
        }
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  mapOrder = (array, order, key) => {
    array.sort((a, b) => {
      let A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  };

  fetchDataContainerInvoices = async (containersData) => {
    const containersNumbers = containersData.map((item) => item.number);

    const fetchURL = (url, params) => axios.get(url, params);
    let containerInvociesData = [];

    let promiseArray = containersData.map((item) => {
      return fetchURL(`/container-invoices/${item.id}.json`, {
        params: {
          filterByColumn: this.state.filterByColumn ? this.state.filterByColumn.value : null,
          filterByConditional: this.state.filterByConditional ? this.state.filterByConditional.value : null,
        },
      });
    });

    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          containerInvociesData = [...containerInvociesData, ...item.data];
        });
      })
      .catch((err) => {
        toast.error(err);
      });
    /*FILTROWANIE DANYCH NA PODSTAWIE FILTRÓW*/
    let filteredContainerIncoivesData = [];
    containerInvociesData.map((containerInvoice) => {
      containersNumbers.map((number) => {
        if (containerInvoice.container_number === number) {
          filteredContainerIncoivesData = [...filteredContainerIncoivesData, containerInvoice];
        }
      });
    });

    /*SORTOWANIE DANYCH NA PODSTAWIE KOLEJNOŚCI KONTENERÓW*/
    filteredContainerIncoivesData = this.mapOrder(filteredContainerIncoivesData, containersNumbers, "container_number");

    return filteredContainerIncoivesData;
  };

  onFilterField = (filterByColumn, filterByConditional) => {
    const table = this.state.tableRef.current;

    table.dataManager.data.map((item) => {
      if (item.tableData.showDetailPanel) {
        item.tableData.showDetailPanel = undefined;
      }
    });
    if (filterByColumn && filterByConditional) {
      let singleColumnFilterList = this.state.singleColumnFilterList;
      let indexIsExist = singleColumnFilterList.findIndex((filter) => filter.type === "advenced");
      const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === filterByColumn.value);
      let table = "";
      let value;

      this.state.columns.forEach((column) => {
        if (column.field === filterByColumn.value) table = "containers";
      });

      if (filterByColumn.value === "file" && filterByColumn.table === "containers") {
        table = "containers";
      } else {
        table = "container_invoices";
      }

      if (table === "containers") {
        if (filterByConditional.value === "full") {
          if (filterByColumn.type === "dateType")
            value = `<> STR_TO_DATE("",'%m-%d-%y') AND ${sqlColumn[0].path} IS NOT NULL`;
          else value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL`;
        }
        if (filterByConditional.value === "empty") {
          if (filterByColumn.type === "dateType")
            value = `= STR_TO_DATE("",'%m-%d-%y') OR ${sqlColumn[0].path} IS NULL`;
          else value = `="" OR ${sqlColumn[0].path} IS NULL`;
        }
        if (filterByConditional.value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
      } else {
        if (filterByConditional.value === "full") {
          if (filterByColumn.type === "dateType")
            value = `<> STR_TO_DATE("",'%m-%d-%y') AND ${sqlColumn[0].path} IS NOT NULL AND container_invoices.container_id = containers.id`;
          else value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND container_invoices.container_id = containers.id`;
        }
        if (filterByConditional.value === "empty") {
          if (filterByColumn.type === "dateType")
            value = `= STR_TO_DATE("",'%m-%d-%y') OR ${sqlColumn[0].path} IS NULL AND container_invoices.container_id = containers.id`;
          else value = `="" OR ${sqlColumn[0].path} IS NULL AND container_invoices.container_id = containers.id`;
        }
        if (filterByConditional.value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
      }

      if (indexIsExist !== -1) {
        singleColumnFilterList[indexIsExist].column = sqlColumn[0];
        singleColumnFilterList[indexIsExist].value = value;
      } else {
        singleColumnFilterList = [
          ...singleColumnFilterList,
          {
            column: sqlColumn[0],
            value: value,
            type: "advenced",
          },
        ];
      }

      this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
    } else {
      toast.error("Select column or conditional!");
    }
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              resolve();
              this.createContainer(newData);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateContainer(oldData.id, newData, oldData);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              this.deleteContainer(oldData.id, oldData.number);
            }),
        },
        actions: [],
      });
    }
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <div>
        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />
        <HotKeys />
        <MaterialTable
          actions={this.state.actions}
          tableRef={this.state.tableRef}
          title={titleTemplate("Containers")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          data={this.state.filteredData}
          columns={this.state.columnsHidden}
          /*parentChildData={(row, rows) => rows.find(a => a.lc_id === row.parent_lc_id)}*/
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <ContainerInvoices
                  token={this.props.token}
                  user_id={this.props.user_id}
                  ip_address={this.props.ip_address}
                  containerId={rowData.id}
                  containerNumber={rowData.number}
                  filteredPhrase={this.state.filterPhrase}
                  colorFilteredPhrase={this.colorFilteredPhrase}
                  filterByColumn={this.state.filterByColumn}
                  filterByConditional={this.state.filterByConditional}
                  role={this.props.role}
                />
              </div>
            );
          }}
          options={{
            search: false,
            filtering: true,
            pageSize: defaultPageSize,
            exportButton: true,
            exportFileName: "containers_" + formatDate(Date.now()),
          }}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columnsHidden[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columnsHidden[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columnsHidden[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columnsHidden[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "select") {
                      if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "accepted" || value === "added")
                        value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                      if (this.state.columnsHidden[columnId].field === "accepted") {
                        let convertValue = "";
                        value.forEach((item, index) => {
                          if (item === "all")
                            convertValue +=
                              index < value.length - 1
                                ? `LIKE "%%" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                : `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                          else if (item === "empty" || item === "unadded")
                            convertValue +=
                              index < value.length - 1
                                ? ` ="" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                : `="" OR ${sqlColumn[0].path} IS NULL`;
                          else if (item === "accepted" || item === "added")
                            convertValue +=
                              index < value.length - 1
                                ? `OR ${sqlColumn[0].path} <> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} OR ${sqlColumn[0].path} `
                                : `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                          else
                            convertValue +=
                              index < value.length - 1
                                ? `LIKE "%${item}%" OR ${sqlColumn[0].path} `
                                : `LIKE "%${item}%"`;
                        });

                        if (value.length > 0) {
                          if (indexIsExist !== -1) {
                            singleColumnFilterList[indexIsExist].value = convertValue;
                            table.state.columns[columnId].tableData.initialValue = value;
                          } else {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: convertValue,
                                type: "multiSelect",
                              },
                            ];
                            table.state.columns[columnId].tableData.initialValue = value;
                          }
                        } else {
                          singleColumnFilterList = singleColumnFilterList.filter(
                            (filter) => filter.column.key !== "accepted"
                          );
                          table.state.columns[columnId].tableData.initialValue = ["all"];
                        }
                      } else {
                        if (indexIsExist !== -1) {
                          let multiFiltersValues = "";
                          value.forEach((filter, index) => {
                            multiFiltersValues +=
                              index === 0
                                ? `LIKE '%${value[index]}%'`
                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                          });
                          singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                          table.onFilterChange(columnId, value);
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "multiSelect",
                            },
                          ];
                          table.onFilterChange(columnId, value);
                        }
                      }
                    }
                    singleColumnFilterList.filter((column) => column.type !== "advenced");
                    this.setState({
                      singleColumnFilterList: singleColumnFilterList,
                      filterPhrase: "",
                      filterByConditional: { value: "all" },
                      filterByColumn: null,
                    });
                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            Pagination: (props) => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={this.state.limit}
                  count={this.state.totalRow}
                  page={this.state.page}
                  onChangePage={(e, page) => {
                    this.state.tableRef.current.onChangePage(e, page);
                    this.setState({ page: page });
                    this.goPage(
                      page,
                      this.state.limit,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.setState({ limit: event.target.value });
                    this.goPage(
                      this.state.page,
                      event.target.value,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                />
              );
            },
            EditRow: (props) => {
              let index = props.columns.findIndex((column) => column.field === "season");
              props.columns[index].tableData.seasons = this.state.seasons;
              return <MTableEditRow className="editingRow uppercase" {...props} />;
            },
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            Toolbar: (props) => {
              const table = this.state.tableRef.current;
              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 5 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      this.goPage();
                      this.props.history.push(`/containers?page=${1}&limit=${20}`);

                      table.state.columns.forEach((column, index) => {
                        if (column.field === "accepted") {
                          table.state.columns[index].tableData.filterValue = "all";
                          column.tableData.initialValue = "all";
                        } else {
                          table.onFilterChange(index, null);
                          table.state.columns[index].tableData.filterValue = null;
                          column.tableData.initialValue = "";
                        }
                      });
                    }}
                  />

                  {/* Eksportowanie danych z tabeli */}
                  <div className={classes.exportToExcelWrapper}>
                    <Chip
                      variant="outlined"
                      style={{ marginLeft: 24, marginTop: 10 }}
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => {
                        const startTime = Date.now();
                        this.setState({ loaded: false, filteredData: [] });
                        const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                        const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                          this.props.location.search,
                          sortingPhraseHelper
                        );
                        const sortPath = getSortPath(sortingColumnsList);
                        const filterPath = getFilterPath(singleColumnFilterList);

                        axios
                          .get(
                            `/export-containers-to-excel.json`,
                            {
                              params: {
                                phrase: search,
                                sortPath: sortPath,
                                filterPath: filterPath,
                                currentSeasons: currentSeasons,
                              },
                            },
                            { headers: { Authorization: `Bearer ${this.props.token}` } }
                          )
                          .then(async (res) => {
                            console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                            const containersData = res.data.containers;
                            let dataContainerInvoices = await this.fetchDataContainerInvoices(containersData);

                            /*Dane exportowane do excella*/
                            let filteredContainersData = containersData.map((container) => {
                              return {
                                id: container.id,
                                container_number: container.number,
                                container_type: container.container_type,
                                port_name: container.port_name,
                                etd: container.etd,
                                eta: container.eta,
                                warehouse: container.warehouse,
                                dox_ac: container.dox_ac,
                                cleared: container.cleared,
                                season: container.season,
                                season_invoice: container.season_invoice,
                                fv: container.fv,
                                fv_gave_back: container.fv_gave_back,
                                comments: container.comments,
                                delivered: container.supplied,
                                file: container.file,
                              };
                            });

                            let filteredContainerInvoicesData = dataContainerInvoices.map((item) => {
                              return {
                                id: item.id,
                                container_number: item.container_number,
                                shipper: item.shipper,
                                code: item.code,
                                contact_person: item.contact_person,
                                etd: item.etd,
                                eta: item.eta,
                                warehouse: item.warehouse,
                                ctns: item.ctns,
                                cbm: item.cbm ? parseFloat(item.cbm.replace(/\s/g, "").replace(",", ".")) : 0,
                                quantity_in_pcs: item.quantity_in_pcs
                                  ? parseFloat(item.quantity_in_pcs.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                value: item.value ? parseFloat(item.value.replace(/\s/g, "").replace(",", ".")) : 0,
                                invoice_date: item.invoice_date,
                                invoice_number: item.invoice_number,
                                payment_ref: item.payment_ref_lc_number ? item.payment_ref_lc_number : item.payment_ref,
                                net: item.net ? parseFloat(item.net.replace(/\s/g, "").replace(",", ".")) : 0,
                                gross: item.gross ? parseFloat(item.gross.replace(/\s/g, "").replace(",", ".")) : 0,
                                average_carton_weight: item.average_carton_weight,
                                average_item_weight: item.average_item_weight,
                                documents_approved: item.documents_approved,
                                season_as_per_invoice: item.season,
                                originals_approved_date: item.originals_approved_date,
                              };
                            });

                            if (this.state.exportChosen) {
                              const exportColumns = this.state.columnsHidden.map((column) => {
                                if (column.field === "number") column.field = "container_number";
                                if (column.field === "supplied") column.field = "delivered";
                                return column.field;
                              });

                              filteredContainersData = filteredContainersData.map((row) => {
                                const toDelete = exportColumns;
                                const filtered = Object.keys(row)
                                  .filter((key) => toDelete.includes(key))
                                  .reduce((obj, key) => {
                                    obj[key] = row[key];
                                    return obj;
                                  }, {});

                                return filtered;
                              });
                            }

                            json2excel({
                              data: filteredContainerInvoicesData,
                              name: "container_invoices_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            json2excel({
                              data: filteredContainersData,
                              name: "containers_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            this.setState({ loaded: true, filteredData: this.state.data });
                          })
                          .catch((err) => {
                            catchResponse(err);
                          });
                      }}
                    />
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={this.state.exportChosen}
                        size={"small"}
                        onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                        color="primary"
                      />
                      <span>Export only chosen columns</span>
                    </div>
                  </div>
                  {/* Rozwijanie i zwijanie wszystkich subtabel */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24 }}
                    avatar={<Loop />}
                    label={"Switch all subtales"}
                    onClick={async () => {
                      const table = this.state.tableRef.current;
                      table.state.data.map((item, index) => {
                        table.onToggleDetailPanel([index], (rowData) => {
                          return (
                            <div className="detailPanel">
                              <ContainerInvoices
                                number={index}
                                token={this.props.token}
                                user_id={this.props.user_id}
                                ip_address={this.props.ip_address}
                                containerId={rowData.id}
                                containerNumber={rowData.number}
                                filterByColumn={this.state.filterByColumn}
                                filterByConditional={this.state.filterByConditional}
                                filteredPhrase={this.state.filterPhrase}
                                colorFilteredPhrase={this.colorFilteredPhrase}
                                role={this.props.role}
                              />
                            </div>
                          );
                        });
                      });
                    }}
                  />
                  {/*Generowanie tagu szukania*/}
                  {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        this.goPage(
                          0,
                          this.state.limit,
                          "",
                          this.state.sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ filterPhrase: "" });
                      }}
                      label={`search: ${this.state.filterPhrase}`}
                      color="primary"
                    />
                  )}

                  {/*Generowanie tagów filtrów kolumn*/}
                  {this.state.singleColumnFilterList.map((filter) => {
                    let value;
                    let key;
                    let icon = "";
                    if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                    else if (filter.type === "text")
                      value = filter.value
                        .replace("LIKE", "")
                        .replace("'%", "")
                        .replace("%'", "");
                    else if (filter.type === "select")
                      value = filter.value
                        .replace("LIKE", "")
                        .replace("'%", "")
                        .replace("%'", "");
                    else if (filter.type === "multiSelect")
                      value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",");
                    else if (filter.type === "number") value = filter.value.replace("=", "");
                    else if (filter.type === "boolean") {
                      if (filter.value.includes("true")) {
                        value = "checked";
                        icon = <Check />;
                      } else {
                        value = "unchecked";
                        icon = <Close />;
                      }
                    }

                    /*WYJĄTKI*/
                    /*ADVENCED FILTERS*/
                    if (filter.type === "advenced") {
                      value = "";
                      if (filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`)) value += "ALL ";
                      if (
                        filter.value.includes(`="" OR ${filter.column.path} IS NULL`) ||
                        filter.value.includes(`= STR_TO_DATE("",'%m-%d-%y') OR ${filter.column.path} IS NULL`)
                      )
                        value += "EMPTY ";
                      if (
                        filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL`) ||
                        filter.value.includes(`<> STR_TO_DATE("",'%m-%d-%y') AND ${filter.column.path} IS NOT NULL`)
                      )
                        value += "FULL ";
                    }

                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `="" OR ${filter.column.path} IS NULL`
                    )
                      value = "UNADDED";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
                    )
                      value = "ADDED";
                    filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);
                    if (filter.column.key === "payment_term_id") {
                      let currentValue = "";
                      const column = this.state.columns.filter((item) => item.field === "payment_term_id")[0].lookup;
                      const paymnetValue = value.replace(/\s/g, "").split(",");
                      paymnetValue.forEach((item, index) =>
                        index + 1 === paymnetValue.length
                          ? (currentValue += `${column[parseInt(item)]}`)
                          : (currentValue += `${column[parseInt(item)]}, `)
                      );
                      value = currentValue;
                      key = "PAYMENT";
                    }

                    /*MULTISELECT ACCEPTED WYJĄTKI*/
                    if (filter.column.key === "accepted") {
                      value = "";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`)
                      )
                        value += "ALL ";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`="" OR ${filter.column.path} IS NULL`)
                      )
                        value += "EMPTY ";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
                      )
                        value += "ACCEPTED ";
                      if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value += "HOLD ";
                      if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
                        value += "CANCEL ";
                    }

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                            (item) => item.column.key !== filter.column.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            this.state.sortingColumnsList,
                            singleColumnFilterList
                          );
                          this.setState({ singleColumnFilterList: singleColumnFilterList });
                          const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                          if (index !== -1) {
                            if (table.state.columns[index].field === "accepted") {
                              table.state.columns[index].tableData.initialValue = ["all"];
                            } else {
                              table.state.columns[index].tableData.initialValue = "";
                              table.onFilterChange(index, null);
                            }
                          }
                        }}
                        label={`${key.replace("_", " ").toUpperCase()}:  ${value.toUpperCase()}`}
                        icon={icon}
                        variant="outlined"
                      />
                    );
                  })}

                  {/*Generowanie tagów sortowania*/}
                  {this.state.sortingColumnsList.map((column) => {
                    let key;
                    let icon = "";
                    if (column.order === "ASC") icon = <ArrowDownward />;
                    if (column.order === "DESC") icon = <ArrowUpward />;

                    /*WYJĄTKI*/
                    column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const sortingColumnsList = this.state.sortingColumnsList.filter(
                            (item) => item.sortBy.key !== column.sortBy.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ sortingColumnsList: sortingColumnsList });
                        }}
                        label={`${key.toUpperCase().replace("_", " ")}`}
                        icon={icon}
                      />
                    );
                  })}
                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      onOpen={() =>
                        !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                      }
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "containers",
                          };
                        })}
                      options={Columns.containersColumns
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "containers",
                          };
                        })}
                      onChangeColumns={async (columns) => {
                        await this.setState({ columnsHidden: [], currentStateColumns: null });
                        await this.setSelectedColumns(columns);
                        this.setState({ blockedSaveColumnsButton: false });
                        this.setSortingForColumns();
                      }}
                    />
                    <Button
                      disabled={this.state.blockedSaveColumnsButton}
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_containers_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                          this.setState({ blockedSaveColumnsButton: true });
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_containers_${this.props.user_id}`);
                          await this.setState({ columnsHidden: this.state.columns, currentStateColumns: null });
                          toast.success("Column settings have been removed!");
                          this.setSortingForColumns();
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>
                  <AdvencedFilters
                    onFilterField={this.onFilterField}
                    conditionalOptions={[
                      { label: "All Field", value: "all" },
                      { label: "Empty Field", value: "empty" },
                      { label: "Full Field", value: "full" },
                    ]}
                    options={[
                      {
                        label: "Containers Columns",
                        options: Columns.containersColumns
                          .filter((column) => column.field !== "id")
                          .map((column) => {
                            return {
                              label: column.title,
                              value: column.field,
                              table: "containers",
                            };
                          }),
                      },
                      {
                        label: "Containers Invoices Columns",
                        options: Columns.containerInvoicesColumns
                          .filter(
                            (column) =>
                              column.field !== "id" &&
                              column.field !== "average_carton_weight" &&
                              column.field !== "average_item_weight"
                          )
                          .map((column) => {
                            return {
                              label: column.title,
                              value: column.field,
                              table: "containersInvoices",
                              type: column.type,
                            };
                          }),
                      },
                    ]}
                    filters={{
                      filterByColumn: this.state.filterByColumn,
                      filterByConditional: this.state.filterByConditional,
                    }}
                  />
                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                  </div>
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Containers);
