import React, { Component } from "react";
import axios from "../../../axios-sw";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@material-ui/core";
import { catchResponse } from "../../../shared/utility";
class InvoiceProducts extends Component {
    state = {
        data: [],
    };

    componentDidMount() {
        this.indexInvoiceProducts();
    }

    createData = (order_id, product_id, order_number, sex, fabric, description, style_name, index, quantity_invoice, price_invoice, value) => {
        return {
            order_id,
            product_id,
            order_number,
            sex,
            fabric,
            description,
            style_name,
            index,
            quantity_invoice,
            price_invoice,
            value,
        };
    };

    connectionFunc = (url) => {
        axios
            .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
            .then((res) => {
                res.data.forEach((el) => {
                    this.setState({
                        data: [
                            ...this.state.data,
                            this.createData(
                                el.order_id,
                                el.product_id,
                                el.order_number,
                                el.sex,
                                el.fabric,
                                el.description,
                                el.style_name,
                                el.index,
                                el.quantity_invoice,
                                el.price_invoice,
                                el.value
                            ),
                        ],
                    });
                });
            })
            .catch((err) => {
                catchResponse(err);
            });
    };

    indexInvoiceProducts = () => {
        let url = "/products-in-invoice/" + this.props.invoiceId + ".json";
        this.connectionFunc(url);
    };

    render() {
        return (
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Order number</TableCell>
                            <TableCell align="right">Sex</TableCell>
                            <TableCell align="right">Fabric</TableCell>
                            <TableCell align="right">Description</TableCell>
                            <TableCell align="right">Style name</TableCell>
                            <TableCell align="right">Index</TableCell>
                            <TableCell align="right">Quantity invoice</TableCell>
                            <TableCell align="right">Price invoice</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data.map((row) => (
                            <TableRow key={row.product_id}>
                                <TableCell component="th" scope="row">
                                    <Link to={"/orders/" + row.order_id}>{row.order_number}</Link>
                                </TableCell>
                                <TableCell align="right">{row.sex}</TableCell>
                                <TableCell align="right">{row.fabric}</TableCell>
                                <TableCell align="right">{row.description}</TableCell>
                                <TableCell align="right">{row.style_name}</TableCell>
                                <TableCell align="right">{row.index}</TableCell>
                                <TableCell align="right">{row.quantity_invoice}</TableCell>
                                <TableCell align="right">{row.price_invoice}</TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

export default InvoiceProducts;
