import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import packageJson from "../package.json";

import Layout from "./hoc/Layout/Layout";

/* Containers */
import Login from "./views/Login/Login";
import Logout from "./views/Logout/Logout";

import Products from "./views/Products/Products";
/*import Product from './views/Products/Product/Product';*/
import ProductDetails from "./views/ProductDetails/ProductDetails";
import Contacts from "./views/Contacts/Contacts";
/*import Contact from './views/Contacts/Contact/Contact';*/
import Designers from "./views/Designers/Designers";
/*import Designer from './views/Designers/Designer/Designer';*/
import Orders from "./views/Orders/Orders";
import OrderForm from "./views/Order/OrderForm/OrderForm";
import LCs from "./views/LCs/LCs";
import Containers from "./views/Containers/Containers";
import Invoices from "./views/Invoices/Invoices";
import Order from "./views/Order/Order";
import Bookings from "./views/Bookings/Bookings";
import Reports from "./views/Reports/Reports";
import Admin from "./views/Admin/Admin";
import Account from "./views/Account/Account.tsx";
import UserLogs from "./views/UserLogs/UserLogs";
import Settings from "./views/Settings/Settings";
import DesignerDashboard from "./views/DesignerDashboard/DesignerDashboard";
import DesignerProductDetails from "./views/DesignerDashboard/DesignerProductDetails/DesignerProductDetails";
import DesignerProducts from "./views/DesignerDashboard/DesignerProducts/DesignerProducts";
import PDFPreview from "./views/Order/PDFPreview/PDFPreview";

import * as actions from "./store/actions";

import classes from "./App.module.scss";

import Hotkeys from "react-hot-keys";
import CancelledProducts from "./views/CancelledProducts/CancelledProducts";

// Widoki VM
import VMProducts from "./views/VM/Products/Products";
import VMOrders from "./views/VM/Orders/Orders";
import VMCancelledProducts from "./views/VM/CancelledProducts/CancelledProducts";
import VMBookings from "./views/VM/Bookings/Bookings";
import VMContainers from "./views/VM/Containers/Containers";
import Modelokolory from "./views/Modelokolory/Modelokolory";
import VMFormCSV from "./views/VM/VMFormCSV";

import VMCollections from "./views/VM/Collections/Collections";

//Widoki Accounting
import AccountingInvoices from "./views/Accounting/Invoices/Invoices";

//Widoki Warehouse
import WarehouseContainers from "./views/Warehouse/Containers/Containers";

//Widoki Designer
import DesignerCancelledProducts from "./views/DesignerDashboard/DesignerCancelledProducts/DesignerCancelledProducts";
import DesignerStatus from "./views/DesignerDashboard/DesignerStatus/DesignerStatus";
import { checkMyBrowser, checkMyBrowserVersion } from "./shared/utility";
import { supportedBrowsers } from "./shared/defines";
import Paper from "@material-ui/core/Paper";
import { browserVersion } from "react-device-detect";
import BarcodesOrders from "./views/Barcodes/BarcodesOrders";
import Multipacks from "./views/Multipacks/Multipacks";
import moment from "moment";

// Synchronizacja produktów
import SyncProducts from "./views/SyncProducts/SyncProducts";
import SyncProductsFormDesc from "./views/SyncProducts/SyncProductsFormDesc/SyncProductsFormDesc";
import SyncProductsFormDetails from "./views/SyncProducts/SyncProductsFormDetails/SyncProductsFormDetails";
import SyncProductsFormCSV from "./views/SyncProducts/SyncProductsFormCSV/SyncProductsFormCSV";

// Catalog
import Catalog from "./views/Catalog";
import Sizing from "./views/Sizing";

// Forecasts
import Forecasts from "./views/Forecasts";

class App extends Component {
  state = {
    redirection: null,
    isLatestBuildDate: false,
    browser: checkMyBrowser(),
  };

  refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  checkVersionAndRefreshWithCache = () => {
    const lastBuildDate = localStorage.getItem("last_build_date");
    const currentVersionDate = packageJson.buildDate;
    const shouldForceRefresh = lastBuildDate ? moment(currentVersionDate).isAfter(moment(lastBuildDate)) : true;
    if (shouldForceRefresh) {
      this.setState({ isLatestBuildDate: false });
      this.refreshCacheAndReload();
      localStorage.setItem("last_build_date", currentVersionDate);
    } else {
      this.setState({ isLatestBuildDate: true });
    }
  };

  componentDidMount() {
    this.props.onTryAutoSignup();
    this.checkVersionAndRefreshWithCache();
  }

  render() {
    return (
      <>
        {this.state.isLatestBuildDate ? (
          <div className={classes.App}>
            {this.state.redirection}
            {!supportedBrowsers.find((browser) => browser === this.state.browser) ? (
              <div className={"overlay overlay-browser-info"}>
                <Paper elevation={3} className={"browser-info-error"}>
                  <p className={"text-info"}>
                    Your browser: <b>{checkMyBrowser()}</b> is not supported. Try install Chrome or Firefox.
                  </p>
                </Paper>
              </div>
            ) : (
              !checkMyBrowserVersion() && (
                <div className={"overlay overlay-browser-info"}>
                  <Paper elevation={3} className={"browser-info-error"}>
                    <p className={"text-info"}>
                      Your browser version: <b>0.{browserVersion}</b> is not supported. Try update your browser:{" "}
                      <b>{checkMyBrowser()}</b>
                    </p>
                  </Paper>
                </div>
              )
            )}
            <Hotkeys keyName="shift+1" onKeyDown={() => this.setState({ redirection: <Redirect to="/" /> })} />
            <Hotkeys keyName="shift+2" onKeyDown={() => this.setState({ redirection: <Redirect to="/products" /> })} />
            <Hotkeys keyName="shift+3" onKeyDown={() => this.setState({ redirection: <Redirect to="/bookings" /> })} />
            <Hotkeys keyName="shift+4" onKeyDown={() => this.setState({ redirection: <Redirect to="/lcs" /> })} />
            <Hotkeys
              keyName="shift+5"
              onKeyDown={() => this.setState({ redirection: <Redirect to="/containers" /> })}
            />
            <Hotkeys keyName="shift+6" onKeyDown={() => this.setState({ redirection: <Redirect to="/invoices" /> })} />
            <Hotkeys keyName="shift+7" onKeyDown={() => this.setState({ redirection: <Redirect to="/contacts" /> })} />
            <Hotkeys keyName="shift+8" onKeyDown={() => this.setState({ redirection: <Redirect to="/designers" /> })} />
            <Hotkeys
              keyName="shift+9"
              onKeyDown={() => this.setState({ redirection: <Redirect to="/product-details" /> })}
            />

            <Layout>
              <ToastContainer />
              <Switch>
                <Route path="/forecasts" component={Forecasts} />

                {/* Ścieżki pod VM */}
                <Route path="/vm/products" component={VMProducts} />
                <Route path="/vm/orders" component={VMOrders} />
                <Route path="/vm/cancelled-products" component={VMCancelledProducts} />
                <Route path="/vm/bookings" component={VMBookings} />
                <Route path="/vm/containers" component={VMContainers} />
                <Route path="/vm/collections" component={VMCollections} />
                <Route path="/vm/form-csv" component={VMFormCSV} />

                {/*Ścieżki pod Accounting*/}
                <Route path="/accounting/invoices" component={AccountingInvoices} />

                {/*Ścieżki pod Warehouse*/}
                <Route path="/warehouse/containers" component={WarehouseContainers} />

                <Route path="/modelokolory" component={Modelokolory} />
                <Route path="/designer-dashboard/:id" component={DesignerDashboard} />
                <Route path="/designers/product-details" component={DesignerProductDetails} />
                <Route path="/designers/cancelled-products" component={DesignerCancelledProducts} />
                <Route path="/designers/status-of-product" component={DesignerStatus} />
                <Route path="/designers/:id/products" component={DesignerProducts} />
                <Route path="/cancelled-products" component={CancelledProducts} />
                <Route path="/products" component={Products} />
                <Route path="/product-details" component={ProductDetails} />
                <Route path="/contacts" component={Contacts} />
                <Route path="/designers" component={Designers} />
                <Route path="/lcs" component={LCs} />
                <Route path="/invoices" component={Invoices} />
                <Route path="/containers" component={Containers} />
                <Route path="/bookings" component={Bookings} />
                <Route path="/barcodes" component={BarcodesOrders} />
                <Route path="/multipacks" component={Multipacks} />
                <Route path="/reports" component={Reports} />
                <Route path="/account/:id" component={Account} />
                <Route path="/admin" component={Admin} />
                <Route path="/user-logs" component={UserLogs} />
                <Route path="/settings" component={Settings} />
                <Route path="/orders/:id/pdf-preview" component={PDFPreview} />
                <Route path="/orders/:id" component={Order} />
                <Route path="/order-form/:id" component={OrderForm} />
                <Route path="/sizing" component={Sizing} />

                {/* Synchronizacja produktów */}
                <Route path="/sync-products/:id/form-descriptions" component={SyncProductsFormDesc} />
                <Route path="/sync-products/:id/form-details" component={SyncProductsFormDetails} />
                <Route path="/sync-products/form-csv" component={SyncProductsFormCSV} />
                <Route path="/sync-products" component={SyncProducts} />

                <Route path="/catalog" component={Catalog} />

                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/" component={Orders} />
                <Redirect to="/" />
              </Switch>
            </Layout>
          </div>
        ) : (
          <p>Please Reload Page</p>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
