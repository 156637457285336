import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import MaterialTable, { MTableBody, MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";
import { role } from "../../config";
import Columns from "../../shared/tables";
import LCDetails from "./LCDetails/LCDetails";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import {
  authRedirect,
  catchResponse,
  nonAdminRedirect,
  formatDate,
  postFile,
  thenResponse,
  getAndConvertULRParams,
  createSortPath,
  createSingleColumnFilterVal,
  getSortPath,
  getFilterPath,
  sendEmailAboutLoadingDelay,
  indexDetails,
} from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";

// Tabela podsumowania ilości i wartości
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Button,
  TablePagination,
  Checkbox,
} from "@material-ui/core";
import { defaultPageSize } from "../../shared/defines";
import { ArrowDownward, ArrowUpward, Check, Close, GetApp, HighlightOff } from "@material-ui/icons";
import { json2excel } from "js2excel";
import classes from "../Bookings/Bookings.module.scss";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import moment from "moment";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

const formatterCurrency = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const sortingPhraseHelper = [
  { key: "number", path: "lcs.number" },
  { key: "contact_name", path: "contacts.name" },
  { key: "company", path: "contacts.company" },
  {
    key: "total_quantity",
    path:
      "(SELECT SUM(`combinations`.`quantity`) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` INNER JOIN `orders` ON `orders`.`id` = `products`.`order_id` WHERE (orders.lc_number = lcs.number))",
  }, //TODO
  {
    key: "total_value",
    path:
      "(SELECT SUM(`combinations`.`quantity` * `combinations`.`price`) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` INNER JOIN `orders` ON `orders`.`id` = `products`.`order_id` WHERE (orders.lc_number = lcs.number))",
  }, //TODO
  { key: "opening_date", path: "lcs.opening_date" },
  { key: "expiration_date", path: "lcs.expiration_date" },
  { key: "expiration_place", path: "lcs.expiration_place" },
  { key: "expiry_of_security_date", path: "lcs.expiration_date" },
  { key: "state", path: "lcs.state" },
  { key: "file", path: "lcs.file" },
  { key: "amendment_file", path: "lcs.amendment_file" },
  { key: "lc_comments", path: "lcs.lc_comments" },
  { key: "lc_bank", path: "banks.name" },
];

class LCs extends Component {
  state = {
    columns: Columns.lcsColumns,
    columnsHidden: Columns.lcsColumns,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalQuantity: 0,
    totalQuantityOpen: 0,
    quantityPercentage: 0,
    totalValue: 0,
    totalValueOpen: 0,
    valuePercentage: 0,
    filterPhrase: "",
    sortBy: null,
    order: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    parentChildData: "",
    loaded: false,
    tableRef: React.createRef(),
    editable: {},
    actions: {},
    blockedSaveColumnsButton: true,
    banks: [],
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );
    this.indexLCs(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    this.indexBanks();
    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;

    singleColumnFilterList.forEach((filter) => {
      const index = this.state.tableRef.current.state.columns.findIndex((column) => column.field === filter.column.key);
      if (filter.type === "boolean") {
        if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
        else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
        else this.state.tableRef.current.onFilterChange(index, null);
      } else if (filter.type === "text") {
        const value = filter.value
          .replace("LIKE", "")
          .replace("'%", "")
          .replace("%'", "")
          .trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "number") {
        const value = filter.value.replace("=", "").trim();
        this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
      } else if (filter.type === "select") {
        /*WYJĄTKI*/
        if (filter.column.key === "accepted" && filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "all");
        if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "empty");
        if (filter.column.key === "proforma_document" && filter.value === `="" OR ${filter.column.path} IS NULL`)
          table.onFilterChange(index, "unadded");
        if (
          filter.column.key === "accepted" &&
          filter.value ===
            `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path} NOT LIKE "%cancel%" AND ${filter.column.path} NOT LIKE "%hold%"`
        )
          table.onFilterChange(index, "accepted");
        if (
          filter.column.key === "proforma_document" &&
          filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
        )
          table.onFilterChange(index, "added");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) table.onFilterChange(index, "hold");
        if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`)
          table.onFilterChange(index, "cancel");
      } else if (filter.type === "multiSelect") {
        let value = filter.value
          .split("LIKE")
          .join("")
          .split("'%")
          .join("")
          .split("%'")
          .join("")
          .split(`OR ${filter.column.path}`)
          .join(",")
          .trim();
        let valueArray = value.split(",").map((item) => item.trim());
        table.onFilterChange(index, valueArray);
      }
    });
    if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
      for (let i = 0; i < table.state.columns.length; i++) {
        if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
          table.onFilterChange(i, null);
          table.state.columns[i].tableData.filterValue = null;
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexLCs(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
    if (this.props.role !== prevProps.role) this.checkActionsAndEditablePermissions();
  }

  indexBanks = () => {
    axios
      .get("/banks.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        let localMap = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["name"], res.data[i]["name"]);
          localMap.set(res.data[i]["id"], res.data[i]["name"]);
        }
        let obj = Object.fromEntries(map);
        let localObj = Object.fromEntries(localMap);
        let newState = { ...this.state.columns };
        const portIndex = this.state.columns.findIndex((column) => column.field === "lc_bank");
        newState[portIndex].lookup = obj;
        this.setState({ columns: Object.values(newState), banks: localObj });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_lcs_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  handleSerach = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", () => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(`/lcs?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`);
    this.indexLCs(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
  };

  indexLCs = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    const startTime = Date.now();

    axios
      .get(
        `/lcs/${page}/${limit}.json`,
        {
          params: {
            phrase: phrase,
            sortPath: sortPath,
            filterPath: filterPath,
            currentSeasons: currentSeasons,
          },
        },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");

        this.setState({
          data: res.data.lcs,
          totalRow: res.data.total_row,
          totalQuantity: res.data.total_quantity,
          totalValue: parseFloat(res.data.total_value),
          totalQuantityOpen: res.data.total_quantity_open,
          totalValueOpen: res.data.total_value_open,
          quantityPercentage: res.data.total_quantity_percentage,
          valuePercentage: res.data.total_value_percentage,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.lcs,
          loaded: true,
        });

        if (responseTime > 15) {
          let ids = "";
          res.data.lcs.forEach((lc) => (ids += `${lc.id}, `));
          sendEmailAboutLoadingDelay(
            "Lcs",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  createLC = (newData) => {
    let bank_id = null;
    Object.keys(this.state.banks).forEach((key) => {
      if (this.state.banks[key] === newData.lc_bank) {
        bank_id = parseInt(key);
      }
    });
    const data = {
      lc: {
        number: newData.number == null ? null : newData.number.replace(/\s/g, "").toUpperCase(),
        bank_id: bank_id,
        opening_date: newData.opening_date,
        expiration_date: newData.expiration_date,
        expiration_place: newData.expiration_place == null ? null : newData.expiration_place.toUpperCase(),
        state: newData.state,
        lc_comments: newData.lc_comments,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    const url = "/lcs.json";
    axios
      .post(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexLCs();
        thenResponse(res);
      })
      .then(() => {
        postFile(
          "/lcs-upload-file/",
          "file",
          newData,
          this.props.user_id,
          this.props.ip_address,
          this.props.token,
          () => this.indexLCs()
        );
      })
      .then(() => {
        postFile(
          "/lcs-upload-amendment-file/",
          "amendment_file",
          newData,
          this.props.user_id,
          this.props.ip_address,
          this.props.token,
          () => this.indexLCs()
        );
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateLC = (id, newData, oldData) => {
    let bank_id = null;
    Object.keys(this.state.banks).forEach((key) => {
      if (this.state.banks[key] === newData.lc_bank) {
        bank_id = parseInt(key);
      }
    });

    const data = {
      lc: {
        number: newData.number == null ? null : newData.number.replace(/\s/g, "").toUpperCase(),
        bank_id: bank_id,
        opening_date: newData.opening_date,
        expiration_date: newData.expiration_date,
        expiration_place: newData.expiration_place == null ? null : newData.expiration_place.toUpperCase(),
        expiry_of_security_date: newData.expiry_of_security_date,
        state: newData.state,
        file: newData.file ? { url: newData.file } : null,
        amendment_file: newData.amendment_file ? { url: newData.amendment_file } : null,
        lc_comments: newData.lc_comments,
      },
      old_lc_number: oldData.number.replace(/\s/g, "").toUpperCase(),
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    const url = "/lcs/" + id + ".json";
    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedLc = res.data;
            const array = [...this.state.data];
            const index = array.findIndex((x) => x.id === fetchedLc.id);
            if (index !== -1) {
              array[index].number = fetchedLc.number;
              array[index].lc_bank = fetchedLc.lc_bank;
              array[index].opening_date = fetchedLc.opening_date;
              array[index].expiration_date = fetchedLc.expiration_date;
              array[index].expiration_place = fetchedLc.expiration_place;
              array[index].expiry_of_security_date = fetchedLc.expiry_of_security_date;
              array[index].state = fetchedLc.state;
              array[index].file = fetchedLc.file;
              array[index].amendment_file = fetchedLc.amendment_file;
              array[index].lc_comments = fetchedLc.lc_comments;
              this.setState({ data: array });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .then(() => {
        postFile(
          "/lcs-upload-file/",
          "file",
          newData,
          this.props.user_id,
          this.props.ip_address,
          this.props.token,
          () => {
            axios
              .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
              .then((res) => {
                const fetchedLc = res.data;
                const array = [...this.state.data];
                const index = array.findIndex((x) => x.id === fetchedLc.id);
                if (index !== -1) {
                  array[index].number = fetchedLc.number;
                  array[index].lc_bank = fetchedLc.lc_bank;
                  array[index].opening_date = fetchedLc.opening_date;
                  array[index].expiration_date = fetchedLc.expiration_date;
                  array[index].expiration_place = fetchedLc.expiration_place;
                  array[index].expiry_of_security_date = fetchedLc.expiry_of_security_date;
                  array[index].state = fetchedLc.state;
                  array[index].file = fetchedLc.file;
                  array[index].amendment_file = fetchedLc.amendment_file;
                  this.setState({ data: array });
                }
              })
              .catch((err) => {
                catchResponse(err);
              });
          }
        );
      })
      .then(() => {
        postFile(
          "/lcs-upload-amendment-file/",
          "amendment_file",
          newData,
          this.props.user_id,
          this.props.ip_address,
          this.props.token,
          () => {
            axios
              .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
              .then((res) => {
                const fetchedLc = res.data;
                const array = [...this.state.data];
                const index = array.findIndex((x) => x.id === fetchedLc.id);
                if (index !== -1) {
                  array[index].number = fetchedLc.number;
                  array[index].lc_bank = fetchedLc.lc_bank;
                  array[index].opening_date = fetchedLc.opening_date;
                  array[index].expiration_date = fetchedLc.expiration_date;
                  array[index].expiration_place = fetchedLc.expiration_place;
                  array[index].expiry_of_security_date = fetchedLc.expiry_of_security_date;
                  array[index].state = fetchedLc.state;
                  array[index].file = fetchedLc.file;
                  array[index].amendment_file = fetchedLc.amendment_file;
                  this.setState({ data: array });
                }
              })
              .catch((err) => {
                catchResponse(err);
              });
          }
        );
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  deleteLC = (id) => {
    const data = {
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .delete("/lcs/" + id + ".json", { data: data, headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexLCs();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  mapOrder = (array, order, key) => {
    array.sort((a, b) => {
      let A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  };

  fetchLcsInvoicesData = async (lcsData) => {
    const lcsNumbers = lcsData.map((item) => item.number);

    const fetchURL = (url) => axios.get(url);
    let lcsInvoicesData = [];

    let promiseArray = lcsData.map((item) => {
      return fetchURL(`/show-invoices-in-lc/${item.id}.json`);
    });

    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          lcsInvoicesData = [...lcsInvoicesData, ...item.data.invoices];
        });
      })
      .catch((err) => {
        toast.error(err);
      });

    /*FILTROWANIE DANYCH NA PODSTAWIE FILTRÓW*/
    let filteredLcsInvoicesData = [];
    lcsInvoicesData.map((lcsOrder) => {
      lcsNumbers.map((number) => {
        if (lcsOrder.lc_number === number) {
          filteredLcsInvoicesData = [...filteredLcsInvoicesData, lcsOrder];
        }
      });
    });
    /*SORTOWANIE DANYCH NA PODSTAWIE KOLEJNOŚCI KONTENERÓW*/
    filteredLcsInvoicesData = this.mapOrder(filteredLcsInvoicesData, lcsNumbers, "lc_number");
    return filteredLcsInvoicesData;
  };

  fetchLcsOrdersData = async (lcsData) => {
    const lcsNumbers = lcsData.map((item) => item.number);

    const fetchURL = (url) => axios.get(url);
    let lcsOrdersData = [];

    let promiseArray = lcsData.map((item) => {
      return fetchURL(`/show-orders-in-lc/${item.id}.json`);
    });

    await Promise.all(promiseArray)
      .then((data) => {
        data.map((item) => {
          lcsOrdersData = [...lcsOrdersData, ...item.data];
        });
      })
      .catch((err) => {
        toast.error(err);
      });

    /*FILTROWANIE DANYCH NA PODSTAWIE FILTRÓW*/
    let filteredLcsOrdersData = [];
    lcsOrdersData.map((lcsOrder) => {
      lcsNumbers.map((number) => {
        if (lcsOrder.lc_number === number) {
          filteredLcsOrdersData = [...filteredLcsOrdersData, lcsOrder];
        }
      });
    });

    /*SORTOWANIE DANYCH NA PODSTAWIE KOLEJNOŚCI KONTENERÓW*/
    filteredLcsOrdersData = this.mapOrder(filteredLcsOrdersData, lcsNumbers, "lc_number");

    return filteredLcsOrdersData;
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT)
      this.setState({
        editable: {},
        actions: [],
      });
    else {
      this.setState({
        editable: {
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              resolve();
              this.createLC(newData);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateLC(oldData.id, newData, oldData);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              resolve();
              this.deleteLC(oldData.id);
            }),
        },
        actions: [],
      });
    }
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <div>
        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />
        <HotKeys />

        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("L/C's")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          data={this.state.filteredData}
          columns={this.state.columnsHidden}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columnsHidden[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columnsHidden[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columnsHidden[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columnsHidden[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "select") {
                      if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "accepted" || value === "added")
                        value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                      if (indexIsExist !== -1) {
                        let multiFiltersValues = "";
                        value.forEach((filter, index) => {
                          multiFiltersValues +=
                            index === 0
                              ? `LIKE '%${value[index]}%'`
                              : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                        });
                        singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                        table.onFilterChange(columnId, value);
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "multiSelect",
                          },
                        ];
                        table.onFilterChange(columnId, value);
                      }
                    }
                    this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            Pagination: (props) => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={this.state.limit}
                  count={this.state.totalRow}
                  page={this.state.page}
                  onChangePage={(e, page) => {
                    this.state.tableRef.current.onChangePage(e, page);
                    this.setState({ page: page });
                    this.goPage(
                      page,
                      this.state.limit,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                  onChangeRowsPerPage={(event) => {
                    props.onChangeRowsPerPage(event);
                    this.setState({ limit: event.target.value });
                    this.goPage(
                      this.state.page,
                      event.target.value,
                      this.state.filterPhrase,
                      this.state.sortingColumnsList,
                      this.state.singleColumnFilterList
                    );
                  }}
                />
              );
            },
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            Toolbar: (props) => {
              const table = this.state.tableRef.current;
              let totalQuantity = 0,
                totalValue = 0,
                totalQuantityO = 0,
                totalValueO = 0;
              for (let i = 0; i < props.data.length; i++) {
                totalQuantity += parseInt(props.data[i].total_quantity.replace(/\s/g, ""));
                totalValue += parseFloat(props.data[i].total_value.replace(",", ".").replace(/\s/g, ""));
                if (props.data[i].state === "O") {
                  totalQuantityO += parseInt(props.data[i].total_quantity.replace(/\s/g, ""));
                  totalValueO += parseFloat(props.data[i].total_value.replace(",", ".").replace(/\s/g, ""));
                }
              }
              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />

                  {/* Usuwanie filtra z tabeli */}
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 5 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      this.goPage();
                      this.props.history.push(`/lcs?page=${1}&limit=${20}`);

                      table.state.columns.forEach((column, index) => {
                        if (column.field === "accepted") {
                          table.onFilterChange(index, "all");
                          table.state.columns[index].tableData.filterValue = "all";
                        } else {
                          table.onFilterChange(index, null);
                          table.state.columns[index].tableData.filterValue = null;
                          column.tableData.initialValue = "";
                        }
                      });
                    }}
                  />
                  {/* Eksportowanie danych z tabeli */}
                  <div className={classes.exportToExcelWrapper}>
                    <Chip
                      variant="outlined"
                      style={{ marginLeft: 24, marginTop: 10 }}
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => {
                        const startTime = Date.now();
                        this.setState({ loaded: false, filteredData: [] });
                        const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                        const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                          this.props.location.search,
                          sortingPhraseHelper
                        );
                        const sortPath = getSortPath(sortingColumnsList);
                        const filterPath = getFilterPath(singleColumnFilterList);

                        axios
                          .get(
                            `/export-lcs-to-excel.json`,
                            {
                              params: {
                                phrase: search,
                                sortPath: sortPath,
                                filterPath: filterPath,
                                currentSeasons: currentSeasons,
                              },
                            },
                            { headers: { Authorization: `Bearer ${this.props.token}` } }
                          )
                          .then(async (res) => {
                            console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                            const lcsData = res.data.lcs;
                            let lcsOrdersData = await this.fetchLcsOrdersData(lcsData);
                            let lcsInvoicesData = await this.fetchLcsInvoicesData(lcsData);

                            /* PRZYGOTOWANIE DO EXCELA*/
                            let filteredLcsData = lcsData.map((lc) => {
                              return {
                                id: lc.id,
                                lc_number: lc.number,
                                lc_bank: lc.lc_bank,
                                company: lc.company,
                                quantity_per_order: lc.total_quantity
                                  ? parseFloat(lc.total_quantity.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                total_value: lc.total_value
                                  ? parseFloat(lc.total_value.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                opening_date: lc.opening_date ? new Date(lc.opening_date) : null,
                                expiration_date: lc.expiration_date ? new Date(lc.expiration_date) : null,
                                expiration_place: lc.expiration_place,
                                expiry_of_security_date: lc.expiry_of_security_date
                                  ? new Date(lc.expiry_of_security_date)
                                  : null,
                                state: lc.state,
                                balance_usd: lc.balance_usd,
                                file: lc.file,
                                amendment_file: lc.amendment_file,
                              };
                            });

                            let filteredLcsOrdersData = lcsOrdersData.map((order) => {
                              return {
                                id: order.order_id,
                                order_number: order.order_number,
                                assortment: order.assortment,
                                total_quantity: order.total_quantity
                                  ? parseFloat(order.total_quantity.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                total_value: order.total_value
                                  ? parseFloat(order.total_value.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                shipment_date: order.shipment_date ? new Date(order.shipment_date) : null,
                              };
                            });

                            let filteredLcsInvoicesData = lcsInvoicesData.map((invoice) => {
                              return {
                                id: invoice.id,
                                invoice_number: invoice.number,
                                container_number: invoice.container_number,
                                etd: invoice.etd ? new Date(invoice.etd) : null,
                                warehouse: invoice.warehouse ? new Date(invoice.warehouse) : null,
                                invoice_quantity: invoice.invoice_quantity
                                  ? parseFloat(invoice.invoice_quantity.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                invoice_value: invoice.invoice_value
                                  ? parseFloat(invoice.invoice_value.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                contact_person: invoice.contact_person,
                                payment_date: invoice.payment_date ? new Date(invoice.payment_date) : null,
                                amount_to_pay_lc: invoice.amount_to_pay_lc ? invoice.amount_to_pay_lc : 0,
                              };
                            });

                            if (this.state.exportChosen) {
                              const exportColumns = this.state.columnsHidden.map((column) => {
                                if (column.field === "total_quantity") column.field = "quantity_per_order";
                                return column.field;
                              });
                              filteredLcsData = filteredLcsData.map((row) => {
                                const toDelete = exportColumns;
                                const filtered = Object.keys(row)
                                  .filter((key) => toDelete.includes(key))
                                  .reduce((obj, key) => {
                                    obj[key] = row[key];
                                    return obj;
                                  }, {});

                                return filtered;
                              });
                            }

                            if (filteredLcsData.length > 0) {
                              json2excel({
                                data: filteredLcsData,
                                name: "LCs_" + formatDate(Date.now()),
                                formateDate: "dd/mm/yyyy",
                              });
                            }

                            if (filteredLcsOrdersData.length > 0) {
                              json2excel({
                                data: filteredLcsOrdersData,
                                name: "LCs_orders" + formatDate(Date.now()),
                                formateDate: "dd/mm/yyyy",
                              });
                            }

                            if (filteredLcsInvoicesData.length > 0) {
                              json2excel({
                                data: filteredLcsInvoicesData,
                                name: "LCs_invoices" + formatDate(Date.now()),
                                formateDate: "dd/mm/yyyy",
                              });
                            }

                            this.setState({ loaded: true, filteredData: this.state.data });
                          })
                          .catch((err) => {
                            catchResponse(err);
                          });
                      }}
                    />
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={this.state.exportChosen}
                        size={"small"}
                        onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                        color="primary"
                      />
                      <span>Export only chosen columns</span>
                    </div>
                  </div>

                  {/*Generowanie tagu szukania*/}
                  {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        this.goPage(
                          0,
                          this.state.limit,
                          "",
                          this.state.sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ filterPhrase: "" });
                      }}
                      label={`search: ${this.state.filterPhrase}`}
                      color="primary"
                    />
                  )}

                  {/*Generowanie tagów filtrów kolumn*/}
                  {this.state.singleColumnFilterList.map((filter) => {
                    let value;
                    let key;
                    let icon = "";
                    if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                    else if (filter.type === "text")
                      value = filter.value
                        .replace("LIKE", "")
                        .replace("'%", "")
                        .replace("%'", "");
                    else if (filter.type === "select")
                      value = filter.value
                        .replace("LIKE", "")
                        .replace("'%", "")
                        .replace("%'", "");
                    else if (filter.type === "multiSelect")
                      value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",");
                    else if (filter.type === "number") value = filter.value.replace("=", "");
                    else if (filter.type === "boolean") {
                      if (filter.value.includes("true")) {
                        value = "checked";
                        icon = <Check />;
                      } else {
                        value = "unchecked";
                        icon = <Close />;
                      }
                    }

                    /*WYJĄTKI*/
                    if (
                      filter.column.key === "accepted" &&
                      filter.value === `LIKE "%%" OR ${filter.column.path} IS NULL`
                    )
                      value = "ALL";
                    if (filter.column.key === "accepted" && filter.value === `="" OR ${filter.column.path} IS NULL`)
                      value = "EMPTY";
                    if (
                      filter.column.key === "accepted" &&
                      filter.value ===
                        `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path} NOT LIKE "%cancel%" AND ${filter.column.path} NOT LIKE "%hold%"`
                    )
                      value = "ACCEPTED";
                    if (filter.column.key === "accepted" && filter.value === `LIKE "%hold%"`) value = "HOLD";
                    if (filter.column.key === "accepted" && filter.value === `LIKE "%cancel%"`) value = "CANCEL";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `="" OR ${filter.column.path} IS NULL`
                    )
                      value = "UNADDED";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
                    )
                      value = "ADDED";
                    filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);
                    if (filter.column.key === "payment_term_id") {
                      let currentValue = "";
                      const column = this.state.columns.filter((item) => item.field === "payment_term_id")[0].lookup;
                      const paymnetValue = value.replace(/\s/g, "").split(",");
                      paymnetValue.forEach((item, index) =>
                        index + 1 === paymnetValue.length
                          ? (currentValue += `${column[parseInt(item)]}`)
                          : (currentValue += `${column[parseInt(item)]}, `)
                      );
                      value = currentValue;
                      key = "PAYMENT";
                    }

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                            (item) => item.column.key !== filter.column.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            this.state.sortingColumnsList,
                            singleColumnFilterList
                          );
                          this.setState({ singleColumnFilterList: singleColumnFilterList });
                          const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                          if (index !== -1) {
                            if (table.state.columns[index].field === "accepted") {
                              table.onFilterChange(index, "all");
                            } else {
                              table.state.columns[index].tableData.initialValue = "";
                              table.onFilterChange(index, null);
                            }
                          }
                        }}
                        label={`${key.replace("_", " ").toUpperCase()}:  ${value.toUpperCase()}`}
                        icon={icon}
                        variant="outlined"
                      />
                    );
                  })}

                  {/*Generowanie tagów sortowania*/}
                  {this.state.sortingColumnsList.map((column) => {
                    let key;
                    let icon = "";
                    if (column.order === "ASC") icon = <ArrowDownward />;
                    if (column.order === "DESC") icon = <ArrowUpward />;

                    /*WYJĄTKI*/
                    column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const sortingColumnsList = this.state.sortingColumnsList.filter(
                            (item) => item.sortBy.key !== column.sortBy.key
                          );
                          this.goPage(
                            0,
                            this.state.limit,
                            this.state.filterPhrase,
                            sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ sortingColumnsList: sortingColumnsList });
                        }}
                        label={`${key.toUpperCase().replace("_", " ")}`}
                        icon={icon}
                      />
                    );
                  })}
                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      onOpen={() =>
                        !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                      }
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "lcs",
                          };
                        })}
                      options={Columns.lcsColumns
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "lcs",
                          };
                        })}
                      onChangeColumns={async (columns) => {
                        await this.setState({ columnsHidden: [], currentStateColumns: null });
                        await this.setSelectedColumns(columns);
                        this.setState({ blockedSaveColumnsButton: false });
                        this.setSortingForColumns();
                      }}
                    />
                    <Button
                      disabled={this.state.blockedSaveColumnsButton}
                      onClick={() => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_lcs_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                          this.setState({ blockedSaveColumnsButton: true });
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_lcs_${this.props.user_id}`);
                          await this.setState({ columnsHidden: this.state.columns, currentStateColumns: null });
                          toast.success("Column settings have been removed!");
                          this.setSortingForColumns();
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>

                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                  </div>
                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Value</TableCell>
                        <TableCell align="right">Quantity Percentange</TableCell>
                        <TableCell align="right">Value Percentange</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(this.state.totalQuantity)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterCurrency.format(this.state.totalValue)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "15%" }}>
                          {`${this.state.quantityPercentage}`.replace(".", ",")} %
                        </TableCell>
                        <TableCell align="right" style={{ width: "15%" }}>
                          {`${this.state.valuePercentage}`.replace(".", ",")} %
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                          Open
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(this.state.totalQuantityOpen)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterCurrency.format(this.state.totalValueOpen)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                          Rest
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(this.state.totalQuantity - this.state.totalQuantityOpen)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterCurrency.format(this.state.totalValue - this.state.totalValueOpen)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              );
            },
          }}
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <LCDetails token={this.props.token} lcId={rowData.id} />
              </div>
            );
          }}
          options={{
            filtering: true,
            pageSize: defaultPageSize,
          }}
          editable={this.state.editable}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(LCs);
