import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Spinner from "../../components/UI/Spinner/Spinner";
import classes from "./Login.module.scss";
import { toast, ToastContainer } from "react-toastify";

import * as actions from "../../store/actions";
import { Button, FormControl, Grid, Input, InputLabel, Paper } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

class Login extends Component {
  render() {
    if (this.props.error) {
      if (this.props.error.message === "Request failed with status code 401") {
        toast.error("Wrong email, password or one-time code! Try again.");
      } else {
        toast.error(this.props.error.message);
      }
    }

    let authRedirect;
    if (this.props.token !== null) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    const LoginSchema = Yup.object().shape({
      email: Yup.string().email("Email must to have right format!").required("Email is required!"),
      password: Yup.string().required("Password is required!"),
    });

    return (
      <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: "50vh" }}>
        <Grid item>
          <ToastContainer />
          {authRedirect}
          <Paper className={classes.Auth}>
            <Formik
              initialValues={{
                email: "",
                password: "",
                otp_attempt: "",
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, { setSubmitting }) => {
                this.props.onAuth(values.email, values.password, values.otp_attempt, true);
                setSubmitting(false);
              }}
              render={({ submitForm, touched, errors, isSubmitting, values, handleChange, handleBlur }) => (
                <Form>
                  {isSubmitting ? (
                    <Spinner />
                  ) : (
                    <>
                      <h2 style={{ textAlign: "center", marginBottom: 30 }}>Login</h2>

                      <FormControl className={classes.FormControl}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Field name="email" id="email">
                          {({ field }) => (
                            <Input {...field} value={values.email} onBlur={handleBlur} onChange={handleChange} />
                          )}
                        </Field>
                        {errors.email && touched.email && <div className={classes.InputFeedback}>{errors.email}</div>}
                      </FormControl>

                      <FormControl className={classes.FormControl}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Field name="password" id="password" style={{ textTransform: "uppercase !important" }}>
                          {({ field }) => (
                            <Input
                              {...field}
                              type="password"
                              value={values.password}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          )}
                        </Field>
                        {errors.password && touched.password && (
                          <div className={classes.InputFeedback}>{errors.password}</div>
                        )}
                      </FormControl>

                      <FormControl className={classes.FormControl}>
                        <InputLabel htmlFor="otp_attempt">One-Time Code</InputLabel>
                        <Field name="otp_attempt" id="otp_attempt">
                          {({ field }) => (
                            <Input {...field} value={values.otp_attempt} onBlur={handleBlur} onChange={handleChange} />
                          )}
                        </Field>
                        {errors.otp_attempt && touched.otp_attempt && <div className={classes.InputFeedback}>{errors.otp_attempt}</div>}
                      </FormControl>

                      <FormControl style={{ textAlign: "center" }} className={classes.FormControl}>
                        <Button
                          type="submit"
                          style={{ margin: "10px 0" }}
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          {isSubmitting ? "Logging" : "Login"}
                        </Button>
                      </FormControl>
                    </>
                  )}
                </Form>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    token: state.auth.token,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, isSignUp) => dispatch(actions.auth(email, password, isSignUp)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
