import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from '../../../axios-sw';

import {ToastContainer} from 'react-toastify';

import MaterialTable, {MTableBody, MTableBodyRow, MTableEditRow, MTableToolbar} from 'material-table';

import {tableIcons, titleTemplate} from '../../../shared/tableVariables';
import Columns from '../../../shared/tables/designersTables/cancelledProductsTable';
import Switch from '@material-ui/core/Switch';

import {Chip, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

import {HotKeys} from "../../../components/System/HotKeys/HotKeys";
import {authRedirect, catchResponse, thenResponse, nonAdminRedirect, formatDate} from "../../../shared/utility";
import classes from "../../Order/OrderForm/OrderForm.module.scss";
import OpenInNew from "@material-ui/icons/OpenInNew";
import {defaultPageSize} from "../../../shared/defines";
import {HighlightOff} from "@material-ui/icons";
import Spinner from "../../../components/UI/Spinner/Spinner";

const formatterQuantity = new Intl.NumberFormat('pl-PL');

const formatterCurrency = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

class DesignerCancelledProducts extends Component {

    state = {
        columns: Columns.designerCancelledProducts,
        data: [],
        totalQuantity: 0,
        totalValue: 0,
        selection: false,
        actions: [
            () => ({
                icon: OpenInNew,
                tooltip: 'Product order details',
                onClick: (event, rowData) => {
                    this.props.history.push('/orders/' + rowData.order_id);
                }
            })
        ],
    };

    componentDidMount() {
        this.indexProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentSeasons !== prevProps.currentSeasons) {
            this.setState({data:[], loaded: false})
            this.indexProducts();
        }
    }

    indexProducts = () => {
        const currentSeasons = this.props.currentSeasons.map(season => season.value);

        axios.get('/cancelled-products.json', {params: {currentSeasons: currentSeasons, user_id: this.props.user_id}}, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(res => {
                this.setState({data: res.data});
            })
            .catch(err => {
                catchResponse(err)
            })
    };


    switchHandler = () => {
        this.setState({
            selection: !this.state.selection
        })
    };

    render() {
        let tableRef = React.createRef();

        let emptyDataMessage;
        if (!this.state.loaded) {
            emptyDataMessage = <Spinner />
        } else {
            emptyDataMessage = 'No data to display'
        }

        const table = <MaterialTable
            tableRef={tableRef}
            title={titleTemplate('Cancelled products')}
            localization={{
                body: {
                    emptyDataSourceMessage: emptyDataMessage,
                    editRow: {
                        cancelTooltip: 'Back'
                    }
                }
            }}
            icons={tableIcons}
            style={{width: '100%'}}
            columns={this.state.columns}
            data={this.state.data}
            actions={this.state.actions}
            options={{
                pageSize: defaultPageSize,
                exportButton: true,
                exportFileName: 'products_' + formatDate(Date.now()),
                filtering: true,
                actions: true,
                selection: this.state.selection
            }}
            components={{
                Body: props => (
                    <MTableBody {...props} onFilterChanged={(columnId, value) => {
                        props.onFilterChanged(columnId, value);
                    }}/>
                ),
                Row: props => (
                    <MTableBodyRow className={props.data.styles} {...props} />
                ),
                EditRow: props => (
                    <MTableEditRow className='editingRow uppercase' {...props} />
                ),
                Toolbar: props => {
                    let totalQuantity = 0, totalValue = 0;
                    for (let i=0; i < props.data.length; i++) {
                        totalQuantity += parseInt(props.data[i].quantity.replace(/\s/g,''));
                        totalValue += parseFloat(props.data[i].total.replace(",", ".").replace(/\s/g,''));
                    }
                    return (
                        <div className='mainToolbar'>
                            <MTableToolbar {...props} />

                            {/* Usuwanie filtra z tabeli */}
                            <Chip variant="outlined" style={{marginLeft: 24}} avatar={<HighlightOff />} label="Clear filters" onClick={() => {
                                const table = tableRef.current;
                                for (let i=0; i < table.state.columns.length; i++) {
                                    table.onFilterChange(i, null);
                                    table.state.columns[i].tableData.filterValue = null;
                                }
                            }} />

                            <div className={classes.CustomToolbar}>

                                <Table style={{width: '50%'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'>Summary</TableCell>
                                            <TableCell align="right">Quantity</TableCell>
                                            <TableCell align="right">Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{fontWeight: 'bold', width: '50%'}}>Total</TableCell>
                                            <TableCell align="right" style={{width: '25%'}}>{formatterQuantity.format(totalQuantity)}</TableCell>
                                            <TableCell align="right" style={{width: '25%'}}>{formatterCurrency.format(totalValue)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    )
                }
            }}
            onSelectionChange={(rows) => {
                this.sumTotalProducts(rows)
            }}
        />;

        return (
            <div>
                {authRedirect(this.props.token)}
                <ToastContainer/>
                <HotKeys/>

                {table}

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        ip_address: state.auth.ip_address,
        role: state.auth.role,
        currentSeasons: state.systemInfo.currentSeasons
    }
};

export default connect(mapStateToProps)(DesignerCancelledProducts);