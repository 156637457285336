import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { tableIcons, titleTemplate } from "../../shared/tableVariables";

import Columns from "../../shared/tables";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";
import { authRedirect, catchResponse, nonAdminRedirect, formatDate, thenResponse } from "../../shared/utility";
import Spinner from "../../components/UI/Spinner/Spinner";

class Designers extends Component {
  state = {
    columns: Columns.designersColumns,
    data: [],
    loaded: false,
  };

  componentDidMount() {
    this.indexDesigners();
  }

  indexDesigners = () => {
    axios
      .get("/designers.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get("/users.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            let map = new Map();
            for (var i = 0; i < res.data.length; i++) {
              map.set(res.data[i].id, res.data[i]["name"] + " " + res.data[i]["surname"]);
            }
            let obj = Object.fromEntries(map);
            let newState = { ...this.state.columns };
            newState[3].lookup = obj;
            this.setState({
              columns: Object.values(newState),
              loaded: true,
            });
          })
          .catch((err) => {
            catchResponse(err);
          });

        this.setState({ data: res.data });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  createDesigner = (newData) => {
    const data = {
      designer: {
        name: newData.name == null ? null : newData.name.toUpperCase(),
        code: newData.code == null ? null : newData.code.toUpperCase(),
        user_id: newData.user_id,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .post("/designers.json", data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexDesigners();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  destroyDesigner = (id) => {
    const data = {
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    axios
      .delete("/designers/" + id, { data: data, headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexDesigners();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateDesigner = (id, newData) => {
    const data = {
      designer: {
        name: newData.name == null ? null : newData.name.toUpperCase(),
        code: newData.code == null ? null : newData.code.toUpperCase(),
        user_id: newData.user_id,
      },
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    const url = "/designers/" + id + ".json";
    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        this.indexDesigners();
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <div>
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />
        <HotKeys />

        <MaterialTable
          title={titleTemplate("Designers", "Remember to set unique names")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={this.state.data}
          options={{
            pageSize: 15,
            exportButton: true,
            exportFileName: "designers_" + formatDate(Date.now()),
            filtering: true,
          }}
          components={{
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Row: (props) => <MTableBodyRow className="customRow" {...props} />,
            Toolbar: (props) => {
              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />
                </div>
              );
            },
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                resolve();
                this.createDesigner(newData);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                resolve();
                this.updateDesigner(oldData.id, newData);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                resolve();
                this.destroyDesigner(oldData.id);
              }),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(Designers);
