import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../../axios-sw";

import { ToastContainer } from "react-toastify";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";

import { tableIcons, titleTemplate } from "../../../shared/tableVariables";
import Columns from "../../../shared/tables";

import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { HotKeys } from "../../../components/System/HotKeys/HotKeys";
import { authRedirect, catchResponse, roleLimitedView, formatDate } from "../../../shared/utility";
import classes from "../../Order/OrderForm/OrderForm.module.scss";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../../shared/defines";
import { GetApp, HighlightOff } from "@material-ui/icons";
import { json2excel } from "js2excel";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

const formatterCurrency = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

class CancelledProducts extends Component {
  state = {
    columns: Columns.vm.cancelledProducts,
    data: [],
    totalQuantity: 0,
    totalValue: 0,
    selection: false,
    ports: [],
    loaded: false,
  };

  componentDidMount() {
    this.indexPorts();
    this.indexProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexProducts();
    }
  }

  indexProducts = () => {
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);
    axios
      .get(
        "/cancelled-products.json",
        { params: { currentSeasons: currentSeasons } },
        { headers: { Authorization: `Bearer ${this.props.token}` } }
      )
      .then((res) => {
        this.setState({ data: res.data });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };
  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["code"], res.data[i]["code"]);
        }
        let obj = Object.fromEntries(map);
        let newState = { ...this.state.columns };
        newState[10].lookup = obj;
        this.setState({ columns: Object.values(newState) });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  render() {
    let tableRef = React.createRef();

    let emptyDataMessage;
    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    const table = (
      <MaterialTable
        tableRef={tableRef}
        title={titleTemplate("VM Cancelled Products")}
        localization={{
          body: {
            emptyDataSourceMessage: emptyDataMessage,
            editRow: {
              cancelTooltip: "Back",
            },
          },
        }}
        icons={tableIcons}
        style={{ width: "100%" }}
        columns={this.state.columns}
        data={this.state.data}
        options={{
          pageSize: defaultPageSize,
          exportButton: false,
          exportFileName: "products_" + formatDate(Date.now()),
          filtering: true,
          actions: true,
          selection: this.state.selection,
        }}
        components={{
          Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
          EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
          Toolbar: (props) => {
            let totalQuantity = 0,
              totalValue = 0;
            for (let i = 0; i < props.data.length; i++) {
              totalQuantity += parseInt(props.data[i].quantity.replace(/\s/g, ""));
              totalValue += parseFloat(props.data[i].total.replace(/\s/g, ""));
            }
            return (
              <div className="mainToolbar">
                <MTableToolbar {...props} />

                {/* Usuwanie filtra z tabeli */}
                <Chip
                  variant="outlined"
                  style={{ marginLeft: 24 }}
                  avatar={<HighlightOff />}
                  label="Clear filters"
                  onClick={() => {
                    const table = tableRef.current;
                    for (let i = 0; i < table.state.columns.length; i++) {
                      table.onFilterChange(i, null);
                      table.state.columns[i].tableData.filterValue = null;
                    }
                  }}
                />

                {/* Eksportowanie danych z tabeli */}
                <Chip
                  variant="outlined"
                  style={{ marginLeft: 24 }}
                  avatar={<GetApp style={{ padding: 5 }} />}
                  label="Export data to Excel"
                  onClick={() => {
                    const data = tableRef.current.state.data;
                    const columns = tableRef.current.state.columns;

                    data.forEach((row) => {
                      const columnsToReplace = ["port"];
                      for (let i = 0; i < columns.length; i++) {
                        for (let j = 0; j < columnsToReplace.length; j++) {
                          // Podmiana ID na wybrane frazy ze zdefiniowanej listy
                          if (columns[i].field === columnsToReplace[j]) {
                            for (let key in columns[i].lookup) {
                              if (parseInt(key) === parseInt(row[columnsToReplace[j]])) {
                                row[columnsToReplace[j]] = columns[i].lookup[key];
                              }
                            }
                          }
                        }
                      }
                      const columnsToConvert = ["quantity", "price", "total"];
                      for (let i = 0; i < columns.length; i++) {
                        for (let j = 0; j < columnsToConvert.length; j++) {
                          if (columns[i].field === columnsToConvert[j] && row[columnsToConvert[j]] !== null) {
                            row[columnsToConvert[j]] = parseFloat(
                              row[columnsToConvert[j]].replace(/\s/g, "").replace(",", ".")
                            );
                          }
                        }
                      }

                      row.shipment_date = row.shipment_date ? new Date(row.shipment_date) : null;

                      delete row["order_id"];
                      delete row["description_id"];
                      delete row["styles"];
                      delete row["tableData"];

                      delete row["port_id"];
                      delete row["price"];
                      delete row["total"];
                      delete row["payment_term"];
                      delete row["tt_amount"];
                      delete row["discount"];
                      delete row["accessory"];
                      delete row["comments"];
                      delete row["payment_ref"];
                    });

                    json2excel({
                      data,
                      name: "products_" + formatDate(Date.now()),
                      formateDate: "dd/mm/yyyy",
                    });
                  }}
                />

                <div className={classes.CustomToolbar}>
                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "50%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "25%" }}>
                          {formatterQuantity.format(totalQuantity)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            );
          },
        }}
      />
    );

    return (
      <div>
        {authRedirect(this.props.token)}
        {roleLimitedView("vm", this.props.role, this.props.user_id)}
        <ToastContainer />
        <HotKeys />

        {table}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(CancelledProducts);
