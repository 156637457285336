import React, { Component } from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Paper} from '@material-ui/core';
import {authRedirect, nonAdminRedirect} from "../../shared/utility";

class Reports extends Component {

    render() {
        return (
            <Grid container spacing={3}>
                {authRedirect(this.props.token)}
                {nonAdminRedirect(this.props.user_id, this.props.role)}
                <Grid item xs={12}>
                    <Paper style={{padding: 20}}>
                        <h3>Reports</h3>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        user_id: state.auth.user_id,
        role: state.auth.role
    }
};

export default connect(mapStateToProps)(Reports);